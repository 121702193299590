<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.notifications')} / Bot Telegram`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'bot-telegram' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <span>
                  Para recibir notificaciones en Telegram debe incluir su Bot como miembro de ese grupo.
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.identificador"
                      :disabled="onlyShow"
                      :label="$t('lbl.identificador')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.chat_id"
                      :disabled="onlyShow"
                      label="Chat ID"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <h3>{{ $t('lbl.notyBy') }}:</h3>
              </v-col>
              <v-col
                v-for="(module, i) in modules"
                :key="i"
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="modulesCheck"
                  hide-details
                  class="mt-1"
                  :value="module.slug"
                >
                  <template #label>
                    <span>{{ module.name }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
    <Loading
      v-if="loading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      model: {},
      showOptions: false,
      loading: false,
      modules: [],
      modulesCheck: [],
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('bot-telegram-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      optionsChatBotMessages: state => state.app.optionsChatBotMessages,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.modules.push({
      slug: 'update-users',
      name: 'Usuarios / Creación y Activación',
    })
    this.modules.push({
      slug: 'update-apis',
      name: 'APIs / Creación, Activación y Desactivación',
    })
    this.modules.push({
      slug: 'cotizaciones',
      name: 'Cotizaciones',
    })
    this.modules.push({
      slug: 'update-apps',
      name: 'APPS / Creación, Activación y Desactivación',
    })
    this.modules.push({
      slug: 'reservas',
      name: 'Reservas',
    })
    this.modules.push({
      slug: 'facturas-voucher',
      name: 'Facturas y Vouchers / Envío de PDF',
    })
    this.modules.push({
      slug: 'channel-manager',
      name: 'Channel Manager',
      disabled: false,
    })
    this.modules.push({
      slug: 'update-company',
      name: 'Compañías / Creación y Eliminación',
    })
    this.modules.push({
      slug: 'update-contrates',
      name: 'Contratos / Creación y Eliminación',
    })

    this.modules.push({
      slug: 'newsletter',
      name: 'Newsletter',
    })
    this.modules.push({
      slug: 'update-markups',
      name: 'Markups / Creación y Eliminación',
    })
    this.modules.push({
      slug: 'update-stop-sale',
      name: 'Paro de venta / Creación y Eliminación',
    })
    this.modules.push({
      slug: 'update-products',
      name: 'Productos / Creación y Eliminación',
    })
    this.modules.push({
      slug: 'update-imgs',
      name: 'Imágenes de Productos / Eliminación',
    })
    this.modules.push({
      slug: 'exchange-api',
      name: 'Tipo de cambio de moneda',
    })
    this.modules.push({
      slug: 'update-lotes',
      name: 'Carga por lotes / Nomencladores',
    })
    this.modules.push({
      slug: 'chat-bot',
      name: 'Chat Bot',
    })
    this.getItem()
  },
  methods: {
    ...mapMutations(['adicionarOptionsMessaje', 'deleteOptionsMessaje', 'updateOptionsMessaje', 'setOnlyShow']),

    getItem() {
      if (localStorage.getItem('bot-telegram-id') !== null) {
        const id = localStorage.getItem('bot-telegram-id')
        this.axios
          .get(`bot_telegram/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.modulesCheck = this.model.notifications
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },

    save() {
      if (this.model.identificador
        && this.model.chat_id
        && this.modulesCheck.length > 0) {
        this.loading = true
        const json = this.model
        json.notifications = this.modulesCheck

        if (localStorage.getItem('bot-telegram-id') === null) {
          this.axios
            .post('bot_telegram', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === true) {
                localStorage.removeItem('bot-telegram-id')
                this.$router.push({ name: 'bot-telegram' })
              } else if (response.data.success === false) {
                if (response.data.data.status === 301) {
                  this.$toast.error('Chat ID existente.')
                } else if (response.data.data.status === 404) {
                  this.$toast.error('No fue posible la comunicación con el bot.')
                }
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`bot_telegram/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === true) {
                localStorage.removeItem('bot-telegram-id')
                this.$router.push({ name: 'bot-telegram' })
              } else if (response.data.success === false) {
                if (response.data.data.status === 404) {
                  this.$toast.error('No fue posible la comunicación con el bot.')
                }
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
