<!-- eslint-disable no-undef -->
<template>
  <div class="auth-wrapper auth-v1">
    <v-row>
      <v-col
        cols="12"
        md="2"
      ></v-col>
      <v-col
        class="auth-inner pl-5"
        cols="12"
        md="10"
      >
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="`${url_public}${configDataGeneral.isotipo ? configDataGeneral.isotipo : configDataGeneral.logo}`"
                max-height="100px"
                max-width="100px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h2 class="text-2xl font-weight-semibold">
                    {{ configDataGeneral.name }}
                  </h2>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h5>
                    {{ $t('register.titleForm') }}
                  </h5>
                </v-col>
              </v-row>
            </router-link>
          </v-card-title>

          <v-card-text>
            <v-stepper
              v-model="activeStep"
              class="custom-header"
            >
              <!-- Header -->
              <v-stepper-header>
                <!-- Header: Step 1 -->
                <v-stepper-step
                  :complete="activeStep > 1"
                  step="1"
                  :rules="[() => stepValidationResult[1]]"
                >
                  <div class="d-flex align-center">
                    <span
                      class="text-4xl font-weight-bold me-3"
                      :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
                    >01</span>
                    <div class="d-flex flex-column">
                      <span
                        class="text-sm font-weight-semibold"
                        :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
                      >{{ $t('register.titlePerson') }}</span>
                      <span
                        class="text-xs"
                        :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
                      >{{
                        $t('register.subTitlePerson')
                      }}</span>
                    </div>
                  </div>
                </v-stepper-step>

                <v-divider></v-divider>

                <!-- Header: Step 2 -->
                <v-stepper-step
                  :complete="activeStep > 2"
                  step="2"
                  :rules="[() => stepValidationResult[2]]"
                >
                  <div class="d-flex align-center">
                    <span
                      class="text-4xl font-weight-bold me-3"
                      :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
                    >02</span>
                    <div class="d-flex flex-column">
                      <span
                        class="text-sm font-weight-semibold"
                        :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
                      >{{ $t('register.titleFactura') }}</span>
                      <span
                        class="text-xs"
                        :class="stepValidationResult[2] ? 'text--secondary' : 'error--text'"
                      >{{
                        $t('register.titleFact')
                      }}</span>
                    </div>
                  </div>
                </v-stepper-step>

                <v-divider></v-divider>

                <!-- Header: Step 3 -->
                <v-stepper-step
                  step="3"
                  :rules="[() => stepValidationResult[3]]"
                >
                  <div class="d-flex align-center">
                    <span
                      class="text-4xl font-weight-bold me-3"
                      :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
                    >03</span>
                    <div class="d-flex flex-column">
                      <span
                        class="text-sm font-weight-semibold"
                        :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
                      >{{ $t('register.profile') }}</span>
                      <span
                        class="text-xs"
                        :class="stepValidationResult[3] ? 'text--secondary' : 'error--text'"
                      >{{
                        $t('register.typeAfiliado')
                      }}</span>
                    </div>
                  </div>
                </v-stepper-step>
              </v-stepper-header>

              <!-- Stepper Items -->
              <v-stepper-items>
                <!-- Stepper Content: Step 1 -->
                <v-stepper-content step="1">
                  <v-form
                    ref="step1Form"
                    class="pb-6 pt-2"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      ></v-col>
                      <h3 class="ml-5">
                        {{ $t('register.subtitlePerson') }}
                      </h3>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.name_contact"
                          :label="`${$t('lbl.name')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('lbl.name')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.apellidos_contact"
                          :label="`${$t('lbl.apellidos')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('lbl.apellidos')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.email_contact"
                          :label="`${$t('register.emailPerson')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="emailRules"
                          :placeholder="$t('register.emailPerson')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="model.prefijo_number_contact"
                          :label="$t('register.codePais')"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.codePais')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="model.number_contact"
                          :label="`${$t('register.noTelefo')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="phoneRules"
                          :placeholder="$t('register.noTelefo')"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      ></v-col>
                      <h3 class="ml-5">
                        {{ $t('register.subtitleCompany') }}
                      </h3>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.name_comercial"
                          :label="`${$t('register.nameCompany')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.nameCompany')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.site_web"
                          :label="$t('register.siteWebCompany')"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="urlRules"
                          :placeholder="$t('register.siteWebCompany')"
                          clearable
                          @input="autocompletarWeb"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                  <div class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      class="me-2"
                      @click="handleStepValidation(1)"
                    >
                      {{ $t('btn.next') }}
                    </v-btn>
                  </div>
                </v-stepper-content>

                <!-- Stepper Content: Step 2 -->
                <v-stepper-content step="2">
                  <v-form
                    ref="step2Form"
                    class="pb-6 pt-2 multi-col-validation"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        class="pt-5"
                      >
                        <h3 class="pt-5">
                          {{ $t('register.titleFiscal') }}
                        </h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-switch
                          v-model="model.idem_comercial"
                          :label="$t('register.idemComercial')"
                          hide-details
                          @change="idemComercial"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.name_fiscal_facturation"
                          :label="`${$t('register.nameFact')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.nameFact')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-select
                          v-model="model.tipo_fiscal"
                          :items="tipos_fiscal"
                          :label="`${$t('register.tipoFiscal')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="model.cif_nif_facturation"
                          :label="`${$t('register.idFiscal')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.idFiscal')"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      ></v-col>
                      <h3 class="ml-5">
                        {{ $t('register.titleBanco') }}
                      </h3>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="model.no_cuenta_bancaria"
                          :label="`${$t('register.noBanco')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.noBanco')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-select
                          v-model="model.tipo_cuenta_bancaria"
                          :items="tipos_cuentas_bancarias"
                          :label="`${$t('register.tipoBanco')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-select
                          v-model="model.moneda"
                          :items="monedas"
                          :label="`${$t('register.moneda')} *`"
                          outlined
                          dense
                          item-text="code"
                          item-value="code"
                          hide-details="auto"
                          :rules="textRequiered"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="model.name_banco"
                          :label="`${$t('register.nameBanco')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.nameBanco')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="model.swift"
                          :label="`${$t('register.codeSWIFT')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="swiftRules"
                          :placeholder="$t('register.codeSWIFT')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="model.iban"
                          label="IBAN, IRC o CLABE"
                          outlined
                          dense
                          hide-details="auto"
                          placeholder="IBAN, IRC o CLABE"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="model.code_sucursal"
                          :label="`${$t('register.codeSucursal')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.codeSucursal')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="model.direction_facturation"
                          :label="`${$t('register.address')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.address')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="model.code_postal_facturation"
                          :label="$t('register.codPostal')"
                          outlined
                          dense
                          hide-details="auto"
                          :placeholder="$t('register.codPostal')"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          v-model="model.localidad_facturation"
                          :label="`${$t('register.locality')} *`"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                          :placeholder="$t('register.locality')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-select
                          v-model="model.country_facturation"
                          :items="countries"
                          :label="`${$t('register.country')} *`"
                          item-text="name"
                          item-value="name"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="textRequiered"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>

                  <div class="d-flex justify-space-between">
                    <v-btn
                      outlined
                      @click="activeStep = 1"
                    >
                      {{ $t('btn.preview') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="handleStepValidation(2)"
                    >
                      {{ $t('btn.next') }}
                    </v-btn>
                  </div>
                </v-stepper-content>

                <!-- Stepper Content: Step 3 -->
                <v-stepper-content step="3">
                  <v-form
                    ref="step3Form"
                    class="pb-6 pt-2 multi-col-validation"
                  >
                    <v-row>
                      <!-- Twitter -->
                      <v-col
                        v-for="(perfil, i) in perfiles"
                        :key="i"
                        cols="12"
                        md="6"
                      >
                        <v-checkbox
                          v-model="perfilesCheck"
                          hide-details
                          class="mt-1"
                          :value="perfil.id"
                        >
                          <template #label>
                            <v-row>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-card>
                                  <v-card-text>
                                    <span
                                      class="me-2"
                                    ><b>{{ perfil.name }}</b></span>
                                  </v-card-text>
                                  <v-card-subtitle>{{ perfil.description }}</v-card-subtitle>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>

                  <!-- Action Buttons -->
                  <div class="d-flex justify-space-between">
                    <v-btn
                      outlined
                      @click="activeStep = 2"
                    >
                      {{ $t('btn.preview') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="perfilesCheck.length === 0"
                      @click="handleStepValidation(3)"
                    >
                      {{ $t('btn.complete') }}
                    </v-btn>
                  </div>

                  <!-- <v-snackbar v-model="isFormSubmittedSnackbarVisible">
                    Form Submitted Successfully.
                  </v-snackbar> -->
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--<v-row v-else>
      <v-col
        cols="12"
        md="4"
      ></v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </v-row> -->

    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <WstIzq
      v-if="name_project === 'wwwww'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />

    <ProIzq
      v-if="name_project === 'ppppp'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />

    <KroIzq
      v-if="name_project === 'kkkkk'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'

export default {
  components: {
    ProIzq,
    KroIzq,
    WstIzq,
  },
  data() {
    return {
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      model: {},
      perfilesCheck: [],
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      perfiles: [],
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      countries: [],
      loading: false,
      paisUser: {},
      activeStep: 1,
      step1Form: null,
      step2Form: null,
      step3Form: null,
      stepValidationResult: {
        1: true,
        2: true,
        3: true,
      },
      isFormSubmittedSnackbarVisible: false,
      validators: {
        required: true,
      },
      userData: {},
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => this.isEmail(v) || 'E-mail debe ser válido'],
      urlRules: [v => this.isURL(v) || 'URL no es válida'],
      phoneRules: [v => !!v || 'Campo requerido', v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      swiftRules: [
        v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
    }
  },
  computed: {
    ...mapState({
      configDataGeneral: state => state.app.configDataGeneral,
    }),
  },
  created() {
    this.axios
      .get('https://ipapi.co/json')
      .then(res => {
        // console.log(res.data)
        // country_code
        this.paisUser = res.data
        this.model.data_country_contact = this.paisUser
        this.model.prefijo_number_contact = this.paisUser.country_calling_code
        this.model.prefijo_number_facturation = this.paisUser.country_calling_code
        this.model.country_facturation = this.paisUser.country_name
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => {
        this.getCountries()
      })
    this.getPerfiles()
    this.tipos_fiscal.push('NIT')
    this.tipos_fiscal.push('NIF')
    this.tipos_fiscal.push('RTN')
    this.tipos_fiscal.push('RUC')
    this.tipos_fiscal.push('RFC')
    this.tipos_fiscal.push('RUT')
    this.tipos_fiscal.push('EIN')

    this.tipos_fiscal.sort()

    this.tipos_cuentas_bancarias.push('Ahorros')
    this.tipos_cuentas_bancarias.push('Corriente')
  },
  methods: {
    isURL(str) {
      let url

      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        url = new URL(str)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    autocompletarWeb() {
      if (this.model.site_web) {
        if (this.model.site_web.length === 1) {
          this.model.site_web = `https://${this.model.site_web}`
        }
      }
    },
    getCountries() {
      this.axios
        .get('country_list?per_page=1000')
        .then(res => {
          this.countries = res.data.data
          this.countries.forEach(element => {
            if (this.paisUser.country_code) {
              if (element.code === this.paisUser.country_code) {
                this.model.country_facturation = element.name
              }
            }

            /* if (element.currencies) {
            element.currencies.forEach(mmm => {
              if (!this.monedas.includes(mmm.code)) {
                this.monedas.push(mmm.code)
              }
            })
          } */
          })
        })
        .finally(() => {
          this.getCurrencies()
        })

      /* this.axios
        .get('https://restcountries.com/v2/all?fields=name,currencies,alpha2Code')
        .then(res => {
          // console.log(res.data)
          this.countries = res.data
          this.countries.forEach(element => {
            if (element.alpha2Code === this.paisUser.country_code) {
              this.model.country_facturation = element.name
            }
            const mone = element.currencies
            if (mone) {
              mone.forEach(mmm => {
                if (!this.monedas.includes(mmm.code)) {
                  this.monedas.push(mmm.code)
                }
              })
            }
          })
        }) */
    },
    getCurrencies() {
      // &active=1
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.monedas = res.data.data
      })
    },
    getPerfiles() {
      this.axios.get('perfil-afiliados').then(res => {
        this.perfiles = res.data.data
      })
    },
    inscribe() {
      this.loading = true
      this.model.perfiles_id = this.perfilesCheck
      this.axios
        .post('register-company', this.model)
        .then(res => {
          if (res.data.success) {
            this.$router.push({ name: 'user-registre-success' })
          } else {
            this.$toast.error(this.$t('register.msgExistCompany'))
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    handleStepValidation(step) {
      // Get Form templateRef of Step from argument
      if (step === 1) {
        const isStepValid = this.$refs.step1Form.validate()
        if (isStepValid) {
          this.axios.post('verify-email', { email: this.model.email_contact }).then(res => {
            if (res.data.success) {
              this.activeStep += 1
            } else {
              this.$toast.error(this.$t('register.msgExistEmailCompany', { n: this.model.email_contact }))
            }
          })
        }
      } else if (step === 2) {
        const isStepValid = this.$refs.step2Form.validate()
        if (isStepValid) {
          this.activeStep += 1
        }
      } else if (step === 3) {
        const isStepValid = this.$refs.step3Form.validate()
        if (isStepValid) {
          this.inscribe()
        }
      }
    },
    idemComercial() {
      if (this.model.idem_comercial) {
        this.model.name_fiscal_facturation = this.model.name_comercial
      } else {
        this.model.name_fiscal_facturation = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
