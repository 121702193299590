// import { mdiMenu, mdiSecurity } from '@mdi/js'

export default [
  {
    subheader: 'menu.subheader',
  },

  // BOOKING
  {
    title: 'menu.booking',
    icon: 'mdi-cart',
    pertenece: ['cotizador', 'reservas', 'tarifarios'],
    children: [
      {
        title: 'menu.cotizador',
        icon: 'mdi-sale-outline',
        to: 'cotizador',
        permiso: 'cotizador:list',
        pertenece: 'cotizador',
      },
      {
        title: 'menu.reservas',
        icon: 'mdi-cart-outline',
        to: 'reservations',
        permiso: 'reservas:list',
        pertenece: 'reservas',
      },

      /* {
        title: 'menu.tarifarios',
        icon: 'mdi-file-document-outline',
        to: 'tarifarios',
        permiso: 'tarifarios:list',
        pertenece: 'tarifarios',
      }, */
    ],
  },

  // PRODUCTOS
  {
    title: 'menu.products',
    icon: 'mdi-clipboard-text-multiple-outline',
    pertenece: ['vuelo', 'auto', 'hotel'],
    children: [
      {
        title: 'menu.flights',
        icon: 'mdi-airplane-takeoff',
        to: 'flights-list',
        permiso: 'flight:list',
        pertenece: 'vuelo',
      },
      {
        title: 'menu.cars',
        icon: 'mdi-car-outline',
        to: 'cars-list',
        permiso: 'car:list',
        pertenece: 'auto',
      },
      {
        title: 'menu.hotels',
        icon: 'mdi-bed-outline',
        to: 'hotels-list',
        permiso: 'hotel:list',
        pertenece: 'hotel',
      },
    ],
  },

  // CONTRATOS
  {
    title: 'menu.contratos',
    icon: 'mdi-menu',
    pertenece: ['contrato_flight', 'contrato_cars', 'contrato_hotels', 'oferts', 'stopSale'],
    children: [
      {
        title: 'menu.flights',
        icon: 'mdi-airplane-takeoff',
        to: 'flights-contrate-list',
        permiso: 'contrate_flights:list',
        pertenece: 'contrato_flight',
      },
      {
        title: 'menu.cars',
        icon: 'mdi-car-outline',
        to: 'cars-contrate-list',
        permiso: 'contrate_cars:list',
        pertenece: 'contrato_cars',
      },
      {
        title: 'menu.hotels',
        icon: 'mdi-bed-outline',
        to: 'hotels-contrate-list',
        permiso: 'contrate_hotels:list',
        pertenece: 'contrato_hotels',
      },
      {
        title: 'menu.stopSale',
        icon: 'mdi-cart-remove',
        to: 'stopSale-list',
        permiso: 'stopSale:list',
        pertenece: 'stopSale',
      },
      {
        title: 'menu.oferts',
        icon: 'mdi-tag-text-outline',
        to: 'ofertsEBB-list',
        permiso: 'oferts:list',
        pertenece: 'oferts',
      },
    ],
  },

  // ADMINISTRACION
  {
    title: 'menu.gestion',
    icon: 'mdi-security',
    pertenece: ['ctas_x_cobrar', 'ctas_x_pagar', 'pays_recibidos', 'pays_emitidos', 'finanzas', 'markups', 'comitions'],
    children: [
      {
        title: 'menu.markups',
        icon: 'mdi-percent-outline',
        to: 'markups-list',
        permiso: 'markup:list',
        pertenece: 'markups',
      },
      {
        title: 'menu.ctasXC',
        icon: 'mdi-bank-transfer-in',
        to: 'ctas-x-cobrar-list',
        permiso: 'ctas_x_cobrar:list',
        pertenece: 'ctas_x_cobrar',
      },
      {
        title: 'menu.ctasXP',
        icon: 'mdi-bank-transfer-out',
        to: 'ctas-x-pagar-list',
        permiso: 'ctas_x_pagar:list',
        pertenece: 'ctas_x_pagar',
      },

      /* {
        title: 'menu.paysRec',
        permiso: 'pays_recibidos:list',
        pertenece: 'pays_recibidos',
      },
      {
        title: 'menu.paysEmit',
        to: 'pays-emitidos-list',
        permiso: 'pays_emitidos:list',
        pertenece: 'pays_emitidos',
      }, */
      {
        title: 'menu.finanzas',
        icon: 'mdi-bank-transfer',
        to: 'finanzas-list',
        permiso: 'finanzas:list',
        pertenece: 'finanzas',
      },

      /* {
        title: 'menu.contability',
        to: 'contability-list',
        permiso: 'contabilidad:list',
        pertenece: 'contability',
      }, */
      {
        title: 'menu.comitions',
        to: 'currency-exchange-list',
        icon: 'mdi-cash-multiple',
        permiso: 'comisiones:list',
        pertenece: 'comitions',
      },

      /* {
        title: 'menu.export',
        icon: 'mdi-export',
        to: 'exportations-list',
        permiso: 'exportation:list',
        pertenece: 'exportations',
      }, */
    ],
  },

  // CONFIGURACION
  {
    title: 'menu.configuration',
    icon: 'mdi-cogs',
    pertenece: [
      'api_keys',
      'app_backoffice',
      'configuration_general',
      'afiliados',
      'permisos',
      'perfil_afiliados',
      'roles',
      'users',
      'agentes',
      'tipo_afiliado',
      'chatbot_setting',
      'newletters',
      'chatbot_messages_auto',
      'smtp_emails',
      'bot_telegram',
    ],
    children: [
      {
        title: 'menu.perfilUser',
        icon: 'mdi-account-box-multiple-outline',
        to: 'perfil-afiliado-list',
        permiso: 'perfil_afiliado:list',
        pertenece: 'perfil_afiliados',
      },
      {
        title: 'menu.roles',
        icon: 'mdi-account-eye-outline',
        to: 'roles-list',
        permiso: 'role:list',
        pertenece: 'roles',
      },
      {
        title: 'menu.users',
        icon: 'mdi-account-multiple',
        to: 'users-list',
        permiso: 'user:list',
        pertenece: 'users',
      },
      {
        title: 'menu.agentes',
        icon: 'mdi-badge-account',
        to: 'agentes-list',
        permiso: 'agente:list',
        pertenece: 'agentes',
      },
      {
        title: 'menu.companies',
        icon: 'mdi-domain',
        to: 'user-register-list',
        permiso: 'user_register:list',
        pertenece: 'afiliados',
      },
      {
        title: 'API',
        icon: 'mdi-api',
        to: 'api-keys-list',
        permiso: 'api_keys:list',
        pertenece: 'api_keys',
      },
      {
        title: 'APPs',
        icon: 'mdi-tablet-cellphone',
        to: 'apps-list',
        permiso: 'app_backoffice:list',
        pertenece: 'app_backoffice',
      },
      {
        title: 'menu.dataGeneral',
        icon: 'mdi-clipboard-file-outline',
        to: 'configuration-general',
        permiso: 'config_general:list',
        pertenece: 'configuration_general',
      },
      {
        title: 'Newsletter',
        icon: 'mdi-email-newsletter',
        to: 'newletters-list',
        permiso: 'newletters:list',
        pertenece: 'newletters',
      },
      {
        title: 'menu.notifications',
        icon: 'mdi-note-multiple-outline',
        permiso: 'dns_emails:list',
        children: [
          {
            title: 'DNS - Email',
            icon: 'mdi-dns-outline',
            to: 'dns',
            permiso: 'dns_emails:list',
            pertenece: 'smtp_emails',
          },
          {
            title: 'Bot telegram',
            icon: 'mdi-reply-all-outline',
            to: 'bot-telegram',
            permiso: 'bot_telegram:list',
            pertenece: 'bot_telegram',
          },
          {
            title: 'Dashboard',
            icon: 'mdi-view-dashboard-outline',
            to: 'dashboard-update',
            permiso: 'dashboard_cards:list',
            pertenece: 'dashboard_cards',
          },
        ],
      },
      {
        title: 'menu.chatbot',
        icon: 'mdi-message-text',
        permiso: 'chatbot:list',
        children: [
          {
            title: 'menu.configuration',
            icon: 'mdi-cogs',
            to: 'chatbot-settings',
            permiso: 'chatbot:edit',
            pertenece: 'chatbot_setting',
          },
          {
            title: 'menu.messagesInit',
            icon: 'mdi-reply-all-outline',
            to: 'chatbot-messages-auto',
            permiso: 'chatbot:list',
            pertenece: 'chatbot_messages_auto',
          },
          {
            title: 'menu.talking',
            icon: 'mdi-forum-outline',
            to: 'chatbot-talkings',
            permiso: 'chatbot:list',
            pertenece: 'chatbot_talkings',
          },
        ],
      },

      /* {
        title: 'menu.permisos',
        to: 'permisos-list',
        permiso: 'permisos:list',
        pertenece: 'permisos',
      }, */
    ],
  },

  // NOMENCLADORES
  {
    title: 'menu.nomenclador',
    icon: 'mdi-clipboard-text-multiple-outline',
    pertenece: [
      'nom_aerolines',
      'product_aeronave',
      'airports',
      'nom_tarjeta_fidelidad_flights',
      'nom_code_ofac_flights',
      'class_flight',
      'tarifas_flight',
      'nom_dimensiones_flights',
      'nom_peso_equipaje_flights',
      'nom_politica_tarifaria_flights',
      'nom_transmision_cars',
      'import_airport',
      'nom_transmision_cars',
      'nom_type_auto_cars',
      'nom_marca_cars',
      'nom_model_cars',
      'nom_combustible_cars',
      'nom_caracteristica_cars',
      'nom_categories_cars',
      'nom_rentadora_cars',
      'nom_tarifas_cars',
      'nom_tarifas_x_km_cars',
      'nom_edades_cars',
      'nom_tags_pto_recogida_cars',
      'nom_oficina_renta_cars',
      'nom_suplemento_contrate_cars',
      'nom_tag_imagen_cars',
      'nom_suplemento_contrate_fligths',
      'nom_suplemento_contrate_hotels',
      'nom_reducciones_contrate_hotels',
      'nom_categories_conditions',
      'nom_conditions_generals_flights',
      'nom_categories_tarifarios_hotels',
      'nomenclators_tti',
      'nomenclators_countries',
      'nomenclators_currencies',
    ],
    children: [
      {
        title: 'menu.flights',
        icon: 'mdi-airplane-takeoff',
        permiso: 'nom_aerolines:list',
        children: [
          {
            title: 'TTI Zenith',
            icon: 'mdi-paperclip-plus',
            to: 'tii-list',
            permiso: 'nomenclators_tti:list',
            pertenece: 'nomenclators_tti',
          },
          {
            title: 'menu.suplementos',
            icon: 'mdi-paperclip-plus',
            to: 'suplementos-flights-list',
            permiso: 'nom_suplemento_contrate_fligths:list',
            pertenece: 'nom_suplemento_contrate_fligths',
          },
          {
            title: 'menu.conditionGenerals',
            icon: 'mdi-content-duplicate',
            to: 'conditions-generals-flights-list',
            permiso: 'nom_conditions_generals_fligths:list',
            pertenece: 'nom_conditions_generals_flights',
          },
          {
            title: 'menu.aerolinea',
            icon: 'mdi-shield-airplane-outline',
            to: 'aerolinea-flights-list',
            permiso: 'nom_aerolines:list',
            pertenece: 'nom_aerolines',
          },
          {
            title: 'menu.aeronave',
            to: 'aircraft-flights-list',
            permiso: 'airplane:list',
            pertenece: 'product_aeronave',
          },
          {
            title: 'menu.airport',
            to: 'airport-flights-list',
            permiso: 'airport:list',
            pertenece: 'airports',
          },
          {
            title: 'menu.class',
            to: 'clase-flights-list',
            permiso: 'nom_clase_flights:list',
            pertenece: 'class_flight',
          },
          {
            title: 'menu.tarifas',
            to: 'tarifas-flights-list',
            permiso: 'nom_tarifa_flights:list',
            pertenece: 'tarifas_flight',
          },
          {
            title: 'menu.cardFidelidad',
            to: 'tarjeta-fidelidad-flights-list',
            permiso: 'nom_tarjeta_fidelidad_flights:list',
            pertenece: 'nom_tarjeta_fidelidad_flights',
          },
          {
            title: 'menu.codeOFAC',
            to: 'code-ofac-flights-list',
            permiso: 'nom_code_ofac_flights:list',
            pertenece: 'nom_code_ofac_flights',
          },
          {
            title: 'menu.dimensiones',
            to: 'dimensiones-equipaje-flights-list',
            permiso: 'nom_dimensiones_flights:list',
            pertenece: 'nom_dimensiones_flights',
          },
          {
            title: 'menu.pesos',
            to: 'peso-equipaje-flights-list',
            permiso: 'nom_peso_equipaje_flights:list',
            pertenece: 'nom_peso_equipaje_flights',
          },
          {
            title: 'menu.politicaTarifa',
            to: 'politica-tarifaria-flights-list',
            permiso: 'nom_politica_tarifaria_flights:list',
            pertenece: 'nom_politica_tarifaria_flights',
          },

          /* {
            title: 'menu.jsonAirport',
            to: 'json-airport-import',
            permiso: 'json_airport:import',
            pertenece: 'import_airport',
          }, */
        ],
      },
      {
        title: 'menu.cars',
        icon: 'mdi-car-outline',
        permiso: 'nom_oficina_renta_cars:list',
        children: [
          {
            title: 'menu.suplementos',
            icon: 'mdi-paperclip-plus',
            to: 'suplementos-cars-list',
            permiso: 'nom_suplemento_contrate_cars:list',
            pertenece: 'nom_suplemento_contrate_cars',
          },
          {
            title: 'menu.conditionGenerals',
            icon: 'mdi-content-duplicate',
            to: 'conditions-generals-cars-list',
            permiso: 'nom_conditions_generals_cars:list',
            pertenece: 'nom_conditions_generals_cars',
          },
          {
            title: 'menu.ptoRecogida',
            to: 'recogida-cars-list',
            permiso: 'nom_tags_pto_recogida_cars:list',
            pertenece: 'nom_tags_pto_recogida_cars',
          },
          {
            title: 'menu.oficinaRenta',
            to: 'oficinas-cars-list',
            permiso: 'nom_oficina_renta_cars:list',
            pertenece: 'nom_oficina_renta_cars',
          },
          {
            title: 'menu.trasnmision',
            to: 'trasnmision-cars-list',
            permiso: 'nom_transmision_cars:list',
            pertenece: 'nom_transmision_cars',
          },
          {
            title: 'menu.typeCar',
            to: 'type-cars-list',
            permiso: 'nom_type_auto_cars:list',
            pertenece: 'nom_type_auto_cars',
          },
          {
            title: 'menu.marca',
            to: 'marca-cars-list',
            permiso: 'nom_marca_cars:list',
            pertenece: 'nom_marca_cars',
          },
          {
            title: 'menu.modelo',
            to: 'modelo-cars-list',
            permiso: 'nom_model_cars:list',
            pertenece: 'nom_model_cars',
          },
          {
            title: 'menu.combustible',
            to: 'combustible-cars-list',
            permiso: 'nom_combustible_cars:list',
            pertenece: 'nom_combustible_cars',
          },
          {
            title: 'menu.caracteristicas',
            to: 'caracteristicas-cars-list',
            permiso: 'nom_caracteristica_cars:list',
            pertenece: 'nom_caracteristica_cars',
          },
          {
            title: 'menu.category',
            to: 'categories-cars-list',
            permiso: 'nom_categories_cars:list',
            pertenece: 'nom_categories_cars',
          },
          {
            title: 'menu.rentadora',
            to: 'rentadoras-cars-list',
            permiso: 'nom_rentadora_cars:list',
            pertenece: 'nom_rentadora_cars',
          },
          {
            title: 'lbl.rangeDaysRent',
            to: 'tarifas-cars-list',
            permiso: 'nom_tarifas_cars:list',
            pertenece: 'nom_tarifas_cars',
          },
          {
            title: 'menu.tarifasXKm',
            to: 'tarifas-x-km-cars-list',
            permiso: 'nom_tarifas_x_km_cars:list',
            pertenece: 'nom_tarifas_x_km_cars',
          },
          {
            title: 'lbl.edadConductor',
            to: 'edades-cars-list',
            permiso: 'nom_edades_cars:list',
            pertenece: 'nom_edades_cars',
          },
          {
            title: 'menu.tagsImg',
            to: 'tag-img-cars-list',
            permiso: 'nom_tag_imagen_cars:list',
            pertenece: 'nom_tag_imagen_cars',
          },
        ],
      },
      {
        title: 'menu.hotels',
        icon: 'mdi-bed-outline',
        permiso: 'nom_tipo_destino_hotels:list',
        children: [
          {
            title: 'lbl.suplementReducc',
            icon: 'mdi-paperclip-plus',
            to: 'suplementos-hotels-list',
            permiso: 'nom_suplemento_contrate_hotels:list',
            pertenece: 'nom_suplemento_contrate_hotels',
          },

          /* {
            title: 'menu.reducciones',
            to: 'reducciones-hotels-list',
            permiso: 'nom_reducciones_contrate_hotels:list',
            pertenece: 'nom_reducciones_contrate_hotels',
          }, */
          {
            title: 'menu.conditionGenerals',
            icon: 'mdi-content-duplicate',
            to: 'conditions-generals-hotels-list',
            permiso: 'nom_conditions_generals_hotels:list',
            pertenece: 'nom_conditions_generals_hotels',
          },
          {
            title: 'menu.typeDestination',
            to: 'tipo-destino-hotels-list',
            permiso: 'nom_tipo_destino_hotels:list',
            pertenece: 'nom_tipo_destino_hotels',
          },
          {
            title: 'menu.operador',
            to: 'operador-hotels-list',
            permiso: 'nom_operador_hotels:list',
            pertenece: 'nom_operador_hotels',
          },
          {
            title: 'menu.marcaAsociada',
            to: 'marca-asociada-hotels-list',
            permiso: 'nom_marca_asociada_hotels:list',
            pertenece: 'nom_marca_asociada_hotels',
          },
          {
            title: 'menu.category',
            to: 'categorias-hotels-list',
            permiso: 'nom_categorias_hotels:list',
            pertenece: 'nom_categorias_hotels',
          },
          {
            title: 'menu.intereses',
            to: 'intereses-hotels-list',
            permiso: 'nom_intereses_hotels:list',
            pertenece: 'nom_intereses_hotels',
          },
          {
            title: 'menu.servicios',
            to: 'servicios-hotels-list',
            permiso: 'nom_servicios_hotels:list',
            pertenece: 'nom_servicios_hotels',
          },
          {
            title: 'menu.habitaciones',
            to: 'habitaciones-hotels-list',
            permiso: 'nom_habitacion_hotels:list',
            pertenece: 'nom_habitacion_hotels',
          },
          {
            title: 'menu.tipoCamas',
            to: 'tipo-cama-hotels-list',
            permiso: 'nom_habitacion_hotels:list',
            pertenece: 'tipo_cama_hotels',
          },
          {
            title: 'menu.categoriesTarifarios',
            to: 'categories-tarifarios-hotels-list',
            permiso: 'nom_categories_tarifarios_hotels:list',
            pertenece: 'nom_categories_tarifarios_hotels',
          },
          {
            title: 'menu.regimenAlimenticio',
            to: 'regimen-alimenticio-hotels-list',
            permiso: 'nom_regimen_alimenticio:list',
            pertenece: 'nom_regimen_alimenticio',
          },

          /* {
            title: 'menu.edadMin',
            to: 'edad-hotels-list',
            permiso: 'nom_edad_hotels:list',
            pertenece: 'nom_edad_hotels',
          }, */
          {
            title: 'menu.tagsImg',
            to: 'tag-img-hotels-list',
            permiso: 'nom_tag_imagen_hotels:list',
            pertenece: 'nom_tag_imagen_hotels',
          },
        ],
      },
      {
        title: 'menu.categoryCondition',
        icon: 'mdi-content-duplicate',
        to: 'categories-conditions-list',
        permiso: 'nom_categories_conditions:list',
        pertenece: 'nom_categories_conditions',
      },
      {
        title: 'menu.typeAfiliado',
        icon: 'mdi-account-box-outline',
        to: 'tipo-afiliado-list',
        permiso: 'tipo_afiliado:list',
        pertenece: 'tipo_afiliado',
      },
      {
        title: 'lbl.countries',
        icon: 'mdi-earth',
        to: 'nomenclators-countries-list',
        permiso: 'nomenclators_countries:list',
        pertenece: 'nomenclators_countries',
      },
      {
        title: 'lbl.currencies',
        icon: 'mdi-cash',
        to: 'nomenclators-currencies-list',
        permiso: 'nomenclators_currencies:list',
        pertenece: 'nomenclators_currencies',
      },
    ],
  },

  // LOGS
  {
    title: 'menu.logs',
    icon: 'mdi-math-log',
    to: 'logs-user',
    permiso: 'logs:list',
    pertenece: ['logs_user'],
  },

  // MODERACIONES
  {
    title: 'menu.moderations',
    icon: 'mdi-checkbox-marked-circle-plus-outline',
    to: 'moderations-list',
    permiso: 'moderation:list',
    pertenece: ['moderation'],
  },

  // TUTORIALS
  {
    title: 'menu.tutorials',
    icon: 'mdi-video-outline',
    to: 'tutorials-list',
    permiso: 'tutorials:list',
    pertenece: ['tutorials'],
  },

  // TRASLATE
  {
    title: 'menu.translate',
    icon: 'mdi-translate',
    pertenece: [
      'traslate_pages',
    ],
    children: [
      {
        title: 'menu.web',
        icon: 'mdi-web',
        to: 'pages-web',
        permiso: 'traslate_pages:list',
        pertenece: 'traslate_pages',
      },
      {
        title: 'menu.products',
        icon: 'mdi-clipboard-text-multiple-outline',
        pertenece: ['vuelo', 'auto', 'traslate_hotel'],
        children: [
          {
            title: 'menu.hotels',
            icon: 'mdi-bed-outline',
            to: 'traslate-hotel-product',
            permiso: 'hotel:edit',
            pertenece: 'traslate_hotel',
          },
        ],
      },
    ],
  },
]
