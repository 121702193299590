// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  {
    path: '/traslate/web',
    name: 'pages-web',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/Index.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_pages',
    },
  },
  {
    path: '/traslate/web-update',
    name: 'pages-web-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/form/Edit.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_pages',
    },
  },

  {
    path: '/traslate/hotel-product',
    name: 'traslate-hotel-product',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/traslate/HotelsProduct.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'traslate_hotel',
    },
  },
]

export default formTable
