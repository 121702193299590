/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
// eslint-disable-next-line import/no-unresolved
import router from '@/router'
import axios from 'axios'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { mdiHomeOutline } from '@mdi/js'

require('moment/locale/es')

export default {
  setConfigDataGeneral: (state, data) => {
    state.configDataGeneral = data
  },
  logout() {
    localStorage.clear()
    router.push({ name: 'auth-login' })
  },
  GET_ABILITY({ ability }) {
    const permisos = localStorage.getItem('permisos_auth')
    if (permisos === '*') {
      return true
    }
    if (permisos.includes(ability)) {
      return true
    }

    return false
  },

  setCargandoMenu: (state, data) => {
    state.cargandoMenu = data
  },
  setIsLoadingPage: (state, data) => {
    state.isLoadingPage = data
  },
  setPermisosAuth: (state, data) => {
    state.permisosAuth = data
  },
  setCardsDashboard: (state, data) => {
    state.cardsDashboard = data
  },
  setChatBotRead: (state, data) => {
    state.chatBotRead = data
  },
  setSalesWeeks: (state, data) => {
    state.salesWeeks = data
  },
  setSalesWeek: (state, data) => {
    state.salesWeek = data
  },
  setSalesMonths: (state, data) => {
    state.salesMonthAll = data
  },
  setSalesMonth: (state, data) => {
    state.salesMonth = data
  },
  setSalesMonthPreviews: (state, data) => {
    state.salesMonthPreviews = data
  },
  setSalesMonthPreview: (state, data) => {
    state.salesMonthPreview = data
  },
  setContratesPendientsHotelsAll: (state, data) => {
    state.contratesPendientsHotelsAll = data
  },
  setContratesPendientsHotelsUser: (state, data) => {
    state.contratesPendientsHotelsUser = data
  },
  setAppsDownload: (state, data) => {
    state.appsDownload = data
  },
  setDnsEmpty: (state, data) => {
    state.dnsEmpty = data
  },
  setProductsList: (state, data) => {
    state.productsList = data
  },
  setConfigCotizadorHotel: (state, data) => {
    state.configCotizadorHotel = data
  },
  setMaxAgeCotizadorHotel: (state, data) => {
    state.maxAgeCotizadorHotel = data
  },
  setListUser: (state, data) => {
    state.listUser = data
  },
  setShowCardCreateUser: (state, data) => {
    state.showCardCreateUser = data
  },
  setShowCardCreateSeller: (state, data) => {
    state.showCardCreateSeller = data
  },
  setShowCardCreateAgent: (state, data) => {
    state.showCardCreateAgent = data
  },
  setShowCardCreateUserAdmin: (state, data) => {
    state.showCardCreateUserAdmin = data
  },
  setShowCardMarkups: (state, data) => {
    state.showCardMarkups = data
  },
  setShowCardIdentificadorInfo: (state, data) => {
    state.showCardIdentificadorInfo = data
  },
  setShowCardIdentificadorBanco: (state, data) => {
    state.showCardIdentificadorBanco = data
  },
  setShowCardIdentificadorBooking: (state, data) => {
    state.showCardIdentificadorBooking = data
  },
  setShowCardIdentificadorIden: (state, data) => {
    state.showCardIdentificadorIden = data
  },
  setCarsProductCache: (state, data) => {
    state.carsProductCache = data
  },
  setCarsProductFiltersCache: (state, data) => {
    state.carsProductFiltersCache = data
  },
  setHotelsProductCache: (state, data) => {
    state.hotelsProductCache = data
  },
  setHotelsProductListCache: (state, data) => {
    state.hotelsProductListCache = data
  },
  setHotelsProductFiltersCache: (state, data) => {
    state.hotelsProductFiltersCache = data
  },
  setCarsContrateCache: (state, data) => {
    state.carsContrateCache = data
  },
  setHotelsContrateCache: (state, data) => {
    state.hotelsContrateCache = data
  },
  setHotelsContrateListCache: (state, data) => {
    state.hotelsContrateListCache = data
  },
  setHotelsContrateShowCache: (state, data) => {
    state.hotelsContrateShowCache = data
  },
  setActiveDeepL: (state, data) => {
    state.activeDeepL = data
  },
  setDeepLCountTranslate: (state, data) => {
    state.deepLCountTranslate = data
  },

  // ACTUALIZAR LOS PERMISOS DEL ROL O PERFIL AFILIADO
  getPerfilesAuth: state => {
    state.userProfile = JSON.parse(localStorage.getItem('profile'))
    state.perfilesAll = []
    state.perfilesAuth = []

    const permisosUser = state.userProfile.permisos
    const tablerosUser = JSON.parse(localStorage.getItem('tableros_auth'))

    const permisos = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tablerosUser.length; index++) {
      const element = tablerosUser[index]
      if (element.active) {
        state.perfilesAuth.push({
          title: element.name,
          icon: mdiHomeOutline,
          to: { name: 'dashboard', params: { perfil: element.slug } },
          slug: element.slug,
          permiso: '',
          active: localStorage.getItem('perfil') === element.slug,
        })

        if (localStorage.getItem('perfil') === 'propietario' || localStorage.getItem('perfil') === 'soporte') {
          if (state.userProfile.nivel === -1 || state.userProfile.nivel === 0) {
            if (state.userProfile.nivel === -1) {
              // ONLY LEVEL SOPORT
              permisos.push('exportation:list')
            }
            permisosUser.forEach(ability => {
              permisos.push(ability)
            })
          }
        } else if (localStorage.getItem('perfil') === element.slug) {
          if (state.userProfile.nivel === -1 || state.userProfile.nivel === 0) {
            if (state.userProfile.nivel === -1) {
              // ONLY LEVEL SOPORT
              permisos.push('exportation:list')
            }
            element.permisos.abilities.forEach(ability => {
              if (permisosUser.includes(ability)) {
                permisos.push(ability)
              }
            })
          } else {
            if (state.userProfile.nivel === 1 || state.userProfile.nivel === 2) {
              const denegados = state.userProfile.company.denegado
              element.permisos.abilities.forEach(ability => {
                if (!denegados.includes(ability) && permisosUser.includes(ability)) {
                  permisos.push(ability)
                }
              })
            }
          }
        }

        state.permisosAuth = permisos
        localStorage.setItem('permisos_auth', permisos)
      }
    }
  },
  getPerfilesAuthOld: state => {
    axios
      .get(`${process.env.VUE_APP_API_URL}profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      })
      .then(response => {
        // console.log(response.data.data.perfiles)
        // eslint-disable-next-line no-empty
        if (response.data.success === false) {
        } else {
          state.userProfile = response.data.data
          state.perfilesAll = []
          state.perfilesAuth = []

          const permisosUser = response.data.data.permisos.abilities

          const permisos = []
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < response.data.data.tableros.length; index++) {
            const element = response.data.data.tableros[index]
            if (element.active) {
              state.perfilesAuth.push({
                title: element.name,
                icon: mdiHomeOutline,
                to: { name: 'dashboard', params: { perfil: element.slug } },
                slug: element.slug,
                permiso: '',
                active: localStorage.getItem('perfil') === element.slug,
              })

              if (localStorage.getItem('perfil') === 'propietario' || localStorage.getItem('perfil') === 'soporte') {
                if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
                  if (response.data.data.nivel === -1) {
                    // ONLY LEVEL SOPORT
                    permisos.push('exportation:list')
                  }
                  permisosUser.forEach(ability => {
                    permisos.push(ability)
                  })

                  /* element.permisos.abilities.forEach(ability => {
                    if (permisosUser.includes(ability)) {
                      permisos.push(ability)
                    }
                  }) */
                }
              } else if (localStorage.getItem('perfil') === element.slug) {
                if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
                  if (response.data.data.nivel === -1) {
                    // ONLY LEVEL SOPORT
                    permisos.push('exportation:list')
                  }
                  element.permisos.abilities.forEach(ability => {
                    if (permisosUser.includes(ability)) {
                      permisos.push(ability)
                    }
                  })
                } else {
                  if (response.data.data.nivel === 1 || response.data.data.nivel === 2) {
                    const denegados = response.data.data.company.denegado
                    element.permisos.abilities.forEach(ability => {
                      if (!denegados.includes(ability) && permisosUser.includes(ability)) {
                        permisos.push(ability)
                      }
                    })
                  }
                }
              }

              localStorage.setItem('permisos_auth', permisos)

              /* if (localStorage.getItem('perfil') === element.slug) {
                if (
                  response.data.data.id_father === null
                  || response.data.data.role.slug === 'super-admin'
                  || response.data.data.role.slug === 'superadmin'
                ) {
                  if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
                    if (response.data.data.nivel === -1) {
                      const permisos = element.permisos.abilities

                      // ONLY LEVEL SOPORT
                      permisos.push('exportation:list')

                      // permisos.push('nomenclators_countries:list')
                      localStorage.setItem('permisos_auth', permisos)
                    } else {
                      localStorage.setItem('permisos_auth', element.permisos.abilities)
                    }
                  } else {
                    if (response.data.data.nivel === 1 || response.data.data.nivel === 2) {
                      const permisos = []
                      const denegados = response.data.data.company.denegado
                      element.permisos.abilities.forEach(ability => {
                        if (!denegados.includes(ability)) {
                          permisos.push(ability)
                        }
                      })

                      localStorage.setItem('permisos_auth', permisos)
                    }
                  }
                } else {
                  if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
                    if (response.data.data.nivel === -1) {
                      const permisos = state.userProfile.role.permisos.abilities

                      // ONLY LEVEL SOPORT
                      permisos.push('exportation:list')

                      // permisos.push('nomenclators_countries:list')
                      localStorage.setItem('permisos_auth', permisos)
                    } else {
                      localStorage.setItem('permisos_auth', state.userProfile.role.permisos.abilities)
                    }
                  } else {
                    if (response.data.data.nivel === 1 || response.data.data.nivel === 2) {
                      const permisos = []
                      const denegados = response.data.data.company.denegado
                      element.permisos.abilities.forEach(ability => {
                        if (!denegados.includes(ability)) {
                          permisos.push(ability)
                        }
                      })

                      localStorage.setItem('permisos_auth', permisos)
                    }
                  }
                }
              } */
            }
          }

          /*
          for (let index = 0; index < response.data.data.tableros.length; index++) {
            const element = response.data.data.tableros[index]
            if (element.active) {
              state.perfilesAuth.push({
                title: element.name,
                icon: mdiHomeOutline,
                to: { name: 'dashboard', params: { perfil: element.slug } },
                slug: element.slug,
                permiso: '',
                active: localStorage.getItem('perfil') === element.slug,
              })

              if (localStorage.getItem('perfil') === element.slug) {
                if (
                  response.data.data.id_father === null
                  || response.data.data.role.slug === 'super-admin'
                  || response.data.data.role.slug === 'superadmin'
                ) {
                  if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
                    if (response.data.data.nivel === -1) {
                      const permisos = element.permisos.abilities

                      // ONLY LEVEL SOPORT
                      permisos.push('exportation:list')

                      // permisos.push('nomenclators_countries:list')
                      localStorage.setItem('permisos_auth', permisos)
                    } else {
                      localStorage.setItem('permisos_auth', element.permisos.abilities)
                    }
                  } else {
                    if (response.data.data.nivel === 1 || response.data.data.nivel === 2) {
                      const permisos = []
                      const denegados = response.data.data.company.denegado
                      element.permisos.abilities.forEach(ability => {
                        if (!denegados.includes(ability)) {
                          permisos.push(ability)
                        }
                      })

                      localStorage.setItem('permisos_auth', permisos)
                    }
                  }
                } else {
                  if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
                    if (response.data.data.nivel === -1) {
                      const permisos = state.userProfile.role.permisos.abilities

                      // ONLY LEVEL SOPORT
                      permisos.push('exportation:list')

                      // permisos.push('nomenclators_countries:list')
                      localStorage.setItem('permisos_auth', permisos)
                    } else {
                      localStorage.setItem('permisos_auth', state.userProfile.role.permisos.abilities)
                    }
                  } else {
                    if (response.data.data.nivel === 1 || response.data.data.nivel === 2) {
                      const permisos = []
                      const denegados = response.data.data.company.denegado
                      element.permisos.abilities.forEach(ability => {
                        if (!denegados.includes(ability)) {
                          permisos.push(ability)
                        }
                      })

                      localStorage.setItem('permisos_auth', permisos)
                    }
                  }
                }
              }
            }
          }
          */

          /* if (response.data.data.nivel === -1) {
            state.perfilesAuth.push({
              title: response.data.data.perfil_afiliado.name,
              icon: mdiHomeOutline,
              to: { name: 'dashboard', params: { perfil: response.data.data.perfil_afiliado.slug } },
              slug: response.data.data.perfil_afiliado.slug,
              permiso: '',
              active: localStorage.getItem('perfil') === response.data.data.perfil_afiliado.slug,
            })
          } else if (response.data.data.nivel === 0) {
            state.perfilesAuth.push({
              title: response.data.data.perfil_afiliado.name,
              icon: mdiHomeOutline,
              to: { name: 'dashboard', params: { perfil: response.data.data.perfil_afiliado.slug } },
              slug: response.data.data.perfil_afiliado.slug,
              permiso: '',
              active: localStorage.getItem('perfil') === response.data.data.perfil_afiliado.slug,
            })
          } else {
            if (response.data.data.nivel === 1) {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < response.data.data.perfiles.length; index++) {
                const element = response.data.data.perfiles[index]
                if (element.active) {
                  state.perfilesAuth.push({
                    title: element.name,
                    icon: mdiHomeOutline,
                    to: { name: 'dashboard', params: { perfil: element.slug } },
                    slug: element.slug,
                    permiso: '',
                    active: localStorage.getItem('perfil') === element.slug,
                  })
                  if (localStorage.getItem('perfil') === element.slug) {
                    if (response.data.data.id_father === null) {
                      localStorage.setItem('permisos_auth', element.permisos.abilities)
                    } else {
                      localStorage.setItem('permisos_auth', state.userProfile.role.permisos.abilities)
                    }
                  }
                }
              }
            } else {
              state.perfilesAuth.push({
                title: response.data.data.agente.perfil.name,
                icon: mdiHomeOutline,
                to: { name: 'dashboard', params: { perfil: response.data.data.agente.perfil.slug } },
                slug: response.data.data.agente.perfil.slug,
                permiso: '',
                active: localStorage.getItem('perfil') === response.data.data.agente.perfil.slug,
              })
            }
          } */
        }
      })
      .catch(error => console.log(error))
  },

  verifyAllPermisosItem: (state, pos) => {
    const { frecuencias } = state

    frecuencias.push(frecuencias[pos])
  },

  // ACTUALIZAR EL ESTADO DEL PERFIL
  updatePerfilesAuth: (state, itemActive) => {
    state.perfilesAuth.forEach(element => {
      if (element.slug !== itemActive.slug) {
        // eslint-disable-next-line no-param-reassign
        element.active = false
      } else {
        // eslint-disable-next-line no-param-reassign
        element.active = true
      }
    })

    // console.log(state.perfilesAuth)
    // console.log(itemActive)
  },

  // ACTUALIZAR LOS PERMISOS DEL ROL O PERFIL AFILIADO
  editPermisosItem: (state, permisosItem) => {
    state.permisosItem = permisosItem
  },

  // EDITAR LA INFORMACION DE LA VISTA DE PERMISOS
  setPermisosTab: (state, view) => {
    state.sourcePermisos = []
    state.viewPermisos = view
  },
  setPermisosTabLoading: (state, status) => {
    state.isLoadingPagePermisos = status
  },
  setSourcePermisos: (state, update) => {
    state.sourcePermisos = update
  },
  setSourcePermisosPos: (state, { pos, update }) => {
    state.sourcePermisos[pos].permisos = update
  },
  verifyPermisoZona: (state, pos) => {
    // BOOKING
    if (
      state.sourcePermisos[pos].permisos.includes('cotizador:list')
      && state.sourcePermisos[pos].permisos.includes('reservas:list')
      && state.sourcePermisos[pos].permisos.includes('pvp:list')
      && state.sourcePermisos[pos].permisos.includes('tarifarios:list')
      && state.sourcePermisos[pos].permisos.includes('tarifario_neto:list')
    ) {
      state.sourcePermisos[pos].all.checkboxBookingVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('cotizador:edit')
      && state.sourcePermisos[pos].permisos.includes('reservas:edit')
      && state.sourcePermisos[pos].permisos.includes('pvp:edit')
      && state.sourcePermisos[pos].permisos.includes('tarifarios:edit')
      && state.sourcePermisos[pos].permisos.includes('tarifario_neto:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxBookingEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('cotizador:create')
      && state.sourcePermisos[pos].permisos.includes('reservas:create')
      && state.sourcePermisos[pos].permisos.includes('pvp:create')
      && state.sourcePermisos[pos].permisos.includes('tarifarios:create')
      && state.sourcePermisos[pos].permisos.includes('tarifario_neto:create')
    ) {
      state.sourcePermisos[pos].all.checkboxBookingCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('cotizador:destroy')
      && state.sourcePermisos[pos].permisos.includes('reservas:destroy')
      && state.sourcePermisos[pos].permisos.includes('pvp:destroy')
      && state.sourcePermisos[pos].permisos.includes('tarifarios:destroy')
      && state.sourcePermisos[pos].permisos.includes('tarifario_neto:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxBookingDelete = true
    }

    // PRODUCTS
    if (
      state.sourcePermisos[pos].permisos.includes('flight:list')
      && state.sourcePermisos[pos].permisos.includes('hotel:list')
      && state.sourcePermisos[pos].permisos.includes('car:list')
    ) {
      state.sourcePermisos[pos].all.checkboxProductsVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('flight:edit')
      && state.sourcePermisos[pos].permisos.includes('hotel:edit')
      && state.sourcePermisos[pos].permisos.includes('car:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxProductsEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('flight:create')
      && state.sourcePermisos[pos].permisos.includes('hotel:create')
      && state.sourcePermisos[pos].permisos.includes('car:create')
    ) {
      state.sourcePermisos[pos].all.checkboxProductsCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('flight:destroy')
      && state.sourcePermisos[pos].permisos.includes('hotel:destroy')
      && state.sourcePermisos[pos].permisos.includes('car:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxProductsDelete = true
    }

    // CONTRATOS
    if (
      state.sourcePermisos[pos].permisos.includes('contrate_flights:list')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:list')
      && state.sourcePermisos[pos].permisos.includes('stopSale:list')
      && state.sourcePermisos[pos].permisos.includes('oferts:list')
    ) {
      state.sourcePermisos[pos].all.checkboxContrateVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('contrate_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('stopSale:edit')
      && state.sourcePermisos[pos].permisos.includes('oferts:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxContrateEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('contrate_flights:create')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:create')
      && state.sourcePermisos[pos].permisos.includes('stopSale:create')
      && state.sourcePermisos[pos].permisos.includes('oferts:create')
    ) {
      state.sourcePermisos[pos].all.checkboxContrateCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('contrate_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('stopSale:destroy')
      && state.sourcePermisos[pos].permisos.includes('oferts:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxContrateDelete = true
    }

    // ADMINISTRATION
    if (
      state.sourcePermisos[pos].permisos.includes('comisiones:list')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_cobrar:list')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_pagar:list')
      && state.sourcePermisos[pos].permisos.includes('pays_recibidos:list')
      && state.sourcePermisos[pos].permisos.includes('pays_emitidos:list')
      && state.sourcePermisos[pos].permisos.includes('markup:list')
      && state.sourcePermisos[pos].permisos.includes('finanzas:list')
    ) {
      state.sourcePermisos[pos].all.checkboxAdministrationVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('comisiones:edit')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_cobrar:edit')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_pagar:edit')
      && state.sourcePermisos[pos].permisos.includes('pays_recibidos:edit')
      && state.sourcePermisos[pos].permisos.includes('pays_emitidos:edit')
      && state.sourcePermisos[pos].permisos.includes('markup:edit')
      && state.sourcePermisos[pos].permisos.includes('finanzas:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxAdministrationEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('comisiones:create')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_cobrar:create')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_pagar:create')
      && state.sourcePermisos[pos].permisos.includes('pays_recibidos:create')
      && state.sourcePermisos[pos].permisos.includes('pays_emitidos:create')
      && state.sourcePermisos[pos].permisos.includes('markup:create')
      && state.sourcePermisos[pos].permisos.includes('finanzas:create')
    ) {
      state.sourcePermisos[pos].all.checkboxAdministrationCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('comisiones:destroy')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_cobrar:destroy')
      && state.sourcePermisos[pos].permisos.includes('ctas_x_pagar:destroy')
      && state.sourcePermisos[pos].permisos.includes('pays_recibidos:destroy')
      && state.sourcePermisos[pos].permisos.includes('pays_emitidos:destroy')
      && state.sourcePermisos[pos].permisos.includes('markup:destroy')
      && state.sourcePermisos[pos].permisos.includes('finanzas:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxAdministrationDelete = true
    }

    // CONFIGURATION
    if (
      state.sourcePermisos[pos].permisos.includes('config_general:list')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:list')
      && state.sourcePermisos[pos].permisos.includes('user_register:list')
      && state.sourcePermisos[pos].permisos.includes('role:list')
      && state.sourcePermisos[pos].permisos.includes('user:list')
      && state.sourcePermisos[pos].permisos.includes('users_son:list')
      && state.sourcePermisos[pos].permisos.includes('agente:list')
      && state.sourcePermisos[pos].permisos.includes('newletters:list')
    ) {
      state.sourcePermisos[pos].all.checkboxConfigurationVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('config_general:edit')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:edit')
      && state.sourcePermisos[pos].permisos.includes('user_register:edit')
      && state.sourcePermisos[pos].permisos.includes('role:edit')
      && state.sourcePermisos[pos].permisos.includes('user:edit')
      && state.sourcePermisos[pos].permisos.includes('users_son:edit')
      && state.sourcePermisos[pos].permisos.includes('agente:edit')
      && state.sourcePermisos[pos].permisos.includes('newletters:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxConfigurationEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('config_general:create')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:create')
      && state.sourcePermisos[pos].permisos.includes('user_register:create')
      && state.sourcePermisos[pos].permisos.includes('role:create')
      && state.sourcePermisos[pos].permisos.includes('user:create')
      && state.sourcePermisos[pos].permisos.includes('users_son:create')
      && state.sourcePermisos[pos].permisos.includes('agente:create')
      && state.sourcePermisos[pos].permisos.includes('newletters:create')
    ) {
      state.sourcePermisos[pos].all.checkboxConfigurationCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('config_general:destroy')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:destroy')
      && state.sourcePermisos[pos].permisos.includes('user_register:destroy')
      && state.sourcePermisos[pos].permisos.includes('role:destroy')
      && state.sourcePermisos[pos].permisos.includes('user:destroy')
      && state.sourcePermisos[pos].permisos.includes('users_son:destroy')
      && state.sourcePermisos[pos].permisos.includes('agente:destroy')
      && state.sourcePermisos[pos].permisos.includes('newletters:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxConfigurationDelete = true
    }

    // APIs
    if (
      state.sourcePermisos[pos].permisos.includes('api_keys:list')
      && state.sourcePermisos[pos].permisos.includes('api_tti:list')
      && state.sourcePermisos[pos].permisos.includes('api_amadeus:list')
      && state.sourcePermisos[pos].permisos.includes('api_redsys:list')
      && state.sourcePermisos[pos].permisos.includes('api_sibs:list')
      && state.sourcePermisos[pos].permisos.includes('api_authorize_net:list')
      && state.sourcePermisos[pos].permisos.includes('api_paypal:list')
      && state.sourcePermisos[pos].permisos.includes('currencyapi:list')
      && state.sourcePermisos[pos].permisos.includes('api_hotetec:list')
      && state.sourcePermisos[pos].permisos.includes('api_dingus:list')
      && state.sourcePermisos[pos].permisos.includes('api_solways:list')
      && state.sourcePermisos[pos].permisos.includes('api_juniper:list')
      && state.sourcePermisos[pos].permisos.includes('api_copa_connect:list')
    ) {
      state.sourcePermisos[pos].all.checkboxAPIsVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('api_keys:edit')
      && state.sourcePermisos[pos].permisos.includes('api_tti:edit')
      && state.sourcePermisos[pos].permisos.includes('api_amadeus:edit')
      && state.sourcePermisos[pos].permisos.includes('api_redsys:edit')
      && state.sourcePermisos[pos].permisos.includes('api_sibs:edit')
      && state.sourcePermisos[pos].permisos.includes('api_authorize_net:edit')
      && state.sourcePermisos[pos].permisos.includes('api_paypal:edit')
      && state.sourcePermisos[pos].permisos.includes('currencyapi:edit')
      && state.sourcePermisos[pos].permisos.includes('api_hotetec:edit')
      && state.sourcePermisos[pos].permisos.includes('api_dingus:edit')
      && state.sourcePermisos[pos].permisos.includes('api_solways:edit')
      && state.sourcePermisos[pos].permisos.includes('api_juniper:edit')
      && state.sourcePermisos[pos].permisos.includes('api_copa_connect:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxAPIsEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('api_keys:create')
      && state.sourcePermisos[pos].permisos.includes('api_tti:create')
      && state.sourcePermisos[pos].permisos.includes('api_amadeus:create')
      && state.sourcePermisos[pos].permisos.includes('api_redsys:create')
      && state.sourcePermisos[pos].permisos.includes('api_sibs:create')
      && state.sourcePermisos[pos].permisos.includes('api_authorize_net:create')
      && state.sourcePermisos[pos].permisos.includes('api_paypal:create')
      && state.sourcePermisos[pos].permisos.includes('currencyapi:create')
      && state.sourcePermisos[pos].permisos.includes('api_hotetec:create')
      && state.sourcePermisos[pos].permisos.includes('api_dingus:create')
      && state.sourcePermisos[pos].permisos.includes('api_solways:create')
      && state.sourcePermisos[pos].permisos.includes('api_juniper:create')
      && state.sourcePermisos[pos].permisos.includes('api_copa_connect:create')
    ) {
      state.sourcePermisos[pos].all.checkboxAPIsCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('api_keys:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_tti:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_amadeus:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_redsys:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_sibs:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_authorize_net:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_paypal:destroy')
      && state.sourcePermisos[pos].permisos.includes('currencyapi:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_hotetec:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_dingus:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_solways:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_juniper:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_copa_connect:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxAPIsDelete = true
    }

    // NOMENCLADORES VUELOS
    if (
      state.sourcePermisos[pos].permisos.includes('nom_aerolines:list')
      && state.sourcePermisos[pos].permisos.includes('airplane:list')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:list')
      && state.sourcePermisos[pos].permisos.includes('airport:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_politica_tarifaria_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_fligths:list')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_fligths:list')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_aerolines:edit')
      && state.sourcePermisos[pos].permisos.includes('airplane:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('airport:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_politica_tarifaria_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_fligths:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_fligths:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_aerolines:create')
      && state.sourcePermisos[pos].permisos.includes('airplane:create')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:create')
      && state.sourcePermisos[pos].permisos.includes('airport:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_politica_tarifaria_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_fligths:create')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_fligths:create')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_aerolines:destroy')
      && state.sourcePermisos[pos].permisos.includes('airplane:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('airport:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_politica_tarifaria_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_fligths:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_fligths:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightDelete = true
    }

    // NOMENCLADORES AUTOS
    if (
      state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_x_km_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_cars:list')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_x_km_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_cars:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_x_km_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_cars:create')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_x_km_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_cars:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsDelete = true
    }

    // NOMENCLADORES HOTELES
    if (
      state.sourcePermisos[pos].permisos.includes('nom_tipo_destino_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_operador_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_asociada_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_categorias_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_intereses_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_servicios_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_habitacion_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_regimen_alimenticio:list')
      && state.sourcePermisos[pos].permisos.includes('nom_edad_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_reducciones_contrate_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_tarifarios_hotels:list')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresHotelsVer = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_tipo_destino_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_operador_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_asociada_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_categorias_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_intereses_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_servicios_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_habitacion_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_regimen_alimenticio:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_edad_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_reducciones_contrate_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_tarifarios_hotels:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresHotelsEdit = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_tipo_destino_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_operador_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_asociada_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_categorias_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_intereses_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_servicios_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_habitacion_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_regimen_alimenticio:create')
      && state.sourcePermisos[pos].permisos.includes('nom_edad_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_reducciones_contrate_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_tarifarios_hotels:create')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresHotelsCreate = true
    }
    if (
      state.sourcePermisos[pos].permisos.includes('nom_tipo_destino_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_operador_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_asociada_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_categorias_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_intereses_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_servicios_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_habitacion_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_regimen_alimenticio:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_edad_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tag_imagen_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_suplemento_contrate_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_reducciones_contrate_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_conditions_generals_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_tarifarios_hotels:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresHotelsDelete = true
    }

    // NOMENCLADORES ALL
    if (
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightVer
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsVer
      && state.sourcePermisos[pos].all.checkboxNomencladoresHotelsVer
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:list')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_conditions:list')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresVer = true
    }
    if (
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightEdit
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsEdit
      && state.sourcePermisos[pos].all.checkboxNomencladoresHotelsdit
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_conditions:edit')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresEdit = true
    }
    if (
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightCreate
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsCreate
      && state.sourcePermisos[pos].all.checkboxNomencladoresHotelsCreate
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:create')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_conditions:create')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCreate = true
    }
    if (
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightDelete
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsDelete
      && state.sourcePermisos[pos].all.checkboxNomencladoresHotelsDelete
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_conditions:destroy')
    ) {
      state.sourcePermisos[pos].all.checkboxNomencladoresDelete = true
    }
  },

  // USERS
  setSearchUsers: (state, items) => {
    state.searchUsers = items
  },
  setUserUpdate: (state, item) => {
    state.userUpdate = item
  },
  setUserUpdateAvatar: (state, item) => {
    state.userUpdateAvatar = item
  },
  setUserOnlyShow: (state, status) => {
    state.userOnlyShow = status
  },
  setUserItemsPerPage: (state, cant) => {
    state.userItemsPerPage = cant
  },
  setTotalUsersFilters: (state, total) => {
    state.totalUsersFilters = total
  },
  setTotalUsersAll: (state, total) => {
    state.totalUsersAll = total
  },

  // HOTELS
  updateAddressHotel: (state, addressHotel) => {
    state.addressHotel.country = addressHotel.country
    state.addressHotel.state = addressHotel.state
    state.addressHotel.city = addressHotel.city
    if (addressHotel.direccion) {
      state.addressHotel.direccion = addressHotel.direccion
    }
  },
  resetAddressHotel: state => {
    state.addressHotel = {
      country: null,
      state: null,
      city: null,
      direccion: null,
    }
  },
  adicionarContactHotel: state => {
    const { contactsHotel } = state
    contactsHotel.push({
      id: uuidv4(),
      name: '',
      rol: '',
      emails: [{ email: null }],
      phones: [{ phone: null, exts: [{ ext: null }] }],
    })
  },
  deleteContactHotel: (state, pos) => {
    const { contactsHotel } = state

    contactsHotel.splice(pos, 1)
  },
  updateContactHotel: (state, contacts) => {
    state.contactsHotel = contacts
  },
  adicionarEmailContactHotel: (state, pos) => {
    const { contactsHotel } = state
    contactsHotel[pos].emails.push({ email: null })
  },
  deleteEmailContactHotel: (state, { pos, index }) => {
    const { contactsHotel } = state

    contactsHotel[pos].emails.splice(index, 1)
  },
  adicionarTelefonoContactHotel: (state, pos) => {
    const { contactsHotel } = state
    contactsHotel[pos].phones.push({ phone: null, exts: [{ ext: null }] })
  },
  deleteTelefonoContactHotel: (state, { pos, index }) => {
    const { contactsHotel } = state

    contactsHotel[pos].phones.splice(index, 1)
  },
  adicionarExtTelefonoContactHotel: (state, { pos, posTelefono }) => {
    const { contactsHotel } = state
    contactsHotel[pos].phones[posTelefono].exts.push({ ext: null })
  },
  deleteExtTelefonoContactHotel: (state, { pos, posTelefono, index }) => {
    const { contactsHotel } = state

    contactsHotel[pos].phones[posTelefono].exts.splice(index, 1)
  },

  adicionarUrlVideo: state => {
    const { videos_url } = state
    videos_url.push({
      id: uuidv4(),
      url: 'https://',
    })
  },
  deleteUrlVideo: (state, pos) => {
    const { videos_url } = state

    videos_url.splice(pos, 1)
  },
  updateUrlVideo: (state, items) => {
    state.videos_url = items
  },

  // OFICINA RENTA
  updateOficinaRenta: (state, oficinaRenta) => {
    state.oficinaRenta.country = oficinaRenta.country
    state.oficinaRenta.country_code = oficinaRenta.country_code
    state.oficinaRenta.state = oficinaRenta.state
    state.oficinaRenta.city = oficinaRenta.city
  },
  editOficinaRenta: (state, oficce) => {
    state.oficinaRenta = oficce
  },

  // AIRPORT
  updateAirport: (state, aiport) => {
    state.airport.country = aiport.country
    state.airport.state = aiport.state
    state.airport.city = aiport.city
  },
  editAirport: (state, aiport) => {
    state.airport = aiport
  },
  setOnlyShow: (state, status) => {
    state.onlyShow = status
  },
  resetAirport: state => {
    state.airport = {
      iata: null,
      name: null,
      country: null,
      state: null,
      city: null,
      direccion: null,
    }
  },
  resetOficinaRenta: state => {
    state.oficinaRenta = {
      name: null,
      ptos_recogida: null,
      country: null,
      state: null,
      city: null,
      direccion: null,
    }
  },
  resetGeoTag: state => {
    state.geotag = {
      longitud: null,
      latitud: null,
    }
  },

  // COTIZADOR FLIGHTS
  addDestinoCotizadorFligth: state => {
    state.destinosCotizadorFlight.push({
      origen: null,
      destino: null,
      from: null,
      to: null,
    })
  },
  resetCotizadorFligth: state => {
    state.cotizadorFlight = {
      cant_adults: 1,
      cant_ninnos: 0,
      cant_infant: 0,
      aerolinea: 0,
      aerolinea_name: 'Todas',
      onlyIda: false,
      destinosMultiples: false,
    }
  },
  deleteDestinoCotizadorFligth: (state, pos) => {
    const { destinosCotizadorFlight } = state

    destinosCotizadorFlight.splice(pos, 1)
  },
  resetDestinoCotizadorFligth: state => {
    const { destinosCotizadorFlight } = state

    destinosCotizadorFlight.splice(1, destinosCotizadorFlight.length)
  },
  emptyDestinoCotizadorFligth: state => {
    state.destinosCotizadorFlight = [
      {
        origen: null,
        destino: null,
        from: null,
        to: null,
      },
    ]
  },
  incrementAdultosCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_adults++
  },
  decrementAdultosCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_adults--
  },
  incrementChildCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_ninnos++
  },
  decrementChildCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_ninnos--
  },
  incrementInfantCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_infant++
  },
  decrementInfantCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_infant--
  },

  // RESULT
  setSearchCotizadorIdaFlight: (state, value) => {
    state.searchCotizadorIdaFlight = value
  },
  setSearchCotizadorFlights: (state, value) => {
    state.searchCotizadorFlights = value
  },
  setResultsCotizadorIdaFligth: (state, items) => {
    state.resultCotizadorIdaFlight = items
  },
  setAerolineCotizadorIdaFligth: (state, items) => {
    state.aerolineCotizadorIdaFlight = items
  },
  setIndexTarifaEconCotizadorIdaFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorIdaFlight[indAero].indexTarifa = index
  },
  setIndTarifaEconCotizadorIdaFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorIdaFlight[indAero].indTarifa = index
  },
  setAerolineCotizadorRegresoFligth: (state, items) => {
    state.aerolineCotizadorRegresoFlight = items
  },
  setIndexTarifaIdaEconCotizadorIdaBackFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorRegresoFlight[indAero].indexTarifaIda = index
  },
  setIndTarifaIdaEconCotizadorIdaBackFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorRegresoFlight[indAero].indTarifaIda = index
  },
  setIndexTarifaBackEconCotizadorIdaBackFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorRegresoFlight[indAero].indexTarifaRegreso = index
  },
  setIndTarifaBackEconCotizadorIdaBackFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorRegresoFlight[indAero].indTarifaRegreso = index
  },
  setAerolineCotizadorDestinosFligth: (state, items) => {
    state.aerolineCotizadorDestinosFlight = items
  },
  setIndexTarifaEconCotizadorIdaDestinosFligth: (state, { indAero, indDestino, index }) => {
    state.aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].indexTarifa = index
  },
  setIndTarifaEconCotizadorIdaDestinosFligth: (state, { indAero, indDestino, index }) => {
    state.aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].indTarifa = index
  },

  setSearchingFlightCotizador: (state, valor) => {
    state.searchingFlightCotizador = valor
  },
  setPageSearchingFlightCotizador: (state, valor) => {
    state.pageSearchingFlightCotizador = valor
  },

  setCotizadorFlight: (state, item) => {
    state.cotizadorFlight = item
  },

  // SELECT
  setCotizadorFlightSelect: (state, item) => {
    state.cotizadorFlightSelect = item
  },
  setCotizadorPaxFlightSelect: (state, items) => {
    state.cotizadorPaxFlightSelect = items
  },
  setCotizadorFlightSelectMarkups: (state, item) => {
    state.cotizadorFlightSelectMarkups = item
  },
  setContactEmergencyAllCotizadorFlight: (state, { name, phone, email }) => {
    state.cotizadorPaxFlightSelect.forEach(element => {
      // eslint-disable-next-line no-param-reassign
      element.contact_emergency_name = name
      // eslint-disable-next-line no-param-reassign
      element.contact_emergency_phone = phone
      // eslint-disable-next-line no-param-reassign
      element.contact_emergency_email = email
    })
  },
  setCotizadorFlightSelectPrice: (state, { price_total, with_markup, with_descuento }) => {
    state.cotizadorFlightSelect.item_ida.tarifas_contrate.forEach(tarifa => {
      tarifa.price_total = price_total
      tarifa.with_markup = with_markup
      tarifa.with_descuento = with_descuento
    })
  },
  setCotizadorFlightSelectIdaBackPrice: (state, { with_markup, with_descuento }) => {
    state.cotizadorFlightSelect.item_ida.tarifas_contrate.forEach(tarifa => {
      tarifa.price_total = tarifa.price_inicial + with_markup - with_descuento
      tarifa.with_markup = with_markup
      tarifa.with_descuento = with_descuento
    })
    state.cotizadorFlightSelect.item_regreso.tarifas_contrate.forEach(tarifa => {
      tarifa.price_total = tarifa.price_inicial + with_markup - with_descuento
      tarifa.with_markup = with_markup
      tarifa.with_descuento = with_descuento
    })
  },
  setCotizadorFlightSelectDestinosPrice: (state, { with_markup, with_descuento }) => {
    state.cotizadorFlightSelect.destinos.forEach(destino => {
      destino.flights[destino.indexTarifa].tarifas_contrate.forEach(tarifa => {
        tarifa.price_total = tarifa.price_inicial + with_markup - with_descuento
        tarifa.with_markup = with_markup
        tarifa.with_descuento = with_descuento
      })
    })
  },

  // FAVORITES
  insertCotizadorFlightFavorite: (state, item) => {
    state.cotizadorFlightFavorite.push(item)
  },
  deleteCotizadorFlightFavorite: (state, item) => {
    state.cotizadorFlightFavorite.splice(state.cotizadorFlightFavorite.indexOf(item), 1)
  },
  clearCotizadorFlightFavorite: state => {
    state.cotizadorFlightFavorite = []
  },
  insertIdFlightFavorite: (state, id) => {
    state.idFlightFavorite.push(id)
  },
  deleteIdFlightFavorite: (state, id) => {
    state.idFlightFavorite.splice(state.idFlightFavorite.indexOf(id), 1)
  },
  clearIdFlightFavorite: state => {
    state.idFlightFavorite = []
  },

  // SCHEDULE
  setScheduleResult: (state, items) => {
    state.scheduleResult = items
  },
  setSchedulePageResult: (state, page) => {
    state.pageResult = page
  },
  setScheduleItemsPerPageResult: (state, cant) => {
    state.itemsPerPageResult = cant
  },

  // FILTROS COTIZADOR FLIGHT
  setFiltrosCotizadorFlight: (state, item) => {
    if (item.aerolinea !== undefined && item.aerolinea !== null) {
      state.filtrosCotizadorFlight.aerolinea = item.aerolinea
    }
    if (item.escala !== undefined && item.escala !== null) {
      state.filtrosCotizadorFlight.escala = item.escala
    }
    if (item.duracionEscala !== undefined && item.duracionEscala !== null) {
      state.filtrosCotizadorFlight.duracionEscala = item.duracionEscala
    }
    if (item.duracionViaje !== undefined && item.duracionViaje !== null) {
      state.filtrosCotizadorFlight.duracionViaje = item.duracionViaje
    }
    if (item.frecuencias !== undefined && item.frecuencias !== null) {
      state.filtrosCotizadorFlight.frecuencias = item.frecuencias
    }
    if (item.airport !== undefined && item.airport !== null) {
      state.filtrosCotizadorFlight.airport = item.airport
    }
    if (item.clase !== undefined && item.clase !== null) {
      state.filtrosCotizadorFlight.clase = item.clase
    }
    if (item.equipaje !== undefined && item.equipaje !== null) {
      state.filtrosCotizadorFlight.equipaje = item.equipaje
    }
    if (item.byMejorOpcion !== undefined && item.byMejorOpcion !== null) {
      state.filtrosCotizadorFlight.byMejorOpcion = item.byMejorOpcion
    }
    if (item.byMasEconomico !== undefined && item.byMasEconomico !== null) {
      state.filtrosCotizadorFlight.byMasEconomico = item.byMasEconomico
    }
    if (item.byMasRapido !== undefined && item.byMasRapido !== null) {
      state.filtrosCotizadorFlight.byMasRapido = item.byMasRapido
    }
  },

  // ESCALAS
  adicionarEscala: state => {
    state.escalas.push({
      city: '',
      id: 0,
      from: null,
      to: null,
      duration_h: 0,
      duration_m: 0,
    })
  },
  updateEscala: (state, escalas) => {
    state.escalas = escalas
  },
  deleteEscala: (state, escala) => {
    state.loadEscalas = true
    const { escalas } = state

    state.escalas = escalas.filter(geeks => geeks.city.id !== escala.city.id)
    setTimeout(() => {
      state.loadEscalas = false
    }, 100)
  },

  // FRECUENCIAS
  initFrecuencia: state => {
    state.frecuencias.push({
      frecuencia: [],
      fromValidez: null,
      toValidez: null,
      origen: {
        destino: null,
        salida: null,
        terminal: null,
        puerta: null,
        duration: null,
        durationM: null,
      },
      destino: {
        destino: null,
        llegada: null,
        terminal: null,
        puerta: null,
        days: null,
        duration: null,
        durationM: null,
      },
      days: 0,
      duration: '',
      durationM: 0,
      escalas: [],
      tiene_escalas: false,
    })
  },
  adicionarFrecuencia: state => {
    if (state.frecuencias.length > 0) {
      const frec = state.frecuencias[0]
      const origen = {
        destino: frec.origen.destino,
        salida: null,
        terminal: null,
        puerta: null,
        duration: null,
        durationM: null,
      }
      const destino = {
        destino: frec.destino.destino,
        llegada: null,
        terminal: null,
        puerta: null,
        days: null,
        duration: null,
        durationM: null,
      }
      const escalas = []
      frec.escalas.forEach(element => {
        escalas.push({
          city: element.city,
          llegada: null,
          llegadaDay: 0,
          llegadaTerminal: null,
          llegadaDuration: null,
          salida: null,
          salidaDay: 0,
          salidaTerminal: null,
          salidaDuration: null,
        })
      })
      state.frecuencias.push({
        frecuencia: [],
        fromValidez: frec.fromValidez,
        toValidez: frec.toValidez,
        origen,
        destino,
        days: '',
        duration: '',
        durationM: 0,
        escalas,
        tiene_escalas: frec.tiene_escalas,
      })
    } else {
      state.frecuencias.push({
        frecuencia: [],
        fromValidez: '',
        toValidez: '',
        origen: {
          destino: null,
          salida: null,
          terminal: null,
          puerta: null,
          duration: null,
          durationM: null,
        },
        destino: {
          destino: null,
          llegada: null,
          terminal: null,
          puerta: null,
          days: null,
          duration: null,
          durationM: null,
        },
        days: 0,
        duration: '',
        escalas: [],
        tiene_escalas: false,
      })
    }
  },
  addEscalaFrecuencia: (state, pos) => {
    const { frecuencias } = state
    frecuencias[pos].escalas.push({
      city: null,
      llegada: null,
      llegadaDay: null,
      llegadaTerminal: null,
      llegadaDuration: 0,
      llegadaDurationM: 0,
      salida: null,
      salidaDay: null,
      salidaTerminal: null,
      salidaDuration: 0,
      salidaDurationM: 0,
    })
  },
  deleteEscalaFrecuencia: (state, { pos, posFrecuencia }) => {
    const { frecuencias } = state
    frecuencias[posFrecuencia].escalas.splice(pos, 1)
  },
  deleteAllEscalaFrecuencia: (state, pos) => {
    const { frecuencias } = state
    frecuencias[pos].escalas = []
  },
  calculateLlegadaEscala: (state, pos) => {
    const { frecuencias } = state
    let beginningTime
    let idOrigen

    // POR SI NO TIENE ESCALAS
    if (frecuencias[pos].escalas.length === 0) {
      beginningTime = moment(frecuencias[pos].origen.salida, 'HH:mm')
      idOrigen = frecuencias[pos].origen.destino.id
    } else {
      beginningTime = moment(frecuencias[pos].escalas[frecuencias[pos].escalas.length - 1].salida, 'HH:mm')
      idOrigen = frecuencias[pos].escalas[frecuencias[pos].escalas.length - 1].city.id
    }

    const endTime = moment(frecuencias[pos].destino.llegada, 'HH:mm')
    const horaOrigen = beginningTime.format('HH:mm')
    const horaDestino = endTime.format('HH:mm')

    if (frecuencias[pos].destino.llegada) {
      /* if (beginningTime.isBefore(endTime)) {
        frecuencias[pos].destino.days = 0
      } else {
        frecuencias[pos].destino.days = 1
      } */

      const idDestino = frecuencias[pos].destino.destino.id

      const json = {
        idOrigen,
        horaOrigen,
        idDestino,
        horaDestino,
      }
      axios
        .post(`${process.env.VUE_APP_API_URL}calculate/flights-utc`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // console.log(response.data)

          frecuencias[pos].destino.duration = response.data.duration
          frecuencias[pos].destino.durationM = response.data.durationM
          frecuencias[pos].destino.days = response.data.daysLlegada
        })
        .finally(() => {
          let days = 0
          let minutos = 0
          frecuencias[pos].escalas.forEach(element => {
            days = days + element.llegadaDay + element.salidaDay
            minutos = minutos + element.llegadaDurationM + element.salidaDurationM
          })
          frecuencias[pos].days = days + frecuencias[pos].destino.days
          const resulMin = minutos + frecuencias[pos].destino.durationM
          let textHours

          let hours = Math.floor(resulMin / 60)
          const mins = resulMin % 60
          if (hours !== 0 && mins !== 0) {
            if (mins >= 59) {
              hours += 1
              textHours = `${hours}:00`
            } else {
              textHours = `${hours}:${mins.toFixed(0)}`
            }
          } else if (hours === 0 && mins !== 0) {
            textHours = `00:${mins.toFixed(0)}`
          } else if (hours !== 0 && mins === 0) {
            textHours = `${hours}:00`
          }
          // eslint-disable-next-line prefer-destructuring
          frecuencias[pos].duration = textHours
          frecuencias[pos].durationM = resulMin
        })
    }
  },
  changeDaysCalculate: (state, pos) => {
    const { frecuencias } = state
    let days = 0

    frecuencias[pos].escalas.forEach(element => {
      days = days + parseInt(element.llegadaDay, 10) + parseInt(element.salidaDay, 10)
    })
    frecuencias[pos].days = days + parseInt(frecuencias[pos].destino.days, 10)
  },
  updateFrecuencia: (state, frecuencias) => {
    state.frecuencias = frecuencias
  },
  cloneFrecuencia: (state, frecuencia) => {
    const { frecuencias } = state
    frecuencias.push(frecuencia)
  },
  deleteFrecuencia: (state, pos) => {
    // state.loadFrecuencia = true
    const { frecuencias } = state

    frecuencias.splice(pos, 1)

    /* setTimeout(() => {
      state.loadFrecuencia = false
    }, 100) */
  },

  // CLASES DE VUELOS
  initClaseVuelo: state => {
    const { clasesVuelos } = state

    clasesVuelos.push({
      class_id: 0,
      tarifa_id: 0,
      cupo: 0,
      price_adult: 0,
      price_child: 0,
      price_infant: 0,

      equipaje_personal: {
        dimensiones: 0,
        peso: 0,
        precio: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },

      equipaje_cabina: {
        dimensiones: 0,
        peso: 0,
        precio: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },

      equipaje_bodega: {
        dimensiones: 0,
        lineal_dimensiones: 0,
        peso: 0,
        um_peso: '',
        number_peso: 0,
        maletas: [{ price: 0, incluida: false, permitida: false }],
        max_exceso_equipaje: 0,
        exceso_equipaje: 0,
        tasa_despacho: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },
    })
    state.clasesVuelos = clasesVuelos
  },
  adicionarClaseVuelo: state => {
    const { clasesVuelos } = state

    clasesVuelos.push({
      class_id: 0,
      tarifa_id: 0,
      cupo: 0,
      price_adult: 0,
      price_child: 0,
      price_infant: 0,

      equipaje_personal: {
        dimensiones: 0,
        peso: 0,
        precio: 0,
        incluido: false,
        permitido: false,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },

      equipaje_cabina: {
        dimensiones: 0,
        peso: 0,
        precio: 0,
        incluido: false,
        permitido: false,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },

      equipaje_bodega: {
        dimensiones: 0,
        um_dimension: '',
        lineal_dimensiones: 0,
        peso: 0,
        um_peso: '',
        number_peso: 0,
        maletas: [{ price: 0, incluida: false, permitida: false }],
        max_exceso_equipaje: 0,
        exceso_equipaje: [],
        sobre_peso: false,
        max_exceso_dimension: 0,
        exceso_dimension: [],
        sobre_dimension: false,

        // exceso_equipaje: [{ peso_min: 0, peso_max: 0, price: 0 }],
        tasa_despacho: 0,
        incluido: false,
        permitido: false,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },
    })
    state.clasesVuelos = clasesVuelos
  },
  adicionarMaletaCabina: state => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.maletas.push({ price: 0, incluida: false, permitida: false })
    })
  },
  deleteMaletaBodegaVuelo: (state, pos) => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.maletas.splice(pos, 1)
    })
  },
  adicionarExcesoEquipajeBodega: state => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.exceso_equipaje.push({ peso_min: 0, peso_max: 0, price: 0 })
    })
  },
  deleteExcesoEquipajeBodega: (state, pos) => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.exceso_equipaje.splice(pos, 1)
    })
  },
  adicionarDimensionEquipajeBodega: state => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.exceso_dimension.push({ dimension_min: 0, dimension_max: 0, price: 0 })
    })
  },
  deleteDimensionEquipajeBodega: (state, pos) => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.exceso_dimension.splice(pos, 1)
    })
  },
  adicionarPrecioAdicionalVuelo: (state, clase) => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      if (element.class_id === clase.class_id) {
        element.precio_adicional.froms.push({
          from: 0,
          to: 0,
          price: 0,
          aplica_adult: false,
          aplica_child: false,
          aplica_inf: false,
        })
      }
    })
  },
  eliminarPrecioAdicionalVuelo: (state, { clase, from }) => {
    const { clasesVuelos } = state

    const classEdit = clasesVuelos.filter(geeks => geeks.class_id === clase.class_id)

    if (classEdit.length > 0) {
      const { froms } = classEdit[0].precio_adicional

      const fromsR = froms.filter(geeks => geeks.from !== from.from)
      // eslint-disable-next-line no-param-reassign
      clase.precio_adicional.froms = fromsR
    }
  },

  updateClaseVuelo: (state, clases) => {
    state.clasesVuelos = clases
  },
  deleteClaseVuelo: (state, pos) => {
    state.loadClasesVuelos = true
    const { clasesVuelos } = state

    clasesVuelos.splice(pos, 1)

    // state.clasesVuelos = clasesVuelos.filter(geeks => geeks.class_id !== clase.class_id)
    setTimeout(() => {
      state.loadClasesVuelos = false
    }, 100)
  },

  // Temporadas Markus
  adicionarTemporadaFlightMarkus: state => {
    state.temporadasFlightMarkups.push({
      fromValidez: '',
      toValidez: '',
    })
  },
  updateTemporadaFlightMarkus: (state, temporadasFlightMarkups) => {
    state.temporadasFlightMarkups = temporadasFlightMarkups
  },
  initMarkupsFlight: (state, typesAfiliados) => {
    state.markupsFlightMarkups.push({
      id_tipo_afiliado: 0,
      name_tipo_afiliado: 'Ventas Directas',
      valorAdult: '',
      pcAdult: '',
      valorChild: '',
      pcChild: '',
      valorInf: '',
      pcInf: '',
    })

    typesAfiliados.forEach(element => {
      state.markupsFlightMarkups.push({
        id_tipo_afiliado: element.id,
        name_tipo_afiliado: element.name,
        valorAdult: '',
        pcAdult: '',
        valorChild: '',
        pcChild: '',
        valorInf: '',
        pcInf: '',
      })
    })
  },
  updateMarkusFlight: (state, markupsFlightMarkups) => {
    state.markupsFlightMarkups = markupsFlightMarkups
  },
  deleteTemporadaFlightMarkus: (state, pos) => {
    const { temporadasFlightMarkups } = state

    temporadasFlightMarkups.splice(pos, 1)
  },

  // CARS
  updateGeoTag: (state, geotag) => {
    state.geotag = geotag
  },
  setPaisCode: (state, { name, countrySlug, code }) => {
    const { paiseCode } = state
    paiseCode.name = name
    paiseCode.countrySlug = countrySlug
    paiseCode.code = code
  },
  adicionarTelefono: state => {
    const { telefonos } = state
    if (telefonos.length > 0) {
      telefonos.push({ code: '', number: null, ext: null })
    } else {
      telefonos.push({ code: state.telefonos[0].code, number: null, ext: null })
    }
  },
  deleteTelefono: (state, pos) => {
    const { telefonos } = state

    telefonos.splice(pos, 1)
  },
  updateTelefonos: (state, telefonos) => {
    state.telefonos = telefonos
  },
  adicionarHorario: state => {
    state.horarios.push({
      modelSemana: [0, 1, 2, 3, 4, 5, 6],
      is_time_completo: true,
      horarios: [],
    })
  },
  deleteHorario: (state, pos) => {
    const { horarios } = state

    horarios.splice(pos, 1)
  },
  adicionarHorarioComponent: (state, { posOficce, posH }) => {
    state.oficceRent[posOficce].horarios[posH].horarios.push({ from: '', to: '' })
  },
  deleteHorarioComponent: (state, { posOficce, posH, pos }) => {
    state.oficceRent[posOficce].horarios[posH].horarios.splice(pos, 1)
  },
  updateHorarios: (state, horarios) => {
    state.horarios = horarios
  },
  setTimeCompletoOficinal: (state, { posOficce, pos, isTimeCompleto }) => {
    state.oficceRent[posOficce].horarios[pos].is_time_completo = isTimeCompleto
    if (!isTimeCompleto) {
      state.oficceRent[posOficce].horarios[pos].horarios = [{ from: '', to: '' }]
    } else {
      state.oficceRent[posOficce].horarios[pos].horarios = []
    }
  },
  addHorarioOficceRent: (state, posOficce) => {
    state.oficceRent[posOficce].horarios.push({
      modelSemana: [0, 1, 2, 3, 4, 5, 6],
      is_time_completo: true,
      horarios: [],
    })
  },
  deleteHorarioOficceRent: (state, { pos, posOficce }) => {
    state.oficceRent[posOficce].horarios.splice(pos, 1)
  },
  adicionarOficceRent: (state, { oficina_id, telefonos, horarios }) => {
    state.oficceRent.push({
      oficina_id,
      telefonos,
      horarios,
    })
  },
  updateOficceRent: (state, items) => {
    state.oficceRent = items
  },
  deleteOficceRent: (state, pos) => {
    const { oficceRent } = state

    oficceRent.splice(pos, 1)
  },
  addTelefonoOficceRent: (state, pos) => {
    if (state.oficceRent[pos].telefonos.length === 0) {
      state.oficceRent[pos].telefonos.push({
        code: '',
        number: null,
        ext: null,
      })
    } else {
      state.oficceRent[pos].telefonos.push({
        code: state.oficceRent[pos].telefonos[0].code,
        number: null,
        ext: null,
      })
    }
  },
  deleteTelefonoOficceRent: (state, { pos, posOficce }) => {
    const { oficceRent } = state

    oficceRent[posOficce].telefonos.splice(pos, 1)
  },
  adicionarImgCars: (state, { galery, resource }) => {
    galery.forEach(element => {
      state.galeria.push({
        tag: null,
        principal: false,
        resource,
        image: element,
      })
    })
  },
  deleteImgCars: (state, pos) => {
    const { galeria } = state

    galeria.splice(pos, 1)
  },
  adicionarImgHotels: (state, { galery, resource }) => {
    galery.forEach(element => {
      state.galeriaHotels.push({
        tag: null,
        principal: false,
        resource,
        image: element,
        crop: false,
      })
    })
  },
  deleteImgHotels: (state, pos) => {
    const { galeriaHotels } = state

    galeriaHotels.splice(pos, 1)
  },
  updateImgCars: (state, galery) => {
    state.galeria = galery
  },
  updateImgHotels: (state, galery) => {
    state.galeriaHotels = galery
  },
  setImgLoading: (state, status) => {
    state.loadImg = status
  },
  setCurrencyCode: (state, value) => {
    state.currency_code = value
  },
  setCurrencySymbol: (state, value) => {
    state.currency_symbol = value
  },
  setLoadingImg: (state, value) => {
    state.loadingImg = value
  },

  // VIDEOS
  adicionarVideoCars: (state, { videos, resource }) => {
    videos.forEach(element => {
      state.videos.push({
        procesado: false,
        resource,
        url: element,
      })
    })
  },
  deleteVideoCars: (state, pos) => {
    const { videos } = state

    videos.splice(pos, 1)
  },
  updateVideosCars: (state, videos) => {
    state.videos = videos
  },

  // CARS CONTRATE
  addCategoryContrateCar: (state, { edad_id, edad_max_id }) => {
    const { categoryContrateCar } = state

    categoryContrateCar.push({
      category_id: null,
      edad_id,
      edad_max_id,
      cars_id: [],
    })

    this.verifyPricesTemporadasContrateCar()
  },
  deleteCategoryContrateCar: (state, pos) => {
    const { categoryContrateCar } = state

    // VALIDAR Q SE ELIMINE LOS PRECIOS PARA ESA TEMPORADA
    const { pricesTemporadasContrateCar } = state
    const newPrices = []
    pricesTemporadasContrateCar.forEach(price => {
      // eslint-disable-next-line no-empty
      if (price.category_id === categoryContrateCar[pos].category_id) {
      } else {
        newPrices.push(price)
      }
    })
    state.pricesTemporadasContrateCar = newPrices

    categoryContrateCar.splice(pos, 1)

    this.verifyPricesTemporadasContrateCar()
  },
  updateCategoryContrateCar: (state, update) => {
    state.categoryContrateCar = update
  },
  addMarcaModeloContrateCar: (state, { edad_id, edad_max_id }) => {
    const { marcasModeloContrateCar } = state

    marcasModeloContrateCar.push({
      marca_id: uuidv4(),
      edad_id,
      edad_max_id,
      cars_id: [],
    })

    this.verifyPricesTemporadasContrateCar()
  },
  deleteMarcaModeloContrateCar: (state, pos) => {
    const { marcasModeloContrateCar } = state

    // VALIDAR Q SE ELIMINE LOS PRECIOS PARA ESA TEMPORADA
    const { pricesTemporadasContrateCar } = state
    const newPrices = []
    pricesTemporadasContrateCar.forEach(price => {
      // eslint-disable-next-line no-empty
      if (price.marca_id === marcasModeloContrateCar[pos].marca_id) {
      } else {
        newPrices.push(price)
      }
    })
    state.pricesTemporadasContrateCar = newPrices

    marcasModeloContrateCar.splice(pos, 1)

    this.verifyPricesTemporadasContrateCar()
  },
  updateMarcaModeloContrateCar: (state, update) => {
    state.marcasModeloContrateCar = update
  },
  addTemporadaContrateCar: state => {
    const { temporadasContrateCar } = state

    temporadasContrateCar.push({
      temporada_id: uuidv4(),
      name: `T ${temporadasContrateCar.length + 1}`,
      dates: [{ from: null, to: null }],
      tarifas_id: [],
      release: 3,
    })
  },
  deleteTemporadaContrateCar: (state, pos) => {
    const { temporadasContrateCar } = state

    // VALIDAR Q SE ELIMINE LOS PRECIOS PARA ESA TEMPORADA
    const { pricesTemporadasContrateCar } = state
    const newPrices = []
    pricesTemporadasContrateCar.forEach(price => {
      // eslint-disable-next-line no-empty
      if (price.temporada_id === temporadasContrateCar[pos].temporada_id) {
      } else {
        newPrices.push(price)
      }
    })
    state.pricesTemporadasContrateCar = newPrices

    temporadasContrateCar.splice(pos, 1)

    this.verifyPricesTemporadasContrateCar()
  },
  updateTemporadaContrateCar: (state, update) => {
    state.temporadasContrateCar = update
  },
  addDateTemporadaContrateCar: (state, posSeason) => {
    state.temporadasContrateCar[posSeason].dates.push({ from: null, to: null })
  },
  deleteDateTemporadaContrateCar: (state, { posSeason, posDate }) => {
    state.temporadasContrateCar[posSeason].dates.splice(posDate, 1)
  },
  addSuplementoContrateCar: (
    state,
    {
      from,
      to,
      suplemento_id,
      price,
      obligatorio,
      tarifas_id,
      tarifas_retorno,
      tarifas_combustible,
      seguros_edad_conductor,
    },
  ) => {
    const { suplementosContrateCar } = state

    suplementosContrateCar.push({
      suplemento_id,
      from,
      to,
      price,
      obligatorio,
      tarifas_id,
      tarifas_retorno,
      tarifas_combustible,
      seguros_edad_conductor,
    })
  },
  setSuplementoContrateCar: (state, item) => {
    state.suplementoCar = item
  },
  deleteSuplementoContrateCar: (state, pos) => {
    const { suplementosContrateCar } = state

    suplementosContrateCar.splice(pos, 1)
  },
  updateSuplementoContrateCarOne: (
    state,
    {
      pos,
      from,
      to,
      suplemento_id,
      price,
      obligatorio,
      tarifas_id,
      tarifas_retorno,
      tarifas_combustible,
      seguros_edad_conductor,
    },
  ) => {
    state.suplementosContrateCar[pos] = {
      suplemento_id,
      from,
      to,
      price,
      obligatorio,
      tarifas_id,
      tarifas_retorno,
      tarifas_combustible,
      seguros_edad_conductor,
    }
  },
  updateSuplementoContrateCar: (state, update) => {
    state.suplementosContrateCar = update
  },
  setContrateCarFreeSale: (state, update) => {
    state.contrateCarFreeSale = update
  },
  addSeguroEdadConductorSuplementoContrateCar: (
    state,
    {
      indSuplementoCar, category_id, marca_id, edad_min, edad_max,
    },
  ) => {
    state.suplementosContrateCar[indSuplementoCar].seguros_edad_conductor.push({
      category_id,
      marca_id,
      price: null,
      edad_min,
      edad_max,
    })
  },
  deleteSeguroEdadConductorSuplementoContrateCar: (state, { pos, indSuplementoCar }) => {
    const { suplementosContrateCar } = state

    suplementosContrateCar[indSuplementoCar].seguros_edad_conductor.splice(pos, 1)
  },
  addTarifaCombustibleSuplementoContrateCar: (state, {
    indSuplementoCar, category_id, marca_id, price,
  }) => {
    state.suplementosContrateCar[indSuplementoCar].tarifas_combustible.push({
      category_id,
      marca_id,
      price,
    })
  },
  deleteTarifaCombustibleSuplementoContrateCar: (state, { pos, indSuplementoCar }) => {
    const { suplementosContrateCar } = state

    suplementosContrateCar[indSuplementoCar].tarifas_combustible.splice(pos, 1)
  },
  addTarifaXKmSuplementoContrateCar: (state, { indSuplementoCar, tarifa_id, tarifa_prices }) => {
    state.suplementosContrateCar[indSuplementoCar].tarifas_retorno.push({
      tarifa_id,
      tarifa_prices,
    })
  },
  deleteTarifaXKmSuplementoContrateCar: (state, { pos, indSuplementoCar }) => {
    const { suplementosContrateCar } = state

    suplementosContrateCar[indSuplementoCar].tarifas_retorno.splice(pos, 1)
  },
  addPriceTarifaXKmSuplementoContrateCar: (state, {
    indSuplementoCar, indTarifaRetorno, tarifa_id, price,
  }) => {
    state.suplementosContrateCar[indSuplementoCar].tarifas_retorno[indTarifaRetorno].tarifa_prices.push({
      tarifa_id,
      price,
    })
  },
  deletePriceTarifaXKmSuplementoContrateCar: (state, { pos, indSuplementoCar, indTarifaRetorno }) => {
    const { suplementosContrateCar } = state

    suplementosContrateCar[indSuplementoCar].tarifas_retorno[indTarifaRetorno].tarifa_prices.splice(pos, 1)
  },

  addSuplementoContrateFlight: (state, {
    from, to, suplemento_id, price, obligatorio, online,
  }) => {
    const { suplementosContrateFlight } = state

    suplementosContrateFlight.push({
      suplemento_id,
      from,
      to,
      price,
      obligatorio,
      online,
    })
  },
  updateSuplementoContrateFlight: (state, update) => {
    state.suplementosContrateFlight = update
  },
  setSuplementoContrateFlight: (state, item) => {
    state.suplementoFlight = item
  },
  deleteSuplementoContrateFlight: (state, pos) => {
    const { suplementosContrateFlight } = state

    suplementosContrateFlight.splice(pos, 1)
  },
  updateSuplementoContrateFlightOne: (state, {
    pos, from, to, suplemento_id, price, obligatorio, online,
  }) => {
    state.suplementosContrateFlight[pos] = {
      suplemento_id,
      from,
      to,
      price,
      obligatorio,
      online,
    }
  },

  verifyPricesTemporadasContrateCar: state => {
    const { temporadasContrateCar } = state
    const { categoryContrateCar } = state
    const { pricesTemporadasContrateCar } = state
    const { marcasModeloContrateCar } = state

    if (temporadasContrateCar.length > 0) {
      temporadasContrateCar.forEach(season => {
        categoryContrateCar.forEach(category => {
          const prices = pricesTemporadasContrateCar.filter(
            e => e.temporada_id === season.temporada_id && e.category_id === category.category_id.id,
          )

          // CREAR LA TARIFA
          if (prices.length === 0) {
            const arrTarif = []
            arrTarif.push({ tarifa_id: 'seguro', price: 0 })
            arrTarif.push({ tarifa_id: 'deposito', price: 0 })
            arrTarif.push({ tarifa_id: 'day_extra', price: 0 })
            season.tarifas_id.forEach(element => {
              arrTarif.push({ tarifa_id: element, price: 0 })
            })
            pricesTemporadasContrateCar.push({
              category_id: category.category_id.id,
              marca_id: null,
              temporada_id: season.temporada_id,
              from: season.from,
              to: season.to,
              prices: arrTarif,
              cupos: [{ oficina_id: [-1], cuposDisponible: null, cuposTotal: null }],
            })
          } else {
            // BUSCAR SI LA TARIFA EXISTIA
            let find = false
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < pricesTemporadasContrateCar.length; index++) {
              const element = pricesTemporadasContrateCar[index]
              if (element.temporada_id === season.temporada_id && element.category_id === category.category_id.id) {
                // VERIFICAR SI YA EXITIA LA TARIFA
                const tarifaPrice = pricesTemporadasContrateCar[index].prices

                const arrTarif = []
                arrTarif.push({
                  tarifa_id: 'seguro',
                  price: tarifaPrice.filter(e => e.tarifa_id === 'seguro')[0].price,
                })
                arrTarif.push({
                  tarifa_id: 'deposito',
                  price: tarifaPrice.filter(e => e.tarifa_id === 'deposito')[0].price,
                })
                arrTarif.push({
                  tarifa_id: 'day_extra',
                  price: tarifaPrice.filter(e => e.tarifa_id === 'day_extra')[0].price,
                })
                season.tarifas_id.forEach(elementTarif => {
                  const oldPrice = tarifaPrice.filter(e => e.tarifa_id === elementTarif)
                  if (oldPrice.length > 0) {
                    arrTarif.push({ tarifa_id: elementTarif, price: oldPrice[0].price })
                  } else {
                    arrTarif.push({ tarifa_id: elementTarif, price: 0 })
                  }
                })
                const newValue = {
                  category_id: category.category_id.id,
                  marca_id: null,
                  temporada_id: season.temporada_id,
                  from: season.from,
                  to: season.to,
                  prices: arrTarif,
                  cupos: pricesTemporadasContrateCar[index].cupos,
                }

                pricesTemporadasContrateCar[index] = newValue
                find = true
              }
            }

            // CREAR LA TARIFA PORQ NO EXISTIA
            if (!find) {
              const arrTarifN = []
              arrTarifN.push({ tarifa_id: 'seguro', price: 0 })
              arrTarifN.push({ tarifa_id: 'deposito', price: 0 })
              arrTarifN.push({ tarifa_id: 'day_extra', price: 0 })
              pricesTemporadasContrateCar.push({
                category_id: category.category_id.id,
                marca_id: null,
                temporada_id: season.temporada_id,
                from: season.from,
                to: season.to,
                prices: arrTarifN,
                cupos: [{ oficina_id: [-1], cuposDisponible: null, cuposTotal: null }],
              })
            }
          }
        })

        marcasModeloContrateCar.forEach(marca => {
          const prices = pricesTemporadasContrateCar.filter(
            e => e.temporada_id === season.temporada_id && e.marca_id === marca.marca_id,
          )
          if (prices.length === 0) {
            const arrTarif = []
            arrTarif.push({ tarifa_id: 'seguro', price: 0 })
            arrTarif.push({ tarifa_id: 'deposito', price: 0 })
            arrTarif.push({ tarifa_id: 'day_extra', price: 0 })
            season.tarifas_id.forEach(element => {
              arrTarif.push({ tarifa_id: element, price: 0 })
            })
            pricesTemporadasContrateCar.push({
              category_id: null,
              marca_id: marca.marca_id,
              temporada_id: season.temporada_id,
              from: season.from,
              to: season.to,
              prices: arrTarif,
              cupos: [{ oficina_id: [-1], cuposDisponible: null, cuposTotal: null }],
            })
          } else {
            let find = false
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < pricesTemporadasContrateCar.length; index++) {
              const element = pricesTemporadasContrateCar[index]
              if (element.temporada_id === season.temporada_id && element.marca_id === marca.marca_id) {
                // VERIFICAR SI YA EXITIA LA TARIFA
                const tarifaPrice = pricesTemporadasContrateCar[index].prices

                const arrTarif = []
                arrTarif.push({
                  tarifa_id: 'seguro',
                  price: tarifaPrice.filter(e => e.tarifa_id === 'seguro')[0].price,
                })
                arrTarif.push({
                  tarifa_id: 'deposito',
                  price: tarifaPrice.filter(e => e.tarifa_id === 'deposito')[0].price,
                })
                arrTarif.push({
                  tarifa_id: 'day_extra',
                  price: tarifaPrice.filter(e => e.tarifa_id === 'day_extra')[0].price,
                })
                season.tarifas_id.forEach(elementTarif => {
                  const oldPrice = tarifaPrice.filter(e => e.tarifa_id === elementTarif)
                  if (oldPrice.length > 0) {
                    arrTarif.push({ tarifa_id: elementTarif, price: oldPrice[0].price })
                  } else {
                    arrTarif.push({ tarifa_id: elementTarif, price: 0 })
                  }
                })
                const newValue = {
                  category_id: null,
                  marca_id: marca.marca_id,
                  temporada_id: season.temporada_id,
                  from: season.from,
                  to: season.to,
                  prices: arrTarif,
                  cupos: pricesTemporadasContrateCar[index].cupos,
                }

                pricesTemporadasContrateCar[index] = newValue
                find = true
              }
            }
            if (!find) {
              const arrTarifN = []
              arrTarifN.push({ tarifa_id: 'seguro', price: 0 })
              arrTarifN.push({ tarifa_id: 'deposito', price: 0 })
              arrTarifN.push({ tarifa_id: 'day_extra', price: 0 })
              pricesTemporadasContrateCar.push({
                category_id: null,
                marca_id: marca.marca_id,
                temporada_id: season.temporada_id,
                from: season.from,
                to: season.to,
                prices: arrTarifN,
                cupos: [{ oficina_id: [-1], cuposDisponible: null, cuposTotal: null }],
              })
            }
          }
        })
      })
    }
  },
  addCupoPricesTemporadasContrateCar: (state, posPrice) => {
    const { pricesTemporadasContrateCar } = state
    state.cargandoCuposTemporadasContrateCar = true

    pricesTemporadasContrateCar[posPrice].cupos.push({ oficina_id: [-1], cuposDisponible: null, cuposTotal: null })
    setTimeout(() => {
      state.cargandoCuposTemporadasContrateCar = false
    }, 100)
  },
  deleteCupoPricesTemporadasContrateCar: (state, { posPrice, posCupo }) => {
    const { pricesTemporadasContrateCar } = state
    state.cargandoCuposTemporadasContrateCar = true

    pricesTemporadasContrateCar[posPrice].cupos.splice(posCupo, 1)
    setTimeout(() => {
      state.cargandoCuposTemporadasContrateCar = false
    }, 100)
  },
  updatePricesTemporadasContrateCar: (state, items) => {
    state.pricesTemporadasContrateCar = items
  },
  setPosCotizador: (state, pos) => {
    state.posCotizador = pos
  },
  setCotizadorCarsResult: (state, items) => {
    state.cotizadorCarsResult = items
  },
  setCotizadorCars: (state, item) => {
    state.cotizadorCars = item
  },
  setDeclineTerms: (state, value) => {
    state.declineTerms = value
  },
  setSearchingCar: (state, valor) => {
    state.searchingCar = valor
  },
  setCotizadorCarsResultSelect: (state, items) => {
    state.cotizadorCarsResultSelect = items
  },
  setCotizadorCarsResultSelectFacturar: (state, {
    tarifaAllDay,
    tarifaSeguro,
    tarifaGarantia,
    tarifaDayExtra,
    suplementos,
    priceTotal,
    priceWithDescuento,
    priceWithMarkup,
  }) => {
    state.cotizadorCarsResultSelect = {
      ...state.cotizadorCarsResultSelect,
      tarifaAllDay,
      tarifaSeguro,
      tarifaGarantia,
      tarifaDayExtra,
      suplementos,
      priceTotal,
      priceWithDescuento,
      priceWithMarkup,
    }
  },
  setCotizadorCarsResultSelectEdit: (state, items) => {
    state.cotizadorCarsResultSelectEdit = items
  },
  setCotizadorCarsResultSelectEditWith: (state, items) => {
    state.cotizadorCarsResultSelectEditWith = items
  },
  setCotizadorPaxCarSelect: (state, item) => {
    state.cotizadorPaxCarSelect = item
  },
  setSameParamters: (state, value) => {
    state.sameParamters = value
  },
  emptyCotizadorPaxCarSelect: state => {
    state.cotizadorPaxCarSelect = {
      nameConductorPrincipal: null,
      apellidosConductorPrincipal: null,
      nameSecoundConductorPrincipal: null,
      birthdayConductorPrincipal: null,
      nationalityConductorPrincipal: null,
      sexConductorPrincipal: null,
      noDocumentConductorPrincipal: null,
      noDocumentConductorPrincipalScanner: null,
      conductorAditional: false,
      nameConductorAditional: null,
      apellidosConductorAditional: null,
      nameSecoundConductorAditional: null,
      birthdayConductorAditional: null,
      nationalityConductorAditional: null,
      sexConductorAditional: null,
      noDocumentConductorAditional: null,
      noDocumentConductorAditionalScanner: null,
    }
  },
  setCotizadorCarsSelectMarkups: (state, item) => {
    state.cotizadorCarsSelectMarkups = item
  },
  setCotizadorCarSelectPrice: (state, { priceTotal, priceWithMarkup, priceWithDescuento }) => {
    state.cotizadorCarsResultSelect.priceTotal = priceTotal
    state.cotizadorCarsResultSelect.priceWithMarkup = priceWithMarkup
    state.cotizadorCarsResultSelect.priceWithDescuento = priceWithDescuento
  },
  setNoDocumentConductorPrincipalScanner: (state, item) => {
    state.noDocumentConductorPrincipalScanner = item
  },
  setNoDocumentConductorAdicionalScanner: (state, item) => {
    state.noDocumentConductorAditionalScanner = item
  },
  setAvalibleFiltrosCotizadorCar: (state, item) => {
    state.avalibleFiltrosCotizadorCar = item
  },

  updateOnlyAdult: (state, status) => {
    state.onlyAdult = status
  },
  updateAllInclude: (state, status) => {
    state.allInclude = status
  },

  // CONTRATE HOTEL
  addObservationContrateHotel: state => {
    state.observationsHotel.push({
      description: null,
    })
  },
  deleteObservationContrateHotel: (state, pos) => {
    const { observationsHotel } = state

    observationsHotel.splice(pos, 1)
  },
  updateObservationContrateHotel: (state, items) => {
    state.observationsHotel = items
  },

  // HOTELES ASOCIADOS
  addIdsHotelsAsociadosContrate: (state, item) => {
    state.idsHotelsAsociados.push(item)
  },
  deleteIdsHotelsAsociadosContrate: (state, item) => {
    const { idsHotelsAsociados } = state

    const index = idsHotelsAsociados.findIndex(e => e === item)

    idsHotelsAsociados.splice(index, 1)
  },
  updateIdsHotelsAsociadosContrate: (state, items) => {
    state.idsHotelsAsociados = items
  },
  addTemporadaContrateHotel: state => {
    const { temporadasContrateHotel } = state

    temporadasContrateHotel.push({
      temporada_id: uuidv4(),
      name: null,
      typePayment: 'paxByNight',
      showTarifas: false,
      showRooms: false,
      formSGL: {
        type: 'price',
        reduc: '%',
      },
      formTPL: {
        type: 'price',
        reduc: '%',
      },
      formPAX4: {
        type: 'price',
        reduc: '%',
      },
      formPAX5: {
        type: 'price',
        reduc: '%',
      },
      formPAX6: {
        type: 'price',
        reduc: '%',
      },
      formPAX7: {
        type: 'price',
        reduc: '%',
      },
      formPAX8: {
        type: 'price',
        reduc: '%',
      },
      dates: [{ from: null, to: null }],
    })
  },
  deleteTemporadaContrateHotel: (state, pos) => {
    const { temporadasContrateHotel } = state

    temporadasContrateHotel.splice(pos, 1)
  },
  updateTemporadaContrateHotel: (state, update) => {
    state.temporadasContrateHotel = update
  },
  addDateTemporadaContrateHotel: (state, posSeason) => {
    state.temporadasContrateHotel[posSeason].dates.push({ from: null, to: null })
  },
  deleteDateTemporadaContrateHotel: (state, { posSeason, posDate }) => {
    state.temporadasContrateHotel[posSeason].dates.splice(posDate, 1)
  },
  updateIdsHotelsContrateHotel: (state, update) => {
    state.hotelsAsociadosTemporadas = update
  },
  updateHotelesPactadosContrateHotel: (state, update) => {
    state.idsHotelsAsociadosTemporadas = update
  },
  updateCantPaxsContrate: (state, cant) => {
    state.cantPaxsContrate = cant
  },
  addPricesHotelContrate: (state, item) => {
    state.pricesHotelContrate.push(item)
  },
  deletePricesHotelContrate: (state, { contrate_id, hotel_id, room_id }) => {
    const update = []
    state.pricesHotelContrate.forEach(element => {
      // eslint-disable-next-line no-empty
      if (element.contrate_id === contrate_id && element.hotel_id === hotel_id && element.room_id === room_id) {
      } else {
        update.push(element)
      }
    })

    setTimeout(() => {
      state.pricesHotelContrate = update
    }, 150)
  },
  updatePricesHotelContrate: (state, update) => {
    state.pricesHotelContrate = update
  },

  updateSeasonContrateHotelByOne: (state, update) => {
    state.seasonContrateHotel = update
  },

  //* ****************************************************************************/
  // init contrate hotel
  verifySeasonContrateOnlyAdult(state) {
    if (state.seasonContrateHotel.only_adult) {
      state.maxOcupationRoomsSeasonContrateHotel.forEach(element => {
        element.ocupations.childMax = 0
      })
      state.roomsSeasonContrateHotel.forEach(element => {
        element.childAge = {
          from: 'N/A',
          to: 'N/A',
        }
        element.infantAge = {
          from: 'N/A',
          to: 'N/A',
        }
      })
    }
  },
  addSeasonContrateHotel(state, hotel_id) {
    let from = null
    let to = null
    if (state.seasonsContrateHotel.length > 0) {
      if (state.seasonsContrateHotel[state.seasonsContrateHotel.length - 1].to) {
        from = moment(state.seasonsContrateHotel[state.seasonsContrateHotel.length - 1].to)
          .add(1, 'day')
          .toDate()
          .toISOString()
          .substr(0, 10)
        to = moment(state.seasonsContrateHotel[state.seasonsContrateHotel.length - 1].to)
          .add(30, 'day')
          .toDate()
          .toISOString()
          .substr(0, 10)
      }
    }
    state.seasonsContrateHotel.push({
      hotel_id,
      temporada_id: uuidv4(),
      name: `T ${state.seasonsContrateHotel.length + 1}`,
      from,
      to,
    })

    // ACTUALIZAR LOS CUPOS
    for (let index = 0; index < state.cuposRoomsSeasonContrateHotel.length; index++) {
      state.cuposRoomsSeasonContrateHotel[index].cupos.push({ cupo: 'OR' })
    }

    // ACTUALIZAR LOS RELEASE
    for (let index = 0; index < state.releaseRoomsSeasonContrateHotel.length; index++) {
      state.releaseRoomsSeasonContrateHotel[index].release.push({ days: 'N/A' })
    }

    // ACTUALIZAR LA ESTANCIA MIN
    for (let index = 0; index < state.estaciaMinRoomsSeasonContrateHotel.length; index++) {
      state.estaciaMinRoomsSeasonContrateHotel[index].estancia_min.push({ days: 1 })
    }

    // ACTUALIZAR LA ESTANCIA MAX
    for (let index = 0; index < state.estaciaMaxRoomsSeasonContrateHotel.length; index++) {
      state.estaciaMaxRoomsSeasonContrateHotel[index].estancia_max.push({ days: 99 })
    }

    // ACTUALIZAR EL PRECIO EN DBL
    for (let index = 0; index < state.pricesDBLRoomsSeasonContrateHotel.length; index++) {
      state.pricesDBLRoomsSeasonContrateHotel[index].prices.push({ price: null })
    }

    // ACTUALIZAR LOS SUPLEMENTOS/REDUCCIONES/TARIFAS
    for (let index = 0; index < state.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
      for (let j = 0; j < state.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
        state.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j].prices.push({ price: null })
      }
    }

    // ACTUALIZAR LOS SUPLEMENTOS ALIMENTICIOS
    for (let index = 0; index < state.suplementsAlimenticiosSeasonContrateHotel.length; index++) {
      state.suplementsAlimenticiosSeasonContrateHotel[index].adl.prices.push({ price: null })
      state.suplementsAlimenticiosSeasonContrateHotel[index].teen.prices.push({ price: null })
      state.suplementsAlimenticiosSeasonContrateHotel[index].mnr.prices.push({ price: null })
      state.suplementsAlimenticiosSeasonContrateHotel[index].inf.prices.push({ price: null })
    }

    // ACTUALIZAR OTROS SUPLEMENTOS
    for (let index = 0; index < state.suplementsOtrosSeasonContrateHotel.length; index++) {
      state.suplementsOtrosSeasonContrateHotel[index].adl.prices.push({ price: null })
      state.suplementsOtrosSeasonContrateHotel[index].teen.prices.push({ price: null })
      state.suplementsOtrosSeasonContrateHotel[index].mnr.prices.push({ price: null })
      state.suplementsOtrosSeasonContrateHotel[index].inf.prices.push({ price: null })

      // CHECK-IN/OUT
      for (let j = 0; j < state.suplementsOtrosSeasonContrateHotel[index].rooms.length; j++) {
        state.suplementsOtrosSeasonContrateHotel[index].rooms[j].prices.push({ price: null })
      }
    }

    // ACTUALIZAR LAS POLITICAS DE NIÑOS
    for (let index = 0; index < state.politicasChildSeasonContrateHotel.length; index++) {
      state.politicasChildSeasonContrateHotel[index].adl.prices.push({ price: null })

      for (let j = 0; j < state.politicasChildSeasonContrateHotel[index].mnrs.length; j++) {
        state.politicasChildSeasonContrateHotel[index].mnrs[j].prices.push({ price: null })
      }

      for (let j = 0; j < state.politicasChildSeasonContrateHotel[index].mnrs_rooms.length; j++) {
        for (let jk = 0; jk < state.politicasChildSeasonContrateHotel[index].mnrs_rooms[j].mnrs.length; jk++) {
          state.politicasChildSeasonContrateHotel[index].mnrs_rooms[j].mnrs[jk].prices.push({ price: null })
        }
      }
    }
  },
  updateSeasonContrateHotel(state, update) {
    state.seasonsContrateHotel = update
  },
  deleteSeasonContrateHotel(state, pos) {
    state.seasonsContrateHotel.splice(pos, 1)

    // ACTUALIZAR LOS CUPOS
    for (let index = 0; index < state.cuposRoomsSeasonContrateHotel.length; index++) {
      state.cuposRoomsSeasonContrateHotel[index].cupos.splice(pos, 1)
    }

    // ACTUALIZAR LOS RELEASE
    for (let index = 0; index < state.releaseRoomsSeasonContrateHotel.length; index++) {
      state.releaseRoomsSeasonContrateHotel[index].release.splice(pos, 1)
    }

    // ACTUALIZAR LA ESTANCIA MIN
    for (let index = 0; index < state.estaciaMinRoomsSeasonContrateHotel.length; index++) {
      state.estaciaMinRoomsSeasonContrateHotel[index].estancia_min.splice(pos, 1)
    }

    // ACTUALIZAR LA ESTANCIA MAX
    for (let index = 0; index < state.estaciaMaxRoomsSeasonContrateHotel.length; index++) {
      state.estaciaMaxRoomsSeasonContrateHotel[index].estancia_max.splice(pos, 1)
    }

    // ACTUALIZAR EL PRECIO EN DBL
    for (let index = 0; index < state.pricesDBLRoomsSeasonContrateHotel.length; index++) {
      state.pricesDBLRoomsSeasonContrateHotel[index].prices.splice(pos, 1)
    }

    // ACTUALIZAR LOS SUPLEMENTOS/REDUCCIONES/TARIFAS
    for (let index = 0; index < state.suplementsReduccTarifasSeasonContrateHotel.length; index++) {
      for (let j = 0; j < state.suplementsReduccTarifasSeasonContrateHotel[index].rooms.length; j++) {
        state.suplementsReduccTarifasSeasonContrateHotel[index].rooms[j].prices.splice(pos, 1)
      }
    }

    // ACTUALIZAR LOS SUPLEMENTOS ALIMENTICIOS
    for (let index = 0; index < state.suplementsAlimenticiosSeasonContrateHotel.length; index++) {
      state.suplementsAlimenticiosSeasonContrateHotel[index].adl.prices.splice(pos, 1)
      state.suplementsAlimenticiosSeasonContrateHotel[index].teen.prices.splice(pos, 1)
      state.suplementsAlimenticiosSeasonContrateHotel[index].mnr.prices.splice(pos, 1)
      state.suplementsAlimenticiosSeasonContrateHotel[index].inf.prices.splice(pos, 1)
    }

    // ACTUALIZAR OTROS SUPLEMENTOS
    for (let index = 0; index < state.suplementsOtrosSeasonContrateHotel.length; index++) {
      state.suplementsOtrosSeasonContrateHotel[index].adl.prices.splice(pos, 1)
      state.suplementsOtrosSeasonContrateHotel[index].teen.prices.splice(pos, 1)
      state.suplementsOtrosSeasonContrateHotel[index].mnr.prices.splice(pos, 1)
      state.suplementsOtrosSeasonContrateHotel[index].inf.prices.splice(pos, 1)

      // CHECK-IN/OUT
      for (let j = 0; j < state.suplementsOtrosSeasonContrateHotel[index].rooms.length; j++) {
        state.suplementsOtrosSeasonContrateHotel[index].rooms[j].prices.splice(pos, 1)
      }
    }

    // ACTUALIZAR LAS POLITICAS DE NIÑOS
    for (let index = 0; index < state.politicasChildSeasonContrateHotel.length; index++) {
      state.politicasChildSeasonContrateHotel[index].adl.prices.splice(pos, 1)

      for (let j = 0; j < state.politicasChildSeasonContrateHotel[index].mnrs.length; j++) {
        state.politicasChildSeasonContrateHotel[index].mnrs[j].prices.splice(pos, 1)
      }

      for (let j = 0; j < state.politicasChildSeasonContrateHotel[index].mnrs_rooms.length; j++) {
        for (let jk = 0; jk < state.politicasChildSeasonContrateHotel[index].mnrs_rooms[j].mnrs.length; jk++) {
          state.politicasChildSeasonContrateHotel[index].mnrs_rooms[j].mnrs[jk].prices.splice(pos, 1)
        }
      }
    }

    // ELIMINAR EN LOS SUPLEMENTS
    // eslint-disable-next-line no-plusplus
    /* for (let index = 0; index < state.suplementsSeasonContrateHotel.length; index++) {
      state.suplementsSeasonContrateHotel[index].prices.splice(pos, 1)
    }

    // ELIMINAR EN LAS REDUCCIONES
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < state.reduccionsSeasonContrateHotel.length; index++) {
      state.reduccionsSeasonContrateHotel[index].prices.splice(pos, 1)
    } */
  },
  addRoomSeasonContrateHotel(state, plan_id) {
    state.roomsSeasonContrateHotel.push({
      rooms_id: [],
      plan_id,
      planes_id: [],
      adultAge: {
        from: null,
        to: null,
      },
      teenAge: {
        from: 'N/A',
        to: 'N/A',
      },
      has_a_teen: false,
      childAge: {
        from: null,
        to: null,
      },
      has_a_mnr: false,
      infantAge: {
        from: '0.00',
        to: null,
      },
      has_a_inf: false,
    })
  },
  updateRoomSeasonContrateHotel(state, update) {
    state.roomsSeasonContrateHotel = update
  },
  updateTarifariosOriginContrateHotel(state, update) {
    state.tarifariosOriginHotels = update
  },
  updateTarifariosContrateHotel(state, update) {
    state.tarifariosHotels = update
  },
  updateTarifasContrateHotel(state, update) {
    state.tarifasHotels = update
  },
  deleteTarifasContrateHotel(state, pos) {
    state.tarifasHotels.splice(pos, 1)
  },
  addTarifasContrateHotel(state) {
    state.tarifasHotels.push({
      code: uuidv4().toUpperCase().substring(28),
      name: '',
      category_id: 0,
      category_slug: '',
    })
  },

  // PARA CTTOS DIRECTOS
  updateTarifasContrateDirectoHotel(state, update) {
    state.tarifasDirectoHotels = update
  },
  deleteTarifasContrateDirectoHotel(state, pos) {
    state.tarifasDirectoHotels.splice(pos, 1)
  },
  addTarifasContrateDirectoHotel(state) {
    const code = uuidv4().toUpperCase().substring(28)
    state.tarifasDirectoHotels.push({
      code,
      name: '',
      category_id: 0,
      category_slug: '',
      seasons: [],
      cupos: [],
      release: [],
      estancia_min: [],
      estancia_max: [],
      type_estancia_dbl: [],
      is_tarifa_price_estancia_dbl: true,
      price_in_dbl: [],
      politicas_childs: state.politicasChildSeasonContrateHotel.length > 0 ? state.politicasChildSeasonContrateHotel : [],
      suplements_reductions: [],
      suplements_alimenticios: [],
      suplements_otros: [],
      not_show: [],
    })
    if (state.tarifaSelectDirectoHotels === -1) {
      state.tarifaSelectDirectoHotels = code
    }
  },
  updateTarifaSelectDirectoHotel(state, update) {
    state.tarifaSelectDirectoHotels = update
  },
  updateTarifariosOrigin(state, update) {
    state.tarifariosOrigin = update
  },
  updateTarifarioFiltersContrateHotel(state, update) {
    state.tarifarioFiltersHotels = update
  },
  setLoadingTarifarios(state, update) {
    state.loadingTarifarios = update
  },
  filterTarifariosContrateHotel(state) {
    const newArray = []
    state.tarifariosOriginHotels.forEach(element => {
      if (element.room_id == state.tarifarioFiltersHotels.room_id
          && element.plan === state.tarifarioFiltersHotels.plan
         && moment(element.dateFrom) >= moment(state.tarifarioFiltersHotels.from)
        && moment(element.dateTo) <= moment(state.tarifarioFiltersHotels.to)) {
        let add = false
        // eslint-disable-next-line no-restricted-globals
        if (state.tarifarioFiltersHotels.adult === 0 || state.tarifarioFiltersHotels.adult === 1 || state.tarifarioFiltersHotels.adult === 2 || state.tarifarioFiltersHotels.adult === 3) {
          if (element.adult == state.tarifarioFiltersHotels.adult) {
            add = true
          }
        } else if (state.tarifarioFiltersHotels.adult === '3+') {
          if (element.adult > 3) {
            add = true
          }
        } else if (state.tarifarioFiltersHotels.adult === 'N/A') {
          if (element.adult == 0) {
            add = true
          }
        } else if (state.tarifarioFiltersHotels.adult === 'Todos') {
          add = true
        }

        let tieneMnr = false
        let addMnr = false
        if (state.tarifarioFiltersHotels.children === 0 || state.tarifarioFiltersHotels.children === 1 || state.tarifarioFiltersHotels.children === 2 || state.tarifarioFiltersHotels.children === 3) {
          tieneMnr = true
          if (element.children == state.tarifarioFiltersHotels.children) {
            addMnr = true
          }
        } else if (state.tarifarioFiltersHotels.children === 'N/A') {
          tieneMnr = true
          if (element.children == 0) {
            addMnr = true
          }
        } else if (state.tarifarioFiltersHotels.children === 'Todos') {
          tieneMnr = true
          addMnr = true
        }

        let tieneInf = false
        let addInf = false
        if (state.tarifarioFiltersHotels.babies === 0 || state.tarifarioFiltersHotels.babies === 1 || state.tarifarioFiltersHotels.babies === 2 || state.tarifarioFiltersHotels.babies === 3) {
          tieneInf = true
          if (element.babies == state.tarifarioFiltersHotels.babies) {
            addInf = true
          }
        } else if (state.tarifarioFiltersHotels.babies === 'N/A') {
          tieneInf = true
          if (element.babies == 0) {
            addInf = true
          }
        } else if (state.tarifarioFiltersHotels.babies === 'Todos') {
          tieneInf = true
          addInf = true
        }

        if (add) {
          let addF = true
          if (tieneMnr) {
            if (!addMnr) {
              addF = false
            }
          }

          if (tieneInf) {
            if (!addInf) {
              addF = false
            }
          }

          if (addF) {
            newArray.push(element)
          }
        }

        /* const seasonChild = []
        // eslint-disable-next-line no-restricted-globals
        if (state.tarifarioFiltersHotels.children === 0 || state.tarifarioFiltersHotels.children === 1 || state.tarifarioFiltersHotels.children === 2 || state.tarifarioFiltersHotels.children === 3) {
          if (element.children == state.tarifarioFiltersHotels.children) {
            seasonChild.push(element)
          }
        } else if (state.tarifarioFiltersHotels.children === 'N/A') {
          if (element.children == 0) {
            seasonChild.push(element)
          }
        } else if (state.tarifarioFiltersHotels.children === 'Todos') {
          seasonChild.push(element)
        }
        seasonCumple = seasonChild

        const seasonBabies = []
        // eslint-disable-next-line no-restricted-globals
        if (state.tarifarioFiltersHotels.babies === 0 || state.tarifarioFiltersHotels.babies === 1 || state.tarifarioFiltersHotels.babies === 2 || state.tarifarioFiltersHotels.babies === 3) {
          if (element.babies == state.tarifarioFiltersHotels.babies) {
            seasonBabies.push(element)
          }
        } else if (state.tarifarioFiltersHotels.babies === 'N/A') {
          if (element.babies == 0) {
            seasonBabies.push(element)
          }
        } else if (state.tarifarioFiltersHotels.babies === 'Todos') {
          seasonBabies.push(element)
        }
        seasonCumple = seasonBabies

        const seasonFrom = []
        if (state.tarifarioFiltersHotels.from) {
          seasonCumple.forEach(elementSeason => {
            // const exp = elementSeason.dateFrom.split('/')
            // if (moment(`${exp[2]}-${exp[1]}-${exp[0]}`) >= moment(state.tarifarioFiltersHotels.from)) {
            if (moment(elementSeason.dateFrom) >= moment(state.tarifarioFiltersHotels.from)) {
              seasonFrom.push(elementSeason)
            }
          })
          seasonCumple = seasonFrom
        }

        const seasonTo = []
        if (state.tarifarioFiltersHotels.to) {
          seasonCumple.forEach(elementSeason => {
            if (moment(elementSeason.dateTo) <= moment(state.tarifarioFiltersHotels.to)) {
              seasonTo.push(elementSeason)
            }
          })
          seasonCumple = seasonTo
        }

        if (seasonCumple.length > 0) {
          // VERFIICAR Q YA NO ESTE EN EL ARRAY
          seasonCumple.forEach(seas => {
            newArray.push(seas)
          })
        } */
      }
    })

    /* state.tarifariosOriginHotels.forEach(element => {
      if (element.room_id == state.tarifarioFiltersHotels.room_id
          && element.plan === state.tarifarioFiltersHotels.plan) {
        let seasonCumple = []
        element.seasons.forEach(elementSeason => {
          // eslint-disable-next-line no-restricted-globals
          if (state.tarifarioFiltersHotels.adult === 0 || state.tarifarioFiltersHotels.adult === 1 || state.tarifarioFiltersHotels.adult === 2 || state.tarifarioFiltersHotels.adult === 3) {
            if (elementSeason.adult == state.tarifarioFiltersHotels.adult) {
              seasonCumple.push(elementSeason)
            }
          } else if (state.tarifarioFiltersHotels.adult === 'N/A') {
            if (elementSeason.adult == 0) {
              seasonCumple.push(elementSeason)
            }
          } else if (state.tarifarioFiltersHotels.adult === 'Todos') {
            seasonCumple.push(elementSeason)
          }
        })

        const seasonChild = []
        seasonCumple.forEach(elementSeason => {
          // eslint-disable-next-line no-restricted-globals
          if (state.tarifarioFiltersHotels.children === 0 || state.tarifarioFiltersHotels.children === 1 || state.tarifarioFiltersHotels.children === 2 || state.tarifarioFiltersHotels.children === 3) {
            if (elementSeason.children == state.tarifarioFiltersHotels.children) {
              seasonChild.push(elementSeason)
            }
          } else if (state.tarifarioFiltersHotels.children === 'N/A') {
            if (elementSeason.children == 0) {
              seasonChild.push(elementSeason)
            }
          } else if (state.tarifarioFiltersHotels.children === 'Todos') {
            seasonChild.push(elementSeason)
          }
        })
        seasonCumple = seasonChild

        const seasonBabies = []
        seasonCumple.forEach(elementSeason => {
          // eslint-disable-next-line no-restricted-globals
          if (state.tarifarioFiltersHotels.babies === 0 || state.tarifarioFiltersHotels.babies === 1 || state.tarifarioFiltersHotels.babies === 2 || state.tarifarioFiltersHotels.babies === 3) {
            if (elementSeason.babies == state.tarifarioFiltersHotels.babies) {
              seasonBabies.push(elementSeason)
            }
          } else if (state.tarifarioFiltersHotels.babies === 'N/A') {
            if (elementSeason.babies == 0) {
              seasonBabies.push(elementSeason)
            }
          } else if (state.tarifarioFiltersHotels.babies === 'Todos') {
            seasonBabies.push(elementSeason)
          }
        })
        seasonCumple = seasonBabies

        const seasonFrom = []
        if (state.tarifarioFiltersHotels.from) {
          seasonCumple.forEach(elementSeason => {
            // const exp = elementSeason.dateFrom.split('/')
            // if (moment(`${exp[2]}-${exp[1]}-${exp[0]}`) >= moment(state.tarifarioFiltersHotels.from)) {
            if (moment(elementSeason.dateFrom) >= moment(state.tarifarioFiltersHotels.from)) {
              seasonFrom.push(elementSeason)
            }
          })
          seasonCumple = seasonFrom
        }

        const seasonTo = []
        if (state.tarifarioFiltersHotels.to) {
          seasonCumple.forEach(elementSeason => {
            if (moment(elementSeason.dateTo) <= moment(state.tarifarioFiltersHotels.to)) {
              seasonTo.push(elementSeason)
            }
          })
          seasonCumple = seasonTo
        }

        if (seasonCumple.length > 0) {
          // VERFIICAR Q YA NO ESTE EN EL ARRAY
          if (JSON.stringify(element.seasons) !== JSON.stringify(seasonCumple)) {
            newArray.push({
              ...element,
              seasons: seasonCumple,
            })
          }
        }
      }
    }) */

    newArray.sort((a, b) => {
      const x = a.dateFrom
      const y = b.dateFrom

      if (x > y) {
        return 1
      }
      if (x < y) {
        return -1
      }

      return 0
    })

    state.tarifariosHotels = newArray
  },
  deleteRoomSeasonContrateHotel(state, pos) {
    state.roomsSeasonContrateHotel.splice(pos, 1)
  },
  reajustPoliticasChildSeasonContrateHotel(state) {
    state.politicasChildSeasonContrateHotel = []

    state.maxOcupationRoomsSeasonContrateHotel.forEach(element => {
      let minAd = parseInt(element.ocupations.adultMin, 10)
      while (minAd <= element.ocupations.adultMax) {
        if (element.ocupations.childMax > 0) {
          let minMnr = 0
          while (minMnr <= element.ocupations.childMax) {
            let minInf = 0
            while (minInf <= element.ocupations.infMax) {
              if (minInf + minMnr + minAd <= element.ocupations.paxMax) {
                if (minInf + minMnr <= element.ocupations.childMax) {
                  if (minMnr + minAd > 0) {
                    const pricesAdl = []
                    state.seasonsContrateHotel.forEach(() => {
                      pricesAdl.push({ price: null })
                    })

                    const mmm = []
                    const mmm_rooms = []

                    // PARA SEPARAR LOS MNR
                    if (minMnr > 0) {
                      let valueMnr = 1
                      while (valueMnr <= minMnr) {
                        const pricesMnr = []
                        state.seasonsContrateHotel.forEach(() => {
                          pricesMnr.push({ price: null })
                        })
                        mmm.push({
                          name:
                            // eslint-disable-next-line no-nested-ternary
                            valueMnr === 1
                              ? minMnr > 1
                                ? '1er MNR'
                                : 'MNR'
                              : valueMnr === 2
                                ? '2do MNR'
                                : valueMnr === 3
                                  ? '3er MNR'
                                  : valueMnr === 4
                                    ? '4to MNR'
                                    : valueMnr === 5
                                      ? '5to MNR'
                                      : valueMnr === 6
                                        ? '6to MNR'
                                        : '',
                          is_teen: false,
                          is_mnr: true,
                          is_inf: false,
                          count: valueMnr,
                          prices: pricesMnr,
                          in_pc: false,
                          room_id: null,
                          base: 'adl_in_dbl',
                        })

                        // PARA TARIFAS EN ON
                        // eslint-disable-next-line no-loop-func
                        element.rooms_id.forEach(rrr => {
                          const pricesMnr2 = []
                          state.seasonsContrateHotel.forEach(() => {
                            pricesMnr2.push({ price: null })
                          })
                          mmm_rooms.push({
                            name:
                              // eslint-disable-next-line no-nested-ternary
                              valueMnr === 1
                                ? minMnr > 1
                                  ? '1er MNR'
                                  : 'MNR'
                                : valueMnr === 2
                                  ? '2do MNR'
                                  : valueMnr === 3
                                    ? '3er MNR'
                                    : valueMnr === 4
                                      ? '4to MNR'
                                      : valueMnr === 5
                                        ? '5to MNR'
                                        : valueMnr === 6
                                          ? '6to MNR'
                                          : '',
                            is_teen: false,
                            is_mnr: true,
                            is_inf: false,
                            count: valueMnr,
                            prices: pricesMnr2,
                            in_pc: false,
                            room_id: rrr,
                            base: 'adl_in_dbl',
                          })
                        })

                        valueMnr++
                      }
                    }

                    // PARA SEPARAR LOS INF
                    if (minInf > 0) {
                      let valueInf = 1
                      while (valueInf <= minInf) {
                        const pricesInf = []
                        state.seasonsContrateHotel.forEach(() => {
                          pricesInf.push({ price: null })
                        })
                        mmm.push({
                          name:
                            // eslint-disable-next-line no-nested-ternary
                            valueInf === 1
                              ? minInf > 1
                                ? '1er INF'
                                : 'INF'
                              : valueInf === 2
                                ? '2do INF'
                                : valueInf === 3
                                  ? '3er INF'
                                  : valueInf === 4
                                    ? '4to INF'
                                    : valueInf === 5
                                      ? '5to INF'
                                      : valueInf === 6
                                        ? '6to INF'
                                        : '',
                          is_teen: false,
                          is_mnr: false,
                          is_inf: true,
                          count: valueInf,
                          prices: pricesInf,
                          in_pc: false,
                          room_id: null,
                          base: 'adl_in_dbl',
                        })

                        // PARA TARIFAS EN ON
                        // eslint-disable-next-line no-loop-func
                        element.rooms_id.forEach(rrr => {
                          const pricesMnr2 = []
                          state.seasonsContrateHotel.forEach(() => {
                            pricesMnr2.push({ price: null })
                          })
                          mmm_rooms.push({
                            name:
                              // eslint-disable-next-line no-nested-ternary
                              valueInf === 1
                                ? minInf > 1
                                  ? '1er INF'
                                  : 'INF'
                                : valueInf === 2
                                  ? '2do INF'
                                  : valueInf === 3
                                    ? '3er INF'
                                    : valueInf === 4
                                      ? '4to INF'
                                      : valueInf === 5
                                        ? '5to INF'
                                        : valueInf === 6
                                          ? '6to INF'
                                          : '',
                            is_teen: false,
                            is_mnr: false,
                            is_inf: true,
                            count: valueInf,
                            prices: pricesMnr2,
                            in_pc: false,
                            room_id: rrr,
                            base: 'adl_in_dbl',
                          })
                        })

                        valueInf++
                      }
                    }

                    if (minMnr > 0 || minInf > 0) {
                      // ACOMODAR LAS COMB X ROOM
                      const mmm_rooms_new = []
                      for (let kkk = 0; kkk < mmm_rooms.length; kkk++) {
                        const menores = []
                        const { room_id } = mmm_rooms[kkk]
                        menores.push(mmm_rooms[kkk])
                        for (let jjj = kkk + 1; jjj < mmm_rooms.length; jjj++) {
                          if (room_id === mmm_rooms[jjj].room_id) {
                            menores.push(mmm_rooms[jjj])
                          }
                        }

                        let esta = false
                        mmm_rooms_new.forEach(newELe => {
                          if (newELe.room_id === room_id) {
                            esta = true
                          }
                        })

                        if (!esta) {
                          mmm_rooms_new.push({
                            room_id,
                            mnrs: menores,
                          })
                        }
                      }

                      state.politicasChildSeasonContrateHotel.push({
                        rooms_id: element.rooms_id,
                        is_tarifa: false,
                        teen_free: false,
                        mnr_free: false,
                        inf_free: false,
                        na: false,
                        adl: {
                          count: minAd,
                          prices: pricesAdl,
                          in_pc: false,
                          base: minAd === 0 ? 'N/A' : 'adl_in_dbl',
                        },
                        teen: {
                          count: 'N/A',
                        },
                        mnr: {
                          count: minMnr,
                        },
                        inf: {
                          count: minInf,
                        },
                        mnrs: mmm,
                        mnrs_rooms: mmm_rooms_new,
                        applyBy: state.seasonContrateHotel.price_estancia_dbl,
                      })
                    }
                  }
                } else {
                  break
                }
              } else {
                break
              }
              minInf++
            }
            minMnr++
          }
        } else {
          let minTeen = 0
          while (minTeen <= element.ocupations.teenMax) {
            if (minTeen + minAd <= element.ocupations.paxMax) {
              if (minTeen + minAd > 0) {
                const pricesAdl = []
                state.seasonsContrateHotel.forEach(() => {
                  pricesAdl.push({ price: null })
                })

                const mmm = []
                const mmm_rooms = []

                // PARA TEEN
                if (minTeen > 0) {
                  let valueTeen = 1
                  while (valueTeen <= minTeen) {
                    const pricesTeen = []
                    state.seasonsContrateHotel.forEach(() => {
                      pricesTeen.push({ price: null })
                    })
                    mmm.push({
                      name:
                        // eslint-disable-next-line no-nested-ternary
                        valueTeen === 1
                          ? minTeen > 1
                            ? '1er TEEN'
                            : 'TEEN'
                          : valueTeen === 2
                            ? '2do TEEN'
                            : valueTeen === 3
                              ? '3er TEEN'
                              : valueTeen === 4
                                ? '4to TEEN'
                                : valueTeen === 5
                                  ? '5to TEEN'
                                  : valueTeen === 6
                                    ? '6to TEEN'
                                    : '',
                      is_teen: true,
                      is_mnr: false,
                      is_inf: false,
                      count: valueTeen,
                      prices: pricesTeen,
                      in_pc: false,
                      base: 'adl_in_dbl',
                    })

                    // PARA TARIFAS EN ON
                    // eslint-disable-next-line no-loop-func
                    element.rooms_id.forEach(rrr => {
                      const pricesTeen2 = []
                      state.seasonsContrateHotel.forEach(() => {
                        pricesTeen2.push({ price: null })
                      })
                      mmm_rooms.push({
                        name:
                          // eslint-disable-next-line no-nested-ternary
                          valueTeen === 1
                            ? minTeen > 1
                              ? '1er TEEN'
                              : 'TEEN'
                            : valueTeen === 2
                              ? '2do TEEN'
                              : valueTeen === 3
                                ? '3er TEEN'
                                : valueTeen === 4
                                  ? '4to TEEN'
                                  : valueTeen === 5
                                    ? '5to TEEN'
                                    : valueTeen === 6
                                      ? '6to TEEN'
                                      : '',
                        is_teen: false,
                        is_mnr: false,
                        is_inf: true,
                        count: valueTeen,
                        prices: pricesTeen2,
                        in_pc: false,
                        room_id: rrr,
                        base: 'adl_in_dbl',
                      })
                    })

                    valueTeen++
                  }
                }

                if (minTeen > 0) {
                  state.politicasChildSeasonContrateHotel.push({
                    rooms_id: element.rooms_id,
                    is_tarifa: false,
                    teen_free: false,
                    mnr_free: false,
                    inf_free: false,
                    na: false,
                    adl: {
                      count: minAd,
                      prices: pricesAdl,
                      in_pc: false,
                      base: minAd === 0 ? 'N/A' : 'adl_in_dbl',
                    },
                    teen: {
                      count: minTeen,
                    },
                    mnr: {
                      count: 'N/A',
                    },
                    inf: {
                      count: 'N/A',
                    },
                    mnrs: mmm,
                    mnrs_rooms: mmm_rooms,
                    applyBy: state.seasonContrateHotel.price_estancia_dbl,
                  })
                }
              }
            } else {
              break
            }
            minTeen++
          }
        }
        minAd++
      }
    })

    // ACTUALIZAR LAS POLITICAS DE LOS CHAMAS EN TODAS LAS TARIFAS
    for (let index = 0; index < state.tarifasDirectoHotels.length; index++) {
      state.tarifasDirectoHotels[index].politicas_childs = state.politicasChildSeasonContrateHotel
    }
  },
  updatePoliticasChildSeasonContrateHotel(state, update) {
    state.politicasChildSeasonContrateHotel = update
  },
  setApplyPoliticaChildSeasonContrateHotel(state, apply) {
    for (let index = 0; index < state.politicasChildSeasonContrateHotel.length; index++) {
      state.politicasChildSeasonContrateHotel[index].applyBy = apply
    }
  },
  verifyRoomsPlanAge(state) {
    // VERFICAR SI SE AGREGO UNA HAB AL GRUPO
    state.roomsSeasonContrateHotel.forEach(roomsSeason => {
      const roomsId = roomsSeason.rooms_id
      roomsId.forEach(rId => {
        let esta = false
        state.pricesDBLRoomsSeasonContrateHotel.forEach(priceDBL => {
          if (priceDBL.room_id === rId) {
            esta = true
          }
        })
        if (!esta) {
          const prices = []
          state.seasonsContrateHotel.forEach(() => {
            prices.push({ price: null })
          })
          state.pricesDBLRoomsSeasonContrateHotel.push({
            room_id: rId,
            in_pc: false,
            prices,
          })
        }
      })
    })

    // VERIFICAR SI SE ELIMINO DE UN GRUPO
    for (let index = 0; index < state.pricesDBLRoomsSeasonContrateHotel.length; index++) {
      const priceDBL = state.pricesDBLRoomsSeasonContrateHotel[index]
      let esta = false
      state.roomsSeasonContrateHotel.forEach(roomsSeason => {
        if (roomsSeason.rooms_id.includes(priceDBL.room_id)) {
          esta = true
        }
      })
      if (!esta) {
        state.pricesDBLRoomsSeasonContrateHotel.splice(index, 1)
      }
    }
  },
  addPriceDBLRoomsSeasonContrateHotel(state, room_id) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })
    state.pricesDBLRoomsSeasonContrateHotel.push({
      room_id,
      in_pc: false,
      prices,
    })
  },
  verifyRoomsPriceDBL(state) {
    state.pricesDBLRoomsSeasonContrateHotel = []

    state.roomsSeasonContrateHotel.forEach(element => {
      element.rooms_id.forEach(elementRoom => {
        const prices = []
        state.seasonsContrateHotel.forEach(() => {
          prices.push({ price: null })
        })
        state.pricesDBLRoomsSeasonContrateHotel.push({
          room_id: elementRoom,
          is_main: true,
          in_pc: false,
          prices,
        })
      })
    })
  },
  verifyRoomsPriceDBLAllTarifas(state) {
    // ACTUALIZAR LOS PRECIOS EN DBL EN TODAS LAS TARIFAS
    for (let index = 0; index < state.tarifasDirectoHotels.length; index++) {
      const arr = []
      state.roomsSeasonContrateHotel.forEach(element => {
        element.rooms_id.forEach(elementRoom => {
          const prices = []
          state.tarifasDirectoHotels[index].seasons.forEach(() => {
            prices.push({ price: null })
          })

          arr.push({
            room_id: elementRoom,
            is_main: true,
            in_pc: false,
            prices,
          })
        })
      })

      state.tarifasDirectoHotels[index].price_in_dbl = arr
    }
  },

  changeRoomDBLSeasonContrateHotel(state, { room_id_old, room_id_new, pos }) {
    for (let index = 0; index < state.pricesDBLRoomsSeasonContrateHotel.length; index++) {
      if (index !== pos) {
        if (state.pricesDBLRoomsSeasonContrateHotel[index].room_id === room_id_new) {
          state.pricesDBLRoomsSeasonContrateHotel[index].room_id = room_id_old
        }
      }
    }
  },
  addPriceDBLRoomsNullSeasonContrateHotel(state) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })
    state.pricesDBLRoomsSeasonContrateHotel.push({
      room_id: null,
      in_pc: false,
      prices,
    })
  },
  updatePriceDBLRoomsSeasonContrateHotel(state, update) {
    state.pricesDBLRoomsSeasonContrateHotel = update
  },
  deletePriceDBLRoomsSeasonContrateHotel(state, pos) {
    state.pricesDBLRoomsSeasonContrateHotel.splice(pos, 1)
  },
  addCupoRoomSeasonContrateHotel(state) {
    const cupos = []
    state.seasonsContrateHotel.forEach(() => {
      cupos.push({ cupo: 'OR' })
    })
    state.cuposRoomsSeasonContrateHotel.push({
      rooms_id: [],
      cupos,
    })
  },
  updateCupoRoomSeasonContrateHotel(state, update) {
    state.cuposRoomsSeasonContrateHotel = update
  },
  deleteCupoRoomSeasonContrateHotel(state, pos) {
    state.cuposRoomsSeasonContrateHotel.splice(pos, 1)
  },
  addReleaseRoomSeasonContrateHotel(state) {
    const release = []
    state.seasonsContrateHotel.forEach(() => {
      release.push({ days: null })
    })
    state.releaseRoomsSeasonContrateHotel.push({
      rooms_id: [],
      release,
    })
  },
  updateReleaseRoomSeasonContrateHotel(state, update) {
    state.releaseRoomsSeasonContrateHotel = update
  },
  deleteReleaseRoomSeasonContrateHotel(state, pos) {
    state.releaseRoomsSeasonContrateHotel.splice(pos, 1)
  },
  addTipoCamaRoomSeasonContrateHotel(state) {
    state.tipoCamaRoomsSeasonContrateHotel.push({
      rooms_id: [],
      tipo_cama: [],
    })
  },
  updateTipoCamaRoomSeasonContrateHotel(state, update) {
    state.tipoCamaRoomsSeasonContrateHotel = update
  },
  deleteTipoCamaRoomSeasonContrateHotel(state, pos) {
    state.tipoCamaRoomsSeasonContrateHotel.splice(pos, 1)
  },
  addNotShowSeasonContrateHotel(state) {
    state.notShowSeasonContrateHotel.push({
      aplica_para: 'cancelaciones',
      time_ante_checkin_from: null,
      time_ante_checkin_to: null,
      time_ante_checkin_range: false,
      in_hours: false,
      cant_rooms: 1,
      all_cant_rooms: false,
      from: null,
      to: null,
      noches_penalizar: null,
      all_noches_penalizar: false,
      penalidad: null,
      pc_penalidad: false,
    })
  },
  updateNotShowSeasonContrateHotel(state, update) {
    state.notShowSeasonContrateHotel = update
  },
  deleteNotShowRoomSeasonContrateHotel(state, pos) {
    state.notShowSeasonContrateHotel.splice(pos, 1)
  },
  addEstanciaMinRoomSeasonContrateHotel(state) {
    const estancia_min = []
    state.seasonsContrateHotel.forEach(() => {
      estancia_min.push({ days: 1 })
    })
    state.estaciaMinRoomsSeasonContrateHotel.push({
      rooms_id: [],
      estancia_min,
    })
  },
  updateEstanciaMinRoomSeasonContrateHotel(state, update) {
    state.estaciaMinRoomsSeasonContrateHotel = update
  },
  deleteEstanciaMinRoomSeasonContrateHotel(state, pos) {
    state.estaciaMinRoomsSeasonContrateHotel.splice(pos, 1)
  },
  addEstanciaMaxRoomSeasonContrateHotel(state) {
    const estancia_max = []
    state.seasonsContrateHotel.forEach(() => {
      estancia_max.push({ days: 99 })
    })
    state.estaciaMaxRoomsSeasonContrateHotel.push({
      rooms_id: [],
      estancia_max,
    })
  },
  updateEstanciaMaxRoomSeasonContrateHotel(state, update) {
    state.estaciaMaxRoomsSeasonContrateHotel = update
  },
  deleteEstanciaMaxRoomSeasonContrateHotel(state, pos) {
    state.estaciaMaxRoomsSeasonContrateHotel.splice(pos, 1)
  },
  addSuplementsReduccTarifasSeasonContrateHotel(state) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })
    state.suplementsReduccTarifasSeasonContrateHotel.push({
      sup_red_id: [],
      rooms: [
        {
          rooms_id: [],
          in_pc: false,
          is_main: false,
          prices,
        },
      ],
      is_tarifa: false,
      is_suplement: false,
      is_reduction: false,
      base: 'adl_in_dbl',
      applyBy: 'pppn',
    })
  },
  setSuplementsReduccTarifasOnSeasonContrateHotel(state, pos) {
    const rooms = []
    state.roomsSeasonContrateHotel.forEach(element => {
      element.rooms_id.forEach(elementRoom => {
        const prices = []
        state.seasonsContrateHotel.forEach(() => {
          prices.push({ price: null })
        })
        rooms.push({
          rooms_id: [elementRoom],
          in_pc: false,
          is_main: true,
          prices,
        })
      })
    })
    state.suplementsReduccTarifasSeasonContrateHotel[pos] = {
      sup_red_id: state.suplementsReduccTarifasSeasonContrateHotel[pos].sup_red_id,
      is_tarifa: state.suplementsReduccTarifasSeasonContrateHotel[pos].is_tarifa,
      is_suplement: state.suplementsReduccTarifasSeasonContrateHotel[pos].is_suplement,
      is_reduction: state.suplementsReduccTarifasSeasonContrateHotel[pos].is_reduction,
      base: state.suplementsReduccTarifasSeasonContrateHotel[pos].base,
      applyBy: state.suplementsReduccTarifasSeasonContrateHotel[pos].applyBy,
      rooms,
    }
  },
  setSuplementsReduccTarifasOffSeasonContrateHotel(state, pos) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })

    state.suplementsReduccTarifasSeasonContrateHotel[pos] = {
      sup_red_id: state.suplementsReduccTarifasSeasonContrateHotel[pos].sup_red_id,
      is_tarifa: state.suplementsReduccTarifasSeasonContrateHotel[pos].is_tarifa,
      is_suplement: state.suplementsReduccTarifasSeasonContrateHotel[pos].is_suplement,
      is_reduction: state.suplementsReduccTarifasSeasonContrateHotel[pos].is_reduction,
      base: state.suplementsReduccTarifasSeasonContrateHotel[pos].base,
      applyBy: state.suplementsReduccTarifasSeasonContrateHotel[pos].applyBy,
      rooms: [
        {
          rooms_id: [],
          in_pc: false,
          is_main: false,
          prices,
        },
      ],
    }
  },
  setTarifaSuplementsReduccTarifasSeasonContrateHotel(state, pos) {
    state.suplementsReduccTarifasSeasonContrateHotel[pos].rooms.forEach(roomS => {
      roomS.in_pc = false
    })

    // state.suplementsReduccTarifasSeasonContrateHotel[pos].is_suplement = false
    // state.suplementsReduccTarifasSeasonContrateHotel[pos].is_reduction = false
  },
  updateSuplementsReduccTarifasSeasonContrateHotel(state, update) {
    state.suplementsReduccTarifasSeasonContrateHotel = update
  },
  deleteSuplementsReduccTarifasSeasonContrateHotel(state, pos) {
    state.suplementsReduccTarifasSeasonContrateHotel.splice(pos, 1)
  },
  addSuplementsAlimenticiosSeasonContrateHotel(state) {
    const pricesAdl = []
    state.seasonsContrateHotel.forEach(() => {
      pricesAdl.push({ price: null })
    })

    const pricesTeen = []
    state.seasonsContrateHotel.forEach(() => {
      pricesTeen.push({ price: null })
    })

    const pricesMnr = []
    state.seasonsContrateHotel.forEach(() => {
      pricesMnr.push({ price: null })
    })

    const pricesInf = []
    state.seasonsContrateHotel.forEach(() => {
      pricesInf.push({ price: null })
    })
    state.suplementsAlimenticiosSeasonContrateHotel.push({
      suplement_id: null,
      adl: {
        prices: pricesAdl,
        in_pc: false,
      },
      teen: {
        prices: pricesTeen,
        in_pc: false,
      },
      mnr: {
        prices: pricesMnr,
        in_pc: false,
      },
      inf: {
        prices: pricesInf,
        in_pc: false,
      },
      is_tarifa: false,
      is_optional: false,
      applyBy: 'pppn',
      incluye: null,
    })
  },
  updateSuplementsAlimenticiosSeasonContrateHotel(state, update) {
    state.suplementsAlimenticiosSeasonContrateHotel = update
  },
  deleteSuplementsAlimenticiosSeasonContrateHotel(state, pos) {
    state.suplementsAlimenticiosSeasonContrateHotel.splice(pos, 1)
  },
  replicaSuplementsAlimenticiosSeasonContrateHotel(state, { pos, posSeason, price }) {
    for (let index = 0; index < state.roomsSeasonContrateHotel.length; index++) {
      const element = state.roomsSeasonContrateHotel[index]

      if (element.has_a_teen) {
        state.suplementsAlimenticiosSeasonContrateHotel[pos].teen.prices[posSeason].price = price
      }
      if (element.has_a_mnr) {
        state.suplementsAlimenticiosSeasonContrateHotel[pos].mnr.prices[posSeason].price = price
      }
      if (element.has_a_inf) {
        state.suplementsAlimenticiosSeasonContrateHotel[pos].inf.prices[posSeason].price = price
      }
    }
  },
  replicaPricePoliticaChildSeasonContrateHotel(state, { posPolitica, posMnr, price }) {
    for (
      let index = 0;
      index < state.politicasChildSeasonContrateHotel[posPolitica].mnrs[posMnr].prices.length;
      index++
    ) {
      state.politicasChildSeasonContrateHotel[posPolitica].mnrs[posMnr].prices[index].price = price
    }
  },
  replicaPriceSuplementTarifaSeasonContrateHotel(state, { posSuplement, posRoom, price }) {
    for (
      let index = 0;
      index < state.suplementsReduccTarifasSeasonContrateHotel[posSuplement].rooms[posRoom].prices.length;
      index++
    ) {
      state.suplementsReduccTarifasSeasonContrateHotel[posSuplement].rooms[posRoom].prices[index].price = price
    }
  },
  editReleaseByCupoSeasonContrateHotel(state, { posSeason, valor }) {
    for (let index = 0; index < state.releaseRoomsSeasonContrateHotel.length; index++) {
      state.releaseRoomsSeasonContrateHotel[index].release[posSeason].days = valor
    }
  },
  addSuplementsOtrosSeasonContrateHotel(state) {
    const pricesAdl = []
    state.seasonsContrateHotel.forEach(() => {
      pricesAdl.push({ price: null })
    })
    const pricesTeen = []
    state.seasonsContrateHotel.forEach(() => {
      pricesTeen.push({ price: null })
    })
    const pricesMnr = []
    state.seasonsContrateHotel.forEach(() => {
      pricesMnr.push({ price: null })
    })
    const pricesInf = []
    state.seasonsContrateHotel.forEach(() => {
      pricesInf.push({ price: null })
    })

    const pricesRoom = []
    state.seasonsContrateHotel.forEach(() => {
      pricesRoom.push({ price: null })
    })
    state.suplementsOtrosSeasonContrateHotel.push({
      suplement_id: null,
      suplement_slug: null,
      suplement_type: null,
      adl: {
        prices: pricesAdl,
        in_pc: false,
      },
      teen: {
        prices: pricesTeen,
        in_pc: false,
      },
      mnr: {
        prices: pricesMnr,
        in_pc: false,
      },
      inf: {
        prices: pricesInf,
        in_pc: false,
      },
      is_tarifa: false,
      is_optional: false,
      applyBy: 'ppp',

      // CHECK-IN/OUT
      rooms: [
        {
          rooms_id: [],
          in_pc: false,
          prices: pricesRoom,
        },
      ],
      check_in: null,
      check_out: null,

      // SEASON PICO
      season_from: null,
      season_to: null,
      season_rooms: [
        {
          rooms_id: [],
          in_pc: false,
          price_adl: null,
          price_teen: null,
          price_mnr: null,
          price_inf: null,
        },
      ],

      // CENAS
      cena_dates: [],
      cena_rooms: [
        {
          rooms_id: [],
          plan_id: null,
          in_pc: false,
          price_adl: null,
          price_teen: null,
          price_mnr: null,
          price_inf: null,
        },
      ],
    })
  },
  updateSuplementsOtrosSeasonContrateHotel(state, update) {
    state.suplementsOtrosSeasonContrateHotel = update
  },
  deleteSuplementsOtrosSeasonContrateHotel(state, pos) {
    state.suplementsOtrosSeasonContrateHotel.splice(pos, 1)
  },
  replicaSuplementsOtrosRoomSeasonContrateHotel(state, { posSuplement, posRoom, price }) {
    for (let j = 1; j < state.suplementsOtrosSeasonContrateHotel[posSuplement].rooms[posRoom].prices.length; j++) {
      state.suplementsOtrosSeasonContrateHotel[posSuplement].rooms[posRoom].prices[j].price = price
    }
  },
  replicaSuplementsOtrosSeasonContrateHotel(state, { pos, person, price }) {
    if (person === 'adl') {
      for (let j = 1; j < state.suplementsOtrosSeasonContrateHotel[pos].adl.prices.length; j++) {
        state.suplementsOtrosSeasonContrateHotel[pos].adl.prices[j].price = price
      }
    } else if (person === 'teen') {
      for (let j = 1; j < state.suplementsOtrosSeasonContrateHotel[pos].teen.prices.length; j++) {
        state.suplementsOtrosSeasonContrateHotel[pos].teen.prices[j].price = price
      }
    } else if (person === 'mnr') {
      for (let j = 1; j < state.suplementsOtrosSeasonContrateHotel[pos].mnr.prices.length; j++) {
        state.suplementsOtrosSeasonContrateHotel[pos].mnr.prices[j].price = price
      }
    } else if (person === 'inf') {
      for (let j = 1; j < state.suplementsOtrosSeasonContrateHotel[pos].inf.prices.length; j++) {
        state.suplementsOtrosSeasonContrateHotel[pos].inf.prices[j].price = price
      }
    }
  },
  addRoomSuplementsOtrosSeasonContrateHotel(state, pos) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })
    state.suplementsOtrosSeasonContrateHotel[pos].rooms.push({
      rooms_id: [],
      in_pc: false,
      prices,
    })
  },
  deleteRoomSuplementsOtrosSeasonContrateHotel(state, { posSup, posRoom }) {
    state.suplementsOtrosSeasonContrateHotel[posSup].rooms.splice(posRoom, 1)
  },
  addRoomSuplementsOtrosSeasonPicoSeasonContrateHotel(state, pos) {
    state.suplementsOtrosSeasonContrateHotel[pos].season_rooms.push({
      rooms_id: [],
      in_pc: false,
      price_adl: null,
      price_teen: null,
      price_mnr: null,
      price_inf: null,
    })
  },
  deleteRoomSuplementsOtrosSeasonPicoSeasonContrateHotel(state, { posSup, posRoom }) {
    state.suplementsOtrosSeasonContrateHotel[posSup].season_rooms.splice(posRoom, 1)
  },
  replicaSuplementsOtrosSeasonPicoSeasonContrateHotel(state, {
    posSuplement, posSeason, posRoom, price,
  }) {
    if (posSeason === -1) {
      state.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          state.suplementsOtrosSeasonContrateHotel[posSuplement].season_rooms[posRoom].price_teen = price
        }
        if (element.has_a_mnr) {
          state.suplementsOtrosSeasonContrateHotel[posSuplement].season_rooms[posRoom].price_mnr = price
        }
        if (element.has_a_inf) {
          state.suplementsOtrosSeasonContrateHotel[posSuplement].season_rooms[posRoom].price_inf = price
        }
      })
    } else {
      if (state.roomsSeasonContrateHotel[posSeason].has_a_teen) {
        state.suplementsOtrosSeasonContrateHotel[posSuplement].season_rooms[posRoom].price_teen = price
      }
      if (state.roomsSeasonContrateHotel[posSeason].has_a_mnr) {
        state.suplementsOtrosSeasonContrateHotel[posSuplement].season_rooms[posRoom].price_mnr = price
      }
      if (state.roomsSeasonContrateHotel[posSeason].has_a_inf) {
        state.suplementsOtrosSeasonContrateHotel[posSuplement].season_rooms[posRoom].price_inf = price
      }
    }
  },
  addRoomSuplementsOtrosCenaSeasonContrateHotel(state, pos) {
    state.suplementsOtrosSeasonContrateHotel[pos].cena_rooms.push({
      rooms_id: [],
      plan_id: null,
      in_pc: false,
      price_adl: null,
      price_teen: null,
      price_mnr: null,
      price_inf: null,
    })
  },
  deleteRoomSuplementsOtrosCenaSeasonContrateHotel(state, { posSup, posRoom }) {
    state.suplementsOtrosSeasonContrateHotel[posSup].cena_rooms.splice(posRoom, 1)
  },
  replicaSuplementsOtrosCenaSeasonContrateHotel(state, {
    posSuplement, posSeason, posRoom, price,
  }) {
    if (posSeason === -1) {
      state.roomsSeasonContrateHotel.forEach(element => {
        if (element.has_a_teen) {
          state.suplementsOtrosSeasonContrateHotel[posSuplement].cena_rooms[posRoom].price_teen = price
        }
        if (element.has_a_mnr) {
          state.suplementsOtrosSeasonContrateHotel[posSuplement].cena_rooms[posRoom].price_mnr = price
        }
        if (element.has_a_inf) {
          state.suplementsOtrosSeasonContrateHotel[posSuplement].cena_rooms[posRoom].price_inf = price
        }
      })
    } else {
      if (state.roomsSeasonContrateHotel[posSeason].has_a_teen) {
        state.suplementsOtrosSeasonContrateHotel[posSuplement].cena_rooms[posRoom].price_teen = price
      }
      if (state.roomsSeasonContrateHotel[posSeason].has_a_mnr) {
        state.suplementsOtrosSeasonContrateHotel[posSuplement].cena_rooms[posRoom].price_mnr = price
      }
      if (state.roomsSeasonContrateHotel[posSeason].has_a_inf) {
        state.suplementsOtrosSeasonContrateHotel[posSuplement].cena_rooms[posRoom].price_inf = price
      }
    }
  },
  addRoomSuplementsReduccTarifasSeasonContrateHotel(state, pos) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })
    state.suplementsReduccTarifasSeasonContrateHotel[pos].rooms.push({
      rooms_id: [],
      in_pc: false,
      is_main: false,
      prices,
    })
  },
  deleteRoomSuplementsReduccTarifasSeasonContrateHotel(state, { posSup, posRoom }) {
    state.suplementsReduccTarifasSeasonContrateHotel[posSup].rooms.splice(posRoom, 1)
  },
  replicaCupoSeasonContrateHotel(state, { posRoom, cupo }) {
    for (let index = 0; index < state.cuposRoomsSeasonContrateHotel[posRoom].cupos.length; index++) {
      state.cuposRoomsSeasonContrateHotel[posRoom].cupos[index].cupo = cupo
    }
  },
  replicaReleaseSeasonContrateHotel(state, { posRoom, days }) {
    for (let index = 0; index < state.releaseRoomsSeasonContrateHotel[posRoom].release.length; index++) {
      state.releaseRoomsSeasonContrateHotel[posRoom].release[index].days = days
    }
  },
  replicaPriceNoMainSeasonContrateHotel(state, { posRoom, price }) {
    for (let index = 0; index < state.pricesDBLRoomsSeasonContrateHotel[posRoom].prices.length; index++) {
      state.pricesDBLRoomsSeasonContrateHotel[posRoom].prices[index].price = price
    }
  },
  replicaEstanciaMinSeasonContrateHotel(state, { posRoom, days }) {
    for (let index = 0; index < state.estaciaMinRoomsSeasonContrateHotel[posRoom].estancia_min.length; index++) {
      state.estaciaMinRoomsSeasonContrateHotel[posRoom].estancia_min[index].days = days
    }
  },
  replicaEstanciaMaxSeasonContrateHotel(state, { posRoom, days }) {
    for (let index = 0; index < state.estaciaMaxRoomsSeasonContrateHotel[posRoom].estancia_max.length; index++) {
      state.estaciaMaxRoomsSeasonContrateHotel[posRoom].estancia_max[index].days = days
    }
  },
  addOcupationRoomSeasonContrateHotel(state, posRoom) {
    state.roomsSeasonContrateHotel[posRoom].ocupations.push({
      pax: null,
      adult: null,
      child: null,
    })
  },
  deleteOcupationRoomSeasonContrateHotel(state, { posRoom, posOcupation }) {
    state.roomsSeasonContrateHotel[posRoom].ocupations.splice(posOcupation, 1)
  },
  replicaPlanRoomSeasonContrateHotel(state, { pos, plan_id }) {
    // eslint-disable-next-line no-plusplus
    for (let index = pos + 1; index < state.roomsSeasonContrateHotel.length; index++) {
      state.roomsSeasonContrateHotel[index].plan_id = plan_id
    }
  },
  replicaCupoRoomSeasonContrateHotel(state, { posCupo, cupo }) {
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < state.roomsSeasonContrateHotel.length; index++) {
      for (let j = 0; j < state.roomsSeasonContrateHotel[index].cupos.length; j++) {
        state.roomsSeasonContrateHotel[index].cupos[posCupo].cupo = cupo
      }
    }
  },
  replicaReleaseRoomSeasonContrateHotel(state, { posRelease, days }) {
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < state.roomsSeasonContrateHotel.length; index++) {
      for (let j = 0; j < state.roomsSeasonContrateHotel[index].cupos.length; j++) {
        state.roomsSeasonContrateHotel[index].release[posRelease].days = days
      }
    }
  },
  replicaEstanciaMinRoomSeasonContrateHotel(state, { posEstanciaMin, days }) {
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < state.roomsSeasonContrateHotel.length; index++) {
      for (let j = 0; j < state.roomsSeasonContrateHotel[index].cupos.length; j++) {
        state.roomsSeasonContrateHotel[index].estancia_min[posEstanciaMin].days = days
      }
    }
  },
  addMaxOcupationRoomsSeasonContrateHotel(state) {
    state.maxOcupationRoomsSeasonContrateHotel.push({
      rooms_id: [],
      ocupations: {
        paxMax: 3,
        paxMin: 0,
        adultMax: 3,
        adultMin: 0,
        teenMax: 0,
        teenMin: 0,
        childMax: 0,
        childMin: 0,
        infMax: 0,
        infMin: 0,
        childMoreInf: 0,
        infOcupaPlace: false,
        infPlace: 'N/A',
      },
      ocupaciones: [],
    })
  },
  updateRangeAgeRoomsSeasonContrateHotel(state, update) {
    state.maxOcupationRoomsSeasonContrateHotel = update
  },
  deleteRangeAgeRoomsSeasonContrateHotel(state, pos) {
    state.maxOcupationRoomsSeasonContrateHotel.splice(pos, 1)
  },
  updateOcupationRoomsSeasonContrateHotel(state, update) {
    state.ocupationRoomsSeasonContrateHotel = update
  },

  // end contrate hotel
  //* ****************************************************************************/

  addSuplementSeasonContrateHotel(state) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })

    state.suplementsSeasonContrateHotel = [
      {
        suplement_id: null,
        name: null,
        value: null,
        in_pc: false,
        required: false,
        rooms_id: [],
        prices,
      },
      ...state.suplementsSeasonContrateHotel,
    ]

    /* state.suplementsSeasonContrateHotel.push({
      suplement_id: null,
      name: null,
      value: null,
      in_pc: false,
      required: false,
      rooms_id: [],
      prices,
    }) */
  },
  deleteSuplementSeasonContrateHotel(state, pos) {
    state.suplementsSeasonContrateHotel.splice(pos, 1)
  },
  updateSuplementSeasonContrateHotel(state, update) {
    state.suplementsSeasonContrateHotel = update
  },
  addReduccionSeasonContrateHotel(state) {
    const prices = []
    state.seasonsContrateHotel.forEach(() => {
      prices.push({ price: null })
    })

    state.reduccionsSeasonContrateHotel = [
      {
        reduccion_id: null,
        name: null,
        value: null,
        in_pc: false,
        required: false,
        rooms_id: [],
        prices,
      },
      ...state.reduccionsSeasonContrateHotel,
    ]

    /* state.reduccionsSeasonContrateHotel.push({
      reduccion_id: null,
      name: null,
      value: null,
      in_pc: false,
      required: false,
      rooms_id: [],
      prices,
    }) */
  },
  deleteReduccionSeasonContrateHotel(state, pos) {
    state.reduccionsSeasonContrateHotel.splice(pos, 1)
  },
  updateReduccionSeasonContrateHotel(state, update) {
    state.reduccionsSeasonContrateHotel = update
  },
  updateRangeAgeContrateHotel(state, update) {
    state.rangeAgeContrateHotel = update
  },
  addOfertaSeasonContrateHotel(state) {
    state.ofertasSeasonContrateHotel.push({
      name: null,
      identificador: null,
      fromViaje: null,
      toViaje: null,
      fromReserva: null,
      toReserva: null,
      rooming: null,
      pays: [
        {
          fromPagar: null,
          daysPagar: null,
          valuePagar: null,
          in_pc_pagar: true,
        },
      ],
      datePublic: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdate: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdateOld: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdateSaved: null,
      dateCancele: null,
      stopSale: false,
      dateStopSale: null,
      modelo: 'descuento',
      calculadoPor: null,
      by_suplement_reductions: false,
      price_by_room: false,
      descuentos: [
        {
          valueDescuento: null,
          in_value_descuento: false,
          rooms_id: [],
        },
      ],
      rooms_by_price: [
        {
          room_id: null,
          price_doble: null,
          price_simple: null,
          price_triple: null,
          price_first_child: null,
          price_second_child: null,
          price_infant: null,
          beneficios: null,
        },
      ],
      rooms_by_price_suplement: [
        {
          room_id: null,
          price_doble: null,
          suplement: [
            {
              suplement_id: null,
              valueSuplement: null,
              in_value_suplement: false,
            },
          ],
          reduction: [
            {
              reduction_id: null,
              valueReduction: null,
              in_value_reduction: false,
            },
          ],
          beneficios: null,
        },
      ],
    })
  },
  deleteOfertaSeasonContrateHotel(state, pos) {
    state.ofertasSeasonContrateHotel.splice(pos, 1)
  },
  updateOfertaSeasonContrateHotel(state, update) {
    state.ofertasSeasonContrateHotel = update
  },
  addOfertaSpecialSeasonContrateHotel(state) {
    state.ofertasSpecialSeasonContrateHotel.push({
      name: null,
      identificador: null,
      fromViaje: null,
      toViaje: null,
      fromReserva: null,
      toReserva: null,
      rooming: null,
      pays: [
        {
          fromPagar: null,
          daysPagar: null,
          valuePagar: null,
          in_pc_pagar: true,
        },
      ],
      datePublic: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdate: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdateOld: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdateSaved: null,
      dateCancele: null,
      stopSale: false,
      dateStopSale: null,
      modelo: 'descuento',
      calculadoPor: null,
      by_suplement_reductions: false,
      price_by_room: false,
      descuentos: [
        {
          valueDescuento: null,
          in_value_descuento: false,
          rooms_id: [],
        },
      ],
      rooms_by_price: [
        {
          room_id: null,
          price_doble: null,
          price_simple: null,
          price_triple: null,
          price_first_child: null,
          price_second_child: null,
          price_infant: null,
          beneficios: null,
        },
      ],
      rooms_by_price_suplement: [
        {
          room_id: null,
          price_doble: null,
          suplement: [
            {
              suplement_id: null,
              valueSuplement: null,
              in_value_suplement: false,
            },
          ],
          reduction: [
            {
              reduction_id: null,
              valueReduction: null,
              in_value_reduction: false,
            },
          ],
          beneficios: null,
        },
      ],
    })
  },
  confirmDateUpdateStopSaleOfertaSeasonContrateHotel(state, { pos, date }) {
    state.ofertasSeasonContrateHotel[pos].dateUpdateSaved = state.ofertasSeasonContrateHotel[pos].dateUpdateOld
    state.ofertasSeasonContrateHotel[pos].dateUpdateOld = date
  },
  confirmDateUpdateStopSaleOfertaSpecialSeasonContrateHotel(state, { pos, date }) {
    state.ofertasSpecialSeasonContrateHotel[pos].dateUpdateSaved = state.ofertasSpecialSeasonContrateHotel[pos].dateUpdateOld
    state.ofertasSpecialSeasonContrateHotel[pos].dateUpdateOld = date
  },
  cancelDateUpdateStopSaleOfertaSeasonContrateHotel(state, pos) {
    state.ofertasSeasonContrateHotel[pos].dateUpdateOld = state.ofertasSeasonContrateHotel[pos].dateUpdateSaved
    state.ofertasSeasonContrateHotel[pos].dateUpdateSaved = null
  },
  cancelDateUpdateStopSaleOfertaSpecialSeasonContrateHotel(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].dateUpdateOld = state.ofertasSpecialSeasonContrateHotel[pos].dateUpdateSaved
    state.ofertasSpecialSeasonContrateHotel[pos].dateUpdateSaved = null
  },
  updateStopSaleOfertaSeasonContrateHotel(state, { pos, date }) {
    state.ofertasSeasonContrateHotel[pos].dateCancele = date
  },
  updateStopSaleOfertaSpecialSeasonContrateHotel(state, { pos, date }) {
    state.ofertasSpecialSeasonContrateHotel[pos].dateCancele = date
  },
  emptyDescuentosOfertaSeasonContrateHotel(state, pos) {
    state.ofertasSeasonContrateHotel[pos].descuentos = [
      {
        valueDescuento: null,
        in_value_descuento: false,
        rooms_id: [],
      },
    ]
  },
  emptyDescuentosOfertaSpecialSeasonContrateHotel(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].descuentos = [
      {
        valueDescuento: null,
        in_value_descuento: false,
        rooms_id: [],
      },
    ]
  },
  emptyPricesOfertaSeasonContrateHotel(state, pos) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price = [
      {
        room_id: null,
        price_doble: null,
        price_simple: null,
        price_triple: null,
        price_first_child: null,
        price_second_child: null,
        price_infant: null,
        beneficios: null,
      },
    ]
  },
  emptyPricesOfertaSpecialSeasonContrateHotel(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price = [
      {
        room_id: null,
        price_doble: null,
        price_simple: null,
        price_triple: null,
        price_first_child: null,
        price_second_child: null,
        price_infant: null,
        beneficios: null,
      },
    ]
  },
  addOfertaSeasonContrateHotelDescuento(state, pos) {
    state.ofertasSeasonContrateHotel[pos].descuentos.push({
      valueDescuento: null,
      in_value_descuento: false,
      rooms_id: [],
    })
  },
  addOfertaSpecialSeasonContrateHotelDescuento(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].descuentos.push({
      valueDescuento: null,
      in_value_descuento: false,
      rooms_id: [],
    })
  },
  deleteOfertaSeasonContrateHotelDescuento(state, { pos, posDesc }) {
    state.ofertasSeasonContrateHotel[pos].descuentos.splice(posDesc, 1)
  },
  deleteOfertaSpecialSeasonContrateHotelDescuento(state, { pos, posDesc }) {
    state.ofertasSpecialSeasonContrateHotel[pos].descuentos.splice(posDesc, 1)
  },
  deleteOfertaSeasonContrateHotelPay(state, { pos, posPay }) {
    state.ofertasSeasonContrateHotel[pos].pays.splice(posPay, 1)
  },
  deleteOfertaSpecialSeasonContrateHotelPay(state, { pos, posPay }) {
    state.ofertasSpecialSeasonContrateHotel[pos].pays.splice(posPay, 1)
  },
  addOfertaSeasonContrateHotelPay(state, pos) {
    state.ofertasSeasonContrateHotel[pos].pays.push({
      fromPagar: null,
      valuePagar: null,
      in_pc_pagar: true,
    })
  },
  addOfertaSpecialSeasonContrateHotelPay(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].pays.push({
      fromPagar: null,
      valuePagar: null,
      in_pc_pagar: true,
    })
  },
  addOfertaSeasonContrateHotelPrice(state, pos) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price.push({
      room_id: null,
      price_doble: null,
      price_simple: null,
      price_triple: null,
      price_first_child: null,
      price_second_child: null,
      price_infant: null,
      beneficios: null,
    })
  },
  addOfertaSpecialSeasonContrateHotelPrice(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price.push({
      room_id: null,
      price_doble: null,
      price_simple: null,
      price_triple: null,
      price_first_child: null,
      price_second_child: null,
      price_infant: null,
      beneficios: null,
    })
  },
  emptyPricesBySuplementOfertaSeasonContrateHotel(state, pos) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement = [
      {
        room_id: null,
        price_doble: null,
        suplement: [
          {
            suplement_id: null,
            valueSuplement: null,
            in_value_suplement: false,
          },
        ],
        reduction: [
          {
            reduction_id: null,
            valueReduction: null,
            in_value_reduction: false,
          },
        ],
        beneficios: null,
      },
    ]
  },
  emptyPricesBySuplementOfertaSpecialSeasonContrateHotel(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement = [
      {
        room_id: null,
        price_doble: null,
        suplement: [
          {
            suplement_id: null,
            valueSuplement: null,
            in_value_suplement: false,
          },
        ],
        reduction: [
          {
            reduction_id: null,
            valueReduction: null,
            in_value_reduction: false,
          },
        ],
        beneficios: null,
      },
    ]
  },
  addPricesBySuplementOfertaSeasonContrateHotel(state, pos) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement.push({
      room_id: null,
      price_doble: null,
      suplement: [
        {
          suplement_id: null,
          valueSuplement: null,
          in_value_suplement: false,
        },
      ],
      reduction: [
        {
          reduction_id: null,
          valueReduction: null,
          in_value_reduction: false,
        },
      ],
      beneficios: null,
    })
  },
  addPricesBySuplementOfertaSpecialSeasonContrateHotel(state, pos) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement.push({
      room_id: null,
      price_doble: null,
      suplement: [
        {
          suplement_id: null,
          valueSuplement: null,
          in_value_suplement: false,
        },
      ],
      reduction: [
        {
          reduction_id: null,
          valueReduction: null,
          in_value_reduction: false,
        },
      ],
      beneficios: null,
    })
  },
  deletePricesBySuplementOfertaSeasonContrateHotel(state, { pos, posPrice }) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement.splice(posPrice, 1)
  },
  deletePricesBySuplementOfertaSpecialSeasonContrateHotel(state, { pos, posPrice }) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement.splice(posPrice, 1)
  },
  deletePricesBySuplementOfertaSeasonContrateHotelSuplement(state, { pos, posPrice, posSuplement }) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].suplement.splice(posSuplement, 1)
  },
  deletePricesBySuplementOfertaSpecialSeasonContrateHotelSuplement(state, { pos, posPrice, posSuplement }) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].suplement.splice(posSuplement, 1)
  },
  addPricesBySuplementOfertaSeasonContrateHotelSuplement(state, { pos, posPrice }) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].suplement.push({
      suplement_id: null,
      valueSuplement: null,
      in_value_suplement: false,
    })
  },
  addPricesBySuplementOfertaSpecialSeasonContrateHotelSuplement(state, { pos, posPrice }) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].suplement.push({
      suplement_id: null,
      valueSuplement: null,
      in_value_suplement: false,
    })
  },
  deletePricesBySuplementOfertaSeasonContrateHotelReduction(state, { pos, posPrice, posReduction }) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].reduction.splice(posReduction, 1)
  },
  deletePricesBySuplementOfertaSpecialSeasonContrateHotelReduction(state, { pos, posPrice, posReduction }) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].reduction.splice(posReduction, 1)
  },
  addPricesBySuplementOfertaSeasonContrateHotelReduction(state, { pos, posPrice }) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].reduction.push({
      reduction_id: null,
      valueReduction: null,
      in_value_reduction: false,
    })
  },
  addPricesBySuplementOfertaSpecialSeasonContrateHotelReduction(state, { pos, posPrice }) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price_suplement[posPrice].reduction.push({
      reduction_id: null,
      valueReduction: null,
      in_value_reduction: false,
    })
  },
  deleteOfertaSeasonContrateHotelPrice(state, { pos, posPrice }) {
    state.ofertasSeasonContrateHotel[pos].rooms_by_price.splice(posPrice, 1)
  },
  deleteOfertaSpecialSeasonContrateHotelPrice(state, { pos, posPrice }) {
    state.ofertasSpecialSeasonContrateHotel[pos].rooms_by_price.splice(posPrice, 1)
  },
  deleteOfertaSpecialSeasonContrateHotel(state, pos) {
    state.ofertasSpecialSeasonContrateHotel.splice(pos, 1)
  },
  updateOfertaSpecialSeasonContrateHotel(state, update) {
    state.ofertasSpecialSeasonContrateHotel = update
  },

  //* ***********************************************************************************/

  setShowRoomHotelCotizador: (state, status) => {
    state.showOcupationRoomHotel = status
  },
  addOcupationRoomHotelCotizador: state => {
    state.cotizadorHotels.ocupation.push({
      cant: 1,
      adults: 2,
      childs: 0,
      edades: [],
      room_id: null,
      tipoCama: [],
      tarifasSeason: [],
      codeTarifa: null,
      plan: null,
      uso: null,
      paxs: [{
        principal: false,
        name: null,
        apellidos: null,
        noDocument: null,
      }],
      showAllPaxs: false,
      priceNeto: null,
      priceNetoCurrency: null,
      price: null,
      priceTotal: null,
      priceWithMarkups: null,
      priceWithDescuento: null,
      cantAdulEdad: 2,
      cantTeenEdad: 0,
      cantMnrEdad: 0,
      cantInfEdad: 0,
    })
  },
  setOcupationHotelShowAll: (state, { pos, value }) => {
    const { cotizadorHotels } = state

    cotizadorHotels.ocupation[pos].showAllPaxs = value
  },
  updateOcupationHotel: (
    state,
    {
      pos,
      paxs,
      room_id,
      tipoCama,
      tarifasSeason,
      plan,
      codeTarifa,
      uso,
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
      cantAdulEdad,
      cantTeenEdad,
      cantMnrEdad,
      cantInfEdad,
    },
  ) => {
    const { cotizadorHotels, cotizadorHotelsResultSelect, cotizadorHotelsRomms } = state

    cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      paxs,
      cantAdulEdad,
      cantTeenEdad,
      cantMnrEdad,
      cantInfEdad,
      room_id,
      tipoCama,
      tarifasSeason,
      plan,
      codeTarifa,
      uso,
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    }

    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      paxs,
      cantAdulEdad,
      cantTeenEdad,
      cantMnrEdad,
      cantInfEdad,
      room_id,
      tipoCama,
      tarifasSeason,
      plan,
      codeTarifa,
      uso,
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    }

    cotizadorHotelsRomms[pos] = {
      ...cotizadorHotelsRomms[pos],
      room_id,
      plan,
      codeTarifa,
      tipoCama,
      tarifasSeason,
      uso,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    }
  },
  updateOcupationHotelOnlyRoomId: (
    state,
    {
      pos,
      room_id,
    },
  ) => {
    const { cotizadorHotels, cotizadorHotelsResultSelect, cotizadorHotelsRomms } = state

    cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      room_id,
    }

    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      room_id,
    }

    cotizadorHotelsRomms[pos] = {
      ...cotizadorHotelsRomms[pos],
      room_id,
    }
  },
  updateOcupationHotelOnlyPlan: (
    state,
    {
      pos,
      plan,
    },
  ) => {
    const { cotizadorHotels, cotizadorHotelsResultSelect, cotizadorHotelsRomms } = state

    cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      plan,
    }

    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      plan,
    }

    cotizadorHotelsRomms[pos] = {
      ...cotizadorHotelsRomms[pos],
      plan,
    }
  },
  updateOcupationHotelPriceNeto: (
    state,
    {
      pos,
      priceNeto,
    },
  ) => {
    const {
      cotizadorHotels,
      cotizadorHotelsResultSelect,
      cotizadorHotelsRomms,
    } = state

    cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      priceNeto,
    }

    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      priceNeto,
    }

    cotizadorHotelsRomms[pos].priceNeto = priceNeto
  },
  updateOcupationHotelPricesFacturar: (
    state,
    {
      pos,
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    },
  ) => {
    const {
      cotizadorHotels,
      cotizadorHotelsResultSelect,
      cotizadorHotelsRomms,
    } = state

    cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    }

    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    }

    cotizadorHotelsRomms[pos].priceNeto = priceNeto
    cotizadorHotelsRomms[pos].priceTotal = priceTotal
    cotizadorHotelsRomms[pos].priceWithMarkups = priceWithMarkups
    cotizadorHotelsRomms[pos].priceWithDescuento = priceWithDescuento
  },
  updateOcupationHotelPricesChangePlan: (
    state,
    {
      pos,
      priceNeto,
      plan,
      codeTarifa,

      // priceTotal,
      // priceWithMarkups,
      // priceWithDescuento,
    },
  ) => {
    const {
      // cotizadorHotels,
      cotizadorHotelsResultSelect,
      cotizadorHotelsRomms,
    } = state

    /* cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    }

    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      priceNeto,
      priceTotal,
      priceWithMarkups,
      priceWithDescuento,
    } */

    if (pos > cotizadorHotelsRomms.length - 1) {
      cotizadorHotelsRomms[pos - 1].priceNeto = priceNeto
      cotizadorHotelsRomms[pos - 1].plan = plan
      cotizadorHotelsRomms[pos - 1].codeTarifa = codeTarifa
    } else {
      cotizadorHotelsRomms[pos].priceNeto = priceNeto
      cotizadorHotelsRomms[pos].plan = plan
      cotizadorHotelsRomms[pos].codeTarifa = codeTarifa
    }

    cotizadorHotelsResultSelect.rooms[pos].plan = plan
    cotizadorHotelsResultSelect.rooms[pos].codeTarifa = codeTarifa

    // cotizadorHotelsRomms[pos].priceTotal = priceTotal
    // cotizadorHotelsRomms[pos].priceWithMarkups = priceWithMarkups
    // cotizadorHotelsRomms[pos].priceWithDescuento = priceWithDescuento
  },
  updateOcupationCantByEdadHotel: (
    state,
    {
      ind,
      cantAdulEdad,
      cantTeenEdad,
      cantMnrEdad,
      cantInfEdad,
    },
  ) => {
    const { cotizadorHotels } = state

    cotizadorHotels.ocupation[ind] = {
      ...cotizadorHotels.ocupation[ind],
      cantAdulEdad,
      cantTeenEdad,
      cantMnrEdad,
      cantInfEdad,
    }
  },
  updatePlanOcupationHotel: (state, {
    pos, room_id, plan, codeTarifa,
  }) => {
    const {
      cotizadorHotels,
      cotizadorHotelsResultSelect,
    } = state

    cotizadorHotels.ocupation[pos] = {
      ...cotizadorHotels.ocupation[pos],
      room_id,
      plan,
      codeTarifa,

      // priceTotal,
      // priceWithMarkups,
      // priceWithDescuento,
    }

    cotizadorHotelsResultSelect.code_tarifa = codeTarifa
    cotizadorHotelsResultSelect.rooms[pos] = {
      ...cotizadorHotelsResultSelect.rooms[pos],
      room_id,
      plan,
      codeTarifa,
    }
  },
  deleteOcupationRoomHotelCotizador: (state, pos) => {
    const { cotizadorHotels } = state

    cotizadorHotels.ocupation.splice(pos, 1)
  },
  setIndexTab: (state, update) => {
    state.indexTab = update
  },
  setShowTab: (state, update) => {
    state.showTab = update
  },
  updateHotelsChange: (state, update) => {
    state.hotelsChanges = update
  },
  setHotelsChange: (state, { pos, item }) => {
    state.hotelsChanges[pos] = item
  },
  setSearchingHotel: (state, valor) => {
    state.searchingHotel = valor
  },
  setCotizadorHotelsResult: (state, items) => {
    state.cotizadorHotelsResult = items
  },
  setCotizadorHotelsResultOrigin: (state, items) => {
    state.cotizadorHotelsResultOrigin = items
  },
  setCotizadorHotelsResultPaginate: (state, items) => {
    state.cotizadorHotelsResultPaginate = items
  },
  addCotizadorHotelsResultPaginate: (state, item) => {
    state.cotizadorHotelsResultPaginate.push(item)
  },
  setCotizadorHotelsResultSelect: (state, items) => {
    state.cotizadorHotelsResultSelect = items
  },
  setCotizadorHotelsSelectMarkups: (state, item) => {
    state.cotizadorHotelsSelectMarkups = item
  },
  setCotizadorHotelSelectPrice: (state, { priceTotal, priceWithMarkup, priceWithDescuento }) => {
    state.cotizadorHotelsResultSelect.priceTotal = priceTotal
    state.cotizadorHotelsResultSelect.priceWithMarkup = priceWithMarkup
    state.cotizadorHotelsResultSelect.priceWithDescuento = priceWithDescuento
  },
  setCotizadorHotelSelectPriceTotal: (state, priceTotal) => {
    state.cotizadorHotelsResultSelect.priceTotal = priceTotal
  },
  setTasaCambioContrate: (state, value) => {
    state.tasaCambioContrate = value
  },
  setCurrencySymbolContrate: (state, value) => {
    state.currencySymbolContrate = value
  },
  setCurrencyCodeContrate: (state, value) => {
    state.currencyCodeContrate = value
  },
  setTasaCambioFacturar: (state, value) => {
    state.tasaCambioFacturar = value
  },
  setCurrencySymbolFacturar: (state, value) => {
    state.currencySymbolFacturar = value
  },
  setCurrencyCodeFacturar: (state, value) => {
    state.currencyCodeFacturar = value
  },
  setCotizadorHotelsRomms: state => {
    const idBy = []
    state.cotizadorHotelsRomms = []

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < state.cotizadorHotels.ocupation.length; i++) {
      let cont = 1
      let price = state.cotizadorHotels.ocupation[i].priceNeto
      const { priceWithMarkups, priceWithDescuento } = state.cotizadorHotels.ocupation[i]

      // eslint-disable-next-line no-plusplus
      for (let j = i + 1; j < state.cotizadorHotels.ocupation.length; j++) {
        if (
          state.cotizadorHotels.ocupation[i].room_id === state.cotizadorHotels.ocupation[j].room_id
          && state.cotizadorHotels.ocupation[i].plan === state.cotizadorHotels.ocupation[j].plan
          && state.cotizadorHotels.ocupation[i].uso === state.cotizadorHotels.ocupation[j].uso
          && state.cotizadorHotels.ocupation[i].cantAdulEdad === state.cotizadorHotels.ocupation[j].cantAdulEdad
          && state.cotizadorHotels.ocupation[i].cantTeenEdad === state.cotizadorHotels.ocupation[j].cantTeenEdad
          && state.cotizadorHotels.ocupation[i].cantMnrEdad === state.cotizadorHotels.ocupation[j].cantMnrEdad
          && state.cotizadorHotels.ocupation[i].cantInfEdad === state.cotizadorHotels.ocupation[j].cantInfEdad
        ) {
          // eslint-disable-next-line no-plusplus
          cont++
          price += state.cotizadorHotels.ocupation[j].priceNeto

          // priceWithDescuento += state.cotizadorHotels.ocupation[j].priceWithDescuento
        }
      }

      const concatena = `${state.cotizadorHotels.ocupation[i].room_id}-${state.cotizadorHotels.ocupation[i].plan}-${state.cotizadorHotels.ocupation[i].uso}-${state.cotizadorHotels.ocupation[i].cantAdulEdad}-${state.cotizadorHotels.ocupation[i].cantTeenEdad}-${state.cotizadorHotels.ocupation[i].cantMnrEdad}-${state.cotizadorHotels.ocupation[i].cantInfEdad}`
      if (!idBy.includes(concatena)) {
        idBy.push(concatena)
        // eslint-disable-next-line no-mixed-operators
        const newPrice = price / state.tasaCambioContrate * state.tasaCambioFacturar
        state.cotizadorHotels.ocupation[i] = {
          ...state.cotizadorHotels.ocupation[i],

          // cant: cont,
          cant: 1,
          price: newPrice,
          priceWithMarkups,
          priceWithDescuento,
        }

        if (state.cotizadorHotels.room) {
          state.cotizadorHotels.room[i] = {
            ...state.cotizadorHotels.room[i],
            price: newPrice,
            priceWithMarkups,
            priceWithDescuento,
          }
        }

        state.cotizadorHotelsRomms.push({
          ...state.cotizadorHotels.ocupation[i],
          cant: cont,
          price: newPrice,
          priceWithMarkups,
          priceWithDescuento,
        })
      }
    }
  },
  updateCotizadorHotelsRomms(state, update) {
    state.cotizadorHotelsRomms = update
  },

  updateDriverAgeCumple: (state, value) => {
    state.driverAgeCumple = value
  },

  // RESERVAS
  updateCommentsGestor: (state, value) => {
    state.comentsGestor = value
  },
  addCommentsGestor: state => {
    state.comentsGestor.push({
      text: null,
    })
  },
  deleteCommentsGestor: (state, pos) => {
    const { comentsGestor } = state

    comentsGestor.splice(pos, 1)
  },
  updateCommentsClient: (state, value) => {
    state.comentsClient = value
  },
  addCommentsClient: state => {
    state.comentsClient.push({
      text: null,
    })
  },
  deleteCommentsClient: (state, pos) => {
    const { comentsClient } = state

    comentsClient.splice(pos, 1)
  },

  // CHATBOT MENSAJES
  adicionarOptionsMessaje: state => {
    const { optionsChatBotMessages } = state
    optionsChatBotMessages.push({ text: null, value: null })
  },
  deleteOptionsMessaje: (state, pos) => {
    const { optionsChatBotMessages } = state

    optionsChatBotMessages.splice(pos, 1)
  },
  updateOptionsMessaje: (state, items) => {
    state.optionsChatBotMessages = items
  },
  setChatActiveAdmin: (state, chat) => {
    state.chatActiveAdmin = chat

    /* if (chat !== null) {
      this.$emit('open-chat', chat.uiiduser)
    } */
  },

  // AFILIADOS
  addContactsAfiliados: state => {
    state.contactsAfiliados.push({
      id: uuidv4(),
      name: null,
      emails: [
        {
          email: null,
        },
      ],
      role_id: null,
      telefonos: [
        {
          ext: null,
          telefono: null,
        },
      ],
      products: [],
    })
  },
  deleteContactsAfiliados: (state, pos) => {
    const { contactsAfiliados } = state

    contactsAfiliados.splice(pos, 1)
  },
  updateContactsAfiliados: (state, items) => {
    state.contactsAfiliados = items
  },
  addEmailContactsAfiliados: (state, pos) => {
    state.contactsAfiliados[pos].emails.push({
      email: null,
    })
  },
  deleteEmailContactsAfiliados: (state, { pos, index }) => {
    const { contactsAfiliados } = state

    contactsAfiliados[index].emails.splice(pos, 1)
  },
  addTelefonoContactsAfiliados: (state, pos) => {
    state.contactsAfiliados[pos].telefonos.push({
      ext: null,
      telefono: null,
    })
  },
  deleteTelefonoContactsAfiliados: (state, { pos, index }) => {
    const { contactsAfiliados } = state

    contactsAfiliados[index].telefonos.splice(pos, 1)
  },

  // MARKUPS
  deleteMarkupsList: (state, pos) => {
    const { markupsList } = state

    markupsList.splice(pos, 1)
  },
  updateMarkupsList: (state, items) => {
    state.markupsList = items
  },

  // RESERVAS
  addEmailAdminReserve: state => {
    state.emailsReserve.push({
      email: null,
    })
  },
  deleteEmailAdminReserve: (state, pos) => {
    const { emailsReserve } = state

    emailsReserve.splice(pos, 1)
  },
  updateEmailAdminReserve: (state, items) => {
    state.emailsReserve = items
  },

  // STOP SALE
  updateStopSales: (state, item) => {
    state.stopSales = item
  },
  updateWatchEvent: (state, value) => {
    state.watchEvent = value
  },
  updateStopSalesPart: (state, parts) => {
    state.stopSales = parts
  },
  deleteStopSalesList: (state, pos) => {
    const { stopSalesList } = state

    stopSalesList.splice(pos, 1)
  },
  updateStopSalesList: (state, items) => {
    state.stopSalesList = items
  },
  addDateStopSale: state => {
    state.datesStopSale.push({
      from: null,
      to: null,
      indefinido: false,
    })
  },
  deleteDateStopSale: (state, pos) => {
    const { datesStopSale } = state

    datesStopSale.splice(pos, 1)
  },
  updateDateStopSale: (state, items) => {
    state.datesStopSale = items
  },

  /** OFERTAS */
  updateOfertEBBList: (state, items) => {
    state.ofertEBBList = items
  },
  updateOfertEBBDates: (state, item) => {
    state.ofertEBBDates = item
  },
  updateOfertEBBDatesOrigin: (state, item) => {
    state.ofertEBBDatesOrigin = item
  },
  initOfertEBB(state) {
    state.ofertEBBDates = {
      identificador: null,
      type_offert: null,
      fromReserva: null,
      toReserva: null,
      rooming: null,
      pays: [
        {
          fromPagar: null,
          daysPagar: null,
          valuePagar: null,
          in_pc_pagar: true,
        },
      ],
      datePublic: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdate: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdateOld: new Date(Date.now()).toISOString().substr(0, 10),
      dateUpdateSaved: null,
      dateCancele: null,
      stopSale: false,
      dateStopSale: null,
      modelo: 'precio', // descuento
      calculadoPor: null,
      by_suplement_reductions: false,
      price_by_room: false,
      hotels: [
        {
          hotel_id: null,
          rooms_by_price: [],
          rooms_by_price_suplement: [],
          descuentos: [],
        },
      ],
      historial: [
        {
          action: 'Publicada',
          date: new Date(Date.now()).toISOString().substr(0, 10),
          nameUser: null,
          idUser: null,
        },
      ],

      /* descuentos: [
        {
          valueDescuento: null,
          in_value_descuento: false,
          rooms_id: [],
        },
      ],
      rooms_by_price: [
        {
          room_id: null,
          price_doble: null,
          price_simple: null,
          price_triple: null,
          price_first_child: null,
          price_second_child: null,
          price_infant: null,
          beneficios: null,
        },
      ],
      rooms_by_price_suplement: [
        {
          room_id: null,
          price_doble: null,
          suplement: [
            {
              suplement_id: null,
              valueSuplement: null,
              in_value_suplement: false,
            },
          ],
          reduction: [
            {
              reduction_id: null,
              valueReduction: null,
              in_value_reduction: false,
            },
          ],
          beneficios: null,
        },
      ], */
    }
  },
  asigUserHistorialOfertEBB(state, { nameUser, idUser }) {
    state.ofertEBBDates.historial[state.ofertEBBDates.historial.length - 1].nameUser = nameUser
    state.ofertEBBDates.historial[state.ofertEBBDates.historial.length - 1].idUser = idUser
  },
  emptyOfertEBB(state) {
    state.ofertEBBDates = {}
    state.indexHotelEBB = -1
    state.showHotelEBB = false
    state.indexRoomEBB = -1
    state.showRoomEBB = false
  },
  addHotelOfertEBB(state) {
    state.ofertEBBDates.hotels.push({
      hotel_id: null,
      rooms_by_price: [],
      rooms_by_price_suplement: [],
      descuentos: [],
    })
  },
  deleteHotelOfertEBB(state, pos) {
    state.ofertEBBDates.hotels.splice(pos, 1)
  },
  initDescuentosOfertEBB(state, pos) {
    state.ofertEBBDates.hotels[pos].descuentos = [
      {
        valueDescuento: null,
        in_value_descuento: false,
        rooms_id: [],
        fromViaje: state.ofertEBBDates.fromReserva,
        toViaje: state.ofertEBBDates.toReserva,
        plan_id: null,
      },
    ]
  },
  initRoomByPriceHotelOfertEBB(state, pos) {
    state.ofertEBBDates.hotels[pos].rooms_by_price = [
      {
        room_id: null,
        price_doble: null,
        fromViaje: state.ofertEBBDates.fromReserva,
        toViaje: state.ofertEBBDates.toReserva,
        plan_id: null,
        suplement_reductions: [
          {
            slug: null,
            is_suplement: false,
            suplement_reducc_id: null,
            price: null,
          },
        ],
        show_beneficios: false,
        beneficios: null,
      },
    ]

    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement = [
      {
        room_id: null,
        price_doble: null,
        fromViaje: state.ofertEBBDates.fromReserva,
        toViaje: state.ofertEBBDates.toReserva,
        plan_id: null,
        suplement: [
          {
            suplement_id: null,
            valueSuplement: null,
            in_value_suplement: false,
          },
        ],
        reduction: [
          {
            reduction_id: null,
            valueReduction: null,
            in_value_reduction: false,
          },
        ],
        show_beneficios: false,
        beneficios: null,
      },
    ]
  },
  initAllDescuentosOfertEBB(state) {
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < state.ofertEBBDates.hotels.length; pos++) {
      state.ofertEBBDates.hotels[pos].descuentos = [
        {
          valueDescuento: null,
          in_value_descuento: false,
          rooms_id: [],
          fromViaje: state.ofertEBBDates.fromReserva,
          toViaje: state.ofertEBBDates.toReserva,
          plan_id: null,
        },
      ]
    }
  },
  initAllRoomByPriceHotelOfertEBB(state) {
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < state.ofertEBBDates.hotels.length; pos++) {
      state.ofertEBBDates.hotels[pos].rooms_by_price = [
        {
          room_id: null,
          price_doble: null,
          fromViaje: state.ofertEBBDates.fromReserva,
          toViaje: state.ofertEBBDates.toReserva,
          plan_id: null,
          suplement_reductions: [
            {
              slug: null,
              is_suplement: false,
              suplement_reducc_id: null,
              price: null,
            },
          ],
          show_beneficios: false,
          beneficios: null,
        },
      ]

      state.ofertEBBDates.hotels[pos].rooms_by_price_suplement = [
        {
          room_id: null,
          price_doble: null,
          fromViaje: state.ofertEBBDates.fromReserva,
          toViaje: state.ofertEBBDates.toReserva,
          plan_id: null,
          suplement: [
            {
              suplement_id: null,
              valueSuplement: null,
              in_value_suplement: false,
            },
          ],
          reduction: [
            {
              reduction_id: null,
              valueReduction: null,
              in_value_reduction: false,
            },
          ],
          show_beneficios: false,
          beneficios: null,
        },
      ]
    }
  },
  addRoomByPriceHotelOfertEBB(state, pos) {
    state.ofertEBBDates.hotels[pos].rooms_by_price.push({
      room_id: null,
      price_doble: null,
      fromViaje: state.ofertEBBDates.fromReserva,
      toViaje: state.ofertEBBDates.toReserva,
      plan_id: null,
      suplement_reductions: [
        {
          slug: null,
          is_suplement: false,
          suplement_reducc_id: null,
          price: null,
        },
      ],
      show_beneficios: false,
      beneficios: null,
    })
  },
  deleteRoomByPriceHotelOfertEBB(state, { pos, posRoom }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price.splice(posRoom, 1)
  },
  addSupRedRoomByPriceHotelOfertEBB(state, { pos, posRoom }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price[posRoom].suplement_reductions.push({
      slug: null,
      is_suplement: false,
      suplement_reducc_id: null,
      price: null,
    })
  },
  addDescuentoOfertEBB(state, pos) {
    state.ofertEBBDates.hotels[pos].descuentos.push({
      valueDescuento: null,
      in_value_descuento: false,
      rooms_id: [],
      fromViaje: state.ofertEBBDates.fromReserva,
      toViaje: state.ofertEBBDates.toReserva,
      plan_id: null,
    })
  },
  addRoomByPriceBySuplementHotelOfertEBB(state, pos) {
    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement.push({
      room_id: null,
      price_doble: null,
      fromViaje: state.ofertEBBDates.fromReserva,
      toViaje: state.ofertEBBDates.toReserva,
      plan_id: null,
      suplement: [
        {
          suplement_id: null,
          valueSuplement: null,
          in_value_suplement: false,
        },
      ],
      reduction: [
        {
          reduction_id: null,
          valueReduction: null,
          in_value_reduction: false,
        },
      ],
      show_beneficios: false,
      beneficios: null,
    })
  },
  deleteRoomByPriceBySuplementHotelOfertEBB(state, { pos, posRoom }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement.splice(posRoom, 1)
  },
  addSuplementRoomByPriceBySuplementHotelOfertEBB(state, { pos, posRoom }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement[posRoom].suplement.push({
      suplement_id: null,
      valueSuplement: null,
      in_value_suplement: false,
    })
  },
  deleteSuplementRoomByPriceBySuplementHotelOfertEBB(state, { pos, posRoom, posSup }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement[posRoom].suplement.splice(posSup, 1)
  },
  addReductionRoomByPriceBySuplementHotelOfertEBB(state, { pos, posRoom }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement[posRoom].reduction.push({
      reduction_id: null,
      valueReduction: null,
      in_value_reduction: false,
    })
  },
  deleteReductionRoomByPriceBySuplementHotelOfertEBB(state, { pos, posRoom, posSup }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price_suplement[posRoom].reduction.splice(posSup, 1)
  },
  deleteDescuentoOfertEBB(state, { pos, posDescuento }) {
    state.ofertEBBDates.hotels[pos].descuentos.splice(posDescuento, 1)
  },
  deleteSupRedRoomByPriceHotelOfertEBB(state, { pos, posRoom, posSup }) {
    state.ofertEBBDates.hotels[pos].rooms_by_price[posRoom].suplement_reductions.splice(posSup, 1)
  },
  deleteOfertEBB(state, pos) {
    state.ofertEBBDates.splice(pos, 1)
  },
  updateOfertEBB(state, update) {
    state.ofertEBBDates = update
  },
  deleteOfertPay(state, pos) {
    state.ofertEBBDates.pays.splice(pos, 1)
  },
  addOfertPay(state) {
    state.ofertEBBDates.pays.push({
      fromPagar: null,
      valuePagar: null,
      in_pc_pagar: true,
    })
  },
  updateOfertPay(state, date) {
    state.ofertEBBDates.dateCancele = date
  },
  emptyDescuentosOfertEBB(state) {
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < state.ofertEBBDates.hotels.length; pos++) {
      state.ofertEBBDates.hotels[pos].descuentos = [
        {
          valueDescuento: null,
          in_value_descuento: false,
          rooms_id: [],
          fromViaje: state.ofertEBBDates.fromReserva,
          toViaje: state.ofertEBBDates.toReserva,
          plan_id: null,
        },
      ]
    }
  },
  emptyRoomByPriceHotelOfertEBB(state) {
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < state.ofertEBBDates.hotels.length; pos++) {
      state.ofertEBBDates.hotels[pos].rooms_by_price = [
        {
          room_id: null,
          price_doble: null,
          fromViaje: state.ofertEBBDates.fromReserva,
          toViaje: state.ofertEBBDates.toReserva,
          plan_id: null,
          suplement_reductions: [
            {
              slug: null,
              is_suplement: false,
              suplement_reducc_id: null,
              price: null,
            },
          ],
          show_beneficios: false,
          beneficios: null,
        },
      ]
    }
  },
  emptyRoomByPriceBySuplementHotelOfertEBB(state) {
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < state.ofertEBBDates.hotels.length; pos++) {
      state.ofertEBBDates.hotels[pos].rooms_by_price_suplement = [
        {
          room_id: null,
          price_doble: null,
          fromViaje: state.ofertEBBDates.fromReserva,
          toViaje: state.ofertEBBDates.toReserva,
          plan_id: null,
          suplement: [
            {
              suplement_id: null,
              valueSuplement: null,
              in_value_suplement: false,
            },
          ],
          reduction: [
            {
              reduction_id: null,
              valueReduction: null,
              in_value_reduction: false,
            },
          ],
          show_beneficios: false,
          beneficios: null,
        },
      ]
    }
  },
  updateShowHotelEBB(state, index) {
    state.showRoomEBB = false
    state.indexRoomEBB = -1
    if (state.indexHotelEBB === -1) {
      state.indexHotelEBB = index
      state.showHotelEBB = true
    } else if (state.indexHotelEBB === index) {
      state.indexHotelEBB = -1
      state.showHotelEBB = false
    } else if (state.indexHotelEBB !== index) {
      state.indexHotelEBB = index
    }
  },
  updateShowRoomEBB(state, index) {
    if (state.indexRoomEBB === -1) {
      state.indexRoomEBB = index
      state.showRoomEBB = true
    } else if (state.indexRoomEBB === index) {
      state.indexRoomEBB = -1
      state.showRoomEBB = false
    } else if (state.indexRoomEBB !== index) {
      state.indexRoomEBB = index
    }
  },

  // CAR SHOP
  adicionarItemToCar(state, item) {
    state.carItems.push(item)
  },
  updateItemToCar(state, items) {
    state.carItems = items
  },
  deleteItemToCar(state, pos) {
    state.carItems.splice(pos, 1)
  },
  emptyClientCarShop: state => {
    state.clientCarShop = {
      name: null,
      apellidos: null,
      email: null,
      subject: null,
      message: null,
      telefono: null,
      is_client_corporate: false,
      id_client_corporate: null,
      id_cta_bancaria: null,
      emails: [],
    }
  },
  setClientCarShop(state, item) {
    state.clientCarShop = item
  },
  updateMessageClientCarShop(state, message) {
    state.clientCarShop.message = message
  },
  updateLoadingBtn(state, loading) {
    state.loadingBtn = loading
  },
  updateIndexCarItem(state, index) {
    state.indexCarItem = index
  },
  updateShowCarItem(state, index) {
    state.showCarItem = index
  },

  setCheckAll(state, value) {
    state.checkAll = value
  },
  addItemCheck(state, item) {
    state.itemsCheck.push(item)
  },
  updateItemCheck(state, arr) {
    state.itemsCheck = arr
  },
  deleteItemCheck(state, pos) {
    state.itemsCheck.splice(pos, 1)
  },
  setCheckAllCobros(state, value) {
    state.checkAllCobros = value
  },
  addItemCheckCobros(state, item) {
    state.itemsCheckCobros.push(item)
  },
  updateItemCheckCobros(state, arr) {
    state.itemsCheckCobros = arr
  },
  deleteItemCheckCobros(state, pos) {
    state.itemsCheckCobros.splice(pos, 1)
  },
  emptyEmailCode(state) {
    state.emailsCodes = []
  },
  addEmailCode(state, email) {
    state.emailsCodes.push({
      email,
    })
  },
  deleteEmailCode(state, pos) {
    state.emailsCodes.splice(pos, 1)
  },

  setCheckAllPaysEmit(state, value) {
    state.checkAllPaysEmit = value
  },
  addItemCheckPaysEmit(state, item) {
    state.itemsCheckPaysEmit.push(item)
  },
  updateItemCheckPaysEmit(state, arr) {
    state.itemsCheckPaysEmit = arr
  },
  deleteItemCheckPaysEmit(state, pos) {
    state.itemsCheckPaysEmit.splice(pos, 1)
  },

  setCheckAllPaysRec(state, value) {
    state.checkAllPaysRec = value
  },
  addItemCheckPaysRec(state, item) {
    state.itemsCheckPaysRec.push(item)
  },
  updateItemCheckPaysRec(state, arr) {
    state.itemsCheckPaysRec = arr
  },
  deleteItemCheckPaysRec(state, pos) {
    state.itemsCheckPaysRec.splice(pos, 1)
  },

  // SEASTING
  initSeasting: state => {
    // RESETEAR
    state.seasting.headersLeft = []
    state.seasting.seatsLeft = []
    state.seasting.headersRight = []
    state.seasting.seatsRight = []
    state.seasting.count.columnsLeft = 3
    state.seasting.count.columnsRight = 3
    state.seasting.count.rows = 15

    // HEADERS LEFT
    state.seasting.headersLeft.push({
      name: 'A',
    })
    state.seasting.headersLeft.push({
      name: 'B',
    })
    state.seasting.headersLeft.push({
      name: 'C',
    })

    // HEADERS RIGHT
    state.seasting.headersRight.push({
      name: 'D',
    })
    state.seasting.headersRight.push({
      name: 'E',
    })
    state.seasting.headersRight.push({
      name: 'F',
    })

    for (let row = 0; row < state.seasting.count.rows; row++) {
      // SEAST LEFT
      for (let hLeft = 0; hLeft < state.seasting.headersLeft.length; hLeft++) {
        state.seasting.seatsLeft.push({
          name: null,
          description: null,
          hidden: false,
          disabled: false,
          row: row + 1,
          posHead: hLeft,
        })
      }

      // SEAST RIGHT
      for (let hRight = 0; hRight < state.seasting.headersRight.length; hRight++) {
        state.seasting.seatsRight.push({
          name: null,
          description: null,
          hidden: false,
          disabled: false,
          row: row + 1,
          posHead: hRight,
        })
      }
    }
  },
  setSeasting(state, value) {
    state.seasting = value
  },
  deleteLeftSeasting: (state, posHead) => {
    state.seasting.headersLeft.splice(posHead, 1)

    const newSeats = []
    for (let index = 0; index < state.seasting.seatsLeft.length; index++) {
      if (state.seasting.seatsLeft[index].posHead !== posHead) {
        newSeats.push(state.seasting.seatsLeft[index])
      }
    }
    state.seasting.seatsLeft = newSeats
    state.seasting.count.columnsLeft -= 1
  },
  addLeftSeasting: (state, posHead) => {
    state.seasting.headersLeft.push({
      name: posHead,
    })

    for (let row = 0; row < state.seasting.count.rows; row++) {
      // SEAST LEFT
      state.seasting.seatsLeft.push({
        name: null,
        description: null,
        hidden: false,
        disabled: false,
        row: row + 1,
        posHead: posHead - 1,
      })
    }
    state.seasting.count.columnsLeft += 1
  },
  deleteRightSeasting: (state, posHead) => {
    state.seasting.headersRight.splice(posHead, 1)

    const newSeats = []
    for (let index = 0; index < state.seasting.seatsRight.length; index++) {
      if (state.seasting.seatsRight[index].posHead !== posHead) {
        newSeats.push(state.seasting.seatsRight[index])
      }
    }
    state.seasting.seatsRight = newSeats
    state.seasting.count.columnsRight -= 1
  },
  addRightSeasting: (state, posHead) => {
    state.seasting.headersRight.push({
      name: posHead,
    })

    for (let row = 0; row < state.seasting.count.rows; row++) {
      // SEAST RIGHT
      state.seasting.seatsRight.push({
        name: null,
        description: null,
        hidden: false,
        disabled: false,
        row: row + 1,
        posHead: posHead - 1,
      })
    }
    state.seasting.count.columnsRight += 1
  },
  addRowSeasting: state => {
    state.seasting.count.rows += 1

    // SEAST LEFT
    for (let hLeft = 0; hLeft < state.seasting.headersLeft.length; hLeft++) {
      state.seasting.seatsLeft.push({
        name: null,
        description: null,
        hidden: false,
        disabled: false,
        row: state.seasting.count.rows,
        posHead: hLeft,
      })
    }

    // SEAST RIGHT
    for (let hRight = 0; hRight < state.seasting.headersRight.length; hRight++) {
      state.seasting.seatsRight.push({
        name: null,
        description: null,
        hidden: false,
        disabled: false,
        row: state.seasting.count.rows,
        posHead: hRight,
      })
    }
  },
  deleteRowSeasting: state => {
    // SEATS LEFT
    const newLeftSeats = []
    for (let index = 0; index < state.seasting.seatsLeft.length; index++) {
      if (state.seasting.seatsLeft[index].row !== state.seasting.count.rows) {
        newLeftSeats.push(state.seasting.seatsLeft[index])
      }
    }
    state.seasting.seatsLeft = newLeftSeats

    // SEATS RIGHT
    const newRightSeats = []
    for (let index = 0; index < state.seasting.seatsRight.length; index++) {
      if (state.seasting.seatsRight[index].row !== state.seasting.count.rows) {
        newRightSeats.push(state.seasting.seatsRight[index])
      }
    }
    state.seasting.seatsRight = newRightSeats

    state.seasting.count.rows -= 1
  },

  setFiltrosExport(state, item) {
    state.filtrosExport = item
  },

  updateCMS(state, item) {
    state.textEnriquecido = item
  },

  addCtasBancarias(state) {
    state.ctasBancarias.push({
      id: uuidv4(),
      payments_id: 0,
      is_new: true,
      payments: 'banco',
      name_fiscal_facturation: null,
      no_cuenta_bancaria: null,
      moneda: null,
      swift: null,
      iban: null,
      name_banco: null,
      code_sucursal: null,
      address: [
        {
          address: null,
        },
      ],
      code_postal: null,
      localidad: null,
      country: null,
      principal: false,
      update_utc: 'automatic',
      utc: null,
      time_utc_public: null,
      time_utc_check: null,
      time_utc_active: null,
      dataZelle: {
        email: null,
        phone: null,
      },
      dataWise: null,
      dataPaypal: null,
      dataRedsys: null,
      dataSIBS: null,
      dataCards: {
        name: null,
        number: null,
        date_expire: null,
        cvv: null,
        principal: false,
      },
    })
  },
  updateCtasBancarias(state, arr) {
    state.ctasBancarias = arr
  },
  deleteCtasBancarias(state, pos) {
    state.ctasBancarias.splice(pos, 1)
  },
  addAddressCtasBancarias(state, pos) {
    state.ctasBancarias[pos].address.push({
      address: null,
    })
  },
  deleteAddressCtasBancarias(state, { pos, posA }) {
    state.ctasBancarias[pos].address.splice(posA, 1)
  },

  // TARIFARIOS
  updateItemsTarifarios(state, arr) {
    state.itemsTarifarios = arr
  },
  updateSearchCars(state, value) {
    state.searchCars = value
  },
  updateSearchHotels(state, value) {
    state.searchHotels = value
  },
  updateSearchFlights(state, value) {
    state.searchFlights = value
  },

  // BANCARIOS
  updateDataBancaria(state, arr) {
    state.dataBancaria = arr
  },
  addAddressDataBancaria(state) {
    state.dataBancaria.info_fiscal.address.push({
      address: null,
    })
  },
  deleteAddressDataBancaria(state, pos) {
    state.dataBancaria.info_fiscal.address.splice(pos, 1)
  },

  // PROVEEDORES
  updateProveedorApi(state, arr) {
    state.proveedorApis = arr
  },
  addProveedorApi(state) {
    state.proveedorApis.push({
      code: null,
      proveedor_id: null,
      proveedor_slug: null,
      user: null,
      password: null,
    })
  },
  deleteProveedorApi(state, pos) {
    state.proveedorApis.splice(pos, 1)
  },

  // FILTROS
  setItemsPerPage(state, cont) {
    state.itemsPerPage = cont
  },
  setItemsPage(state, page) {
    state.itemsPage = page
  },
  setItemsCont(state, cont) {
    state.itemsCont = cont
  },
  updateReservasAll(state, items) {
    state.reservasAll = items
  },
  setReservasPaginate(state, items) {
    state.reservasPaginate = items
  },
  updateReservasPaginate(state, items) {
    items.forEach(element => {
      state.reservasPaginate.push(element)
    })
  },
  setIdUserLevel0(state, items) {
    state.idUserLevel0 = items
  },
  setIdUserAfiliates(state, items) {
    state.idUserAfiliates = items
  },
  updateCotizationsAll(state, items) {
    state.cotizationsAll = items
  },
  setCotizationsPaginate(state, items) {
    state.cotizationsPaginate = items
  },
  updateCotizationsPaginate(state, items) {
    items.forEach(element => {
      state.cotizationsPaginate.push(element)
    })
  },
  updateRefundAll(state, items) {
    state.refundAll = items
  },
  updateRefundPaginate(state, items) {
    items.forEach(element => {
      state.refundPaginate.push(element)
    })
  },
  setRefundPaginate(state, items) {
    state.refundPaginate = items
  },
  filtrarReservas(state) {
    // FECHA DE COMPRA DESDE
    if (state.filtersReservas.dateReserveFrom) {
      const result = []

      state.reservasAll.forEach(element => {
        if (element.date_compra >= state.filtersReservas.dateReserveFrom) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    } else {
      state.reservasPaginate = state.reservasAll
    }

    // FECHA DE COMPRA HASTA
    if (state.filtersReservas.dateReserveTo) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (state.filtersReservas.dateReserveFrom) {
          if (element.date_compra >= state.filtersReservas.dateReserveFrom
              && element.date_compra <= state.filtersReservas.dateReserveTo) {
            result.push(element)
          }
        } else if (element.date_compra <= state.filtersReservas.dateReserveTo) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    // FECHA DE INICIO DE SERVICIO
    if (state.filtersReservas.dateServiceFrom) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        // eslint-disable-next-line prefer-destructuring
        dateServicio = element.date_servicio[0]
        if (dateServicio >= state.filtersReservas.dateServiceFrom) {
          esta = true
        }

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    /*
    if (state.filtersReservas.dateServiceFrom) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === 'cars') {
            dateServicio = elementCar.data_service.dateRecogida
          } else if (elementCar.entity === 'hotels') {
            dateServicio = elementCar.data_service.dateIn
          }
          if (dateServicio >= state.filtersReservas.dateServiceFrom) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }
    */

    // FECHA DE FIN DE SERVICIO
    if (state.filtersReservas.dateServiceTo) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        // eslint-disable-next-line prefer-destructuring
        dateServicio = element.date_servicio[0]
        if (state.filtersReservas.dateServiceFrom) {
          if (dateServicio >= state.filtersReservas.dateServiceFrom
              && dateServicio <= state.filtersReservas.dateServiceTo) {
            esta = true
          }
        } else if (dateServicio <= state.filtersReservas.dateServiceTo) {
          esta = true
        }

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    /*
    if (state.filtersReservas.dateServiceTo) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === 'cars') {
            dateServicio = elementCar.data_service.dateRecogida
          } else if (elementCar.entity === 'hotels') {
            dateServicio = elementCar.data_service.dateIn
          }
          if (state.filtersReservas.dateServiceFrom) {
            if (dateServicio >= state.filtersReservas.dateServiceFrom
              && dateServicio <= state.filtersReservas.dateServiceTo) {
              esta = true
            }
          } else if (dateServicio <= state.filtersReservas.dateServiceTo) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }
    */

    //  TIPO DE PRODUCTO
    if (state.filtersReservas.tipo_product) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === state.filtersReservas.tipo_product) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  NOMBRE DEL CLIENTE
    if (state.filtersReservas.fullName) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        if (element.cliente.name) {
          if (element.cliente.name.toLowerCase().includes(state.filtersReservas.fullName.toLowerCase())) {
            esta = true
          }
        }
        element.car_shop.forEach(elementCar => {
          if (elementCar.data_client.name) {
            if (elementCar.data_client.name.toLowerCase().includes(state.filtersReservas.fullName.toLowerCase())) {
              esta = true
            }
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  AFILIADO
    if (state.filtersReservas.afiliate) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (state.filtersReservas.afiliate === 0) {
          if (state.idUserLevel0.includes(element.user_id)) {
            result.push(element)
          }
        } else {
          let esta = false
          state.idUserAfiliates.forEach(elementUser => {
            if (elementUser === element.user_id && elementUser === state.filtersReservas.afiliate) {
              esta = true
            }
          })
          if (esta) {
            result.push(element)
          }
        }
      })

      state.reservasPaginate = result
    }

    //  SELLER
    if (state.filtersReservas.seller) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (element.user_id === state.filtersReservas.seller) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  CODIGO
    if (state.filtersReservas.codeReserva) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (element.code === state.filtersReservas.codeReserva) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  ESTADO
    if (state.filtersReservas.state) {
      const result = []

      state.reservasPaginate.forEach(element => {
        state.filtersReservas.state.forEach(elementState => {
          if (element.state_reserva_id === elementState) {
            result.push(element)
          }
        })
      })

      state.reservasPaginate = result
    }
  },
  initFiltrosReservas(state, states) {
    state.filtersReservas = {
      dateReserveFrom: null,
      dateReserveTo: null,
      dateServiceFrom: null,
      dateServiceTo: null,
      tipo_product: null,
      fullName: null,
      afiliate: null,
      seller: null,
      codeReserva: null,
      state: states,
    }

    state.reservasPaginate = []

    // FECHA DE COMPRA DESDE
    if (state.filtersReservas.dateReserveFrom) {
      const result = []

      state.reservasAll.forEach(element => {
        if (element.date_compra >= state.filtersReservas.dateReserveFrom) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    } else {
      state.reservasPaginate = state.reservasAll
    }

    // FECHA DE COMPRA HASTA
    if (state.filtersReservas.dateReserveTo) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (state.filtersReservas.dateReserveFrom) {
          if (element.date_compra >= state.filtersReservas.dateReserveFrom
              && element.date_compra <= state.filtersReservas.dateReserveTo) {
            result.push(element)
          }
        } else if (element.date_compra <= state.filtersReservas.dateReserveTo) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    // FECHA DE INICIO DE SERVICIO
    if (state.filtersReservas.dateServiceFrom) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === 'cars') {
            dateServicio = elementCar.data_service.dateRecogida
          } else if (elementCar.entity === 'hotels') {
            dateServicio = elementCar.data_service.dateIn
          }
          if (dateServicio >= state.filtersReservas.dateServiceFrom) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    // FECHA DE FIN DE SERVICIO
    if (state.filtersReservas.dateServiceTo) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === 'cars') {
            dateServicio = elementCar.data_service.dateRecogida
          } else if (elementCar.entity === 'hotels') {
            dateServicio = elementCar.data_service.dateIn
          }
          if (state.filtersReservas.dateServiceFrom) {
            if (dateServicio >= state.filtersReservas.dateServiceFrom
              && dateServicio <= state.filtersReservas.dateServiceTo) {
              esta = true
            }
          } else if (dateServicio <= state.filtersReservas.dateServiceTo) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  TIPO DE PRODUCTO
    if (state.filtersReservas.tipo_product) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === state.filtersReservas.tipo_product) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  NOMBRE DEL CLIENTE
    if (state.filtersReservas.fullName) {
      const result = []

      state.reservasPaginate.forEach(element => {
        let esta = false
        if (element.cliente.name) {
          if (element.cliente.name.toLowerCase().includes(state.filtersReservas.fullName.toLowerCase())) {
            esta = true
          }
        }
        element.car_shop.forEach(elementCar => {
          if (elementCar.data_client.name) {
            if (elementCar.data_client.name.toLowerCase().includes(state.filtersReservas.fullName.toLowerCase())) {
              esta = true
            }
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  AFILIADO
    if (state.filtersReservas.afiliate) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (state.filtersReservas.afiliate === 0) {
          if (state.idUserLevel0.includes(element.user_id)) {
            result.push(element)
          }
        } else {
          let esta = false
          state.idUserAfiliates.forEach(elementUser => {
            if (elementUser === element.user_id && elementUser === state.filtersReservas.afiliate) {
              esta = true
            }
          })
          if (esta) {
            result.push(element)
          }
        }
      })

      state.reservasPaginate = result
    }

    //  SELLER
    if (state.filtersReservas.seller) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (element.user_id === state.filtersReservas.seller) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  CODIGO
    if (state.filtersReservas.codeReserva) {
      const result = []

      state.reservasPaginate.forEach(element => {
        if (element.code === state.filtersReservas.codeReserva) {
          result.push(element)
        }
      })

      state.reservasPaginate = result
    }

    //  ESTADO
    if (state.filtersReservas.state) {
      const result = []

      state.reservasPaginate.forEach(element => {
        state.filtersReservas.state.forEach(elementState => {
          if (element.state_reserva_id === elementState) {
            result.push(element)
          }
        })
      })

      state.reservasPaginate = result
    }
  },
  clearFiltrosReservas(state) {
    state.filtersReservas = {
      dateReserveFrom: null,
      dateReserveTo: null,
      dateServiceFrom: null,
      dateServiceTo: null,
      tipo_product: null,
      fullName: null,
      afiliate: null,
      seller: null,
      codeReserva: null,
      state: [],
    }

    state.reservasPaginate = []
    for (let index = 0; index < state.reservasAll.length; index++) {
      state.reservasPaginate.push(state.reservasAll[index])

      /* if (index < 10) {
        state.reservasPaginate.push(state.reservasAll[index])
      } */
    }
  },
  filtrarCotizations(state) {
    // FECHA DE COMPRA DESDE
    if (state.filtersCotizations.dateReserveFrom) {
      const result = []

      state.cotizationsAll.forEach(element => {
        if (element.date_compra >= state.filtersCotizations.dateReserveFrom) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    } else {
      state.cotizationsPaginate = state.cotizationsAll
    }

    // FECHA DE COMPRA HASTA
    if (state.filtersCotizations.dateReserveTo) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        if (state.filtersCotizations.dateReserveFrom) {
          if (element.date_compra >= state.filtersCotizations.dateReserveFrom
              && element.date_compra <= state.filtersCotizations.dateReserveTo) {
            result.push(element)
          }
        } else if (element.date_compra <= state.filtersCotizations.dateReserveTo) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    // FECHA DE INICIO DE SERVICIO
    if (state.filtersCotizations.dateServiceFrom) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === 'cars') {
            dateServicio = elementCar.data_service.dateRecogida
          } else if (elementCar.entity === 'hotels') {
            dateServicio = elementCar.data_service.dateIn
          }
          if (dateServicio >= state.filtersCotizations.dateServiceFrom) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    // FECHA DE FIN DE SERVICIO
    if (state.filtersCotizations.dateServiceTo) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        let esta = false
        let dateServicio = null
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === 'cars') {
            dateServicio = elementCar.data_service.dateRecogida
          } else if (elementCar.entity === 'hotels') {
            dateServicio = elementCar.data_service.dateIn
          }
          if (state.filtersCotizations.dateServiceFrom) {
            if (dateServicio >= state.filtersCotizations.dateServiceFrom
              && dateServicio <= state.filtersCotizations.dateServiceTo) {
              esta = true
            }
          } else if (dateServicio <= state.filtersCotizations.dateServiceTo) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    //  TIPO DE PRODUCTO
    if (state.filtersCotizations.tipo_product) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        let esta = false
        element.car_shop.forEach(elementCar => {
          if (elementCar.entity === state.filtersCotizations.tipo_product) {
            esta = true
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    //  NOMBRE DEL CLIENTE
    if (state.filtersCotizations.fullName) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        let esta = false
        if (element.cliente.name) {
          if (element.cliente.name.toLowerCase().includes(state.filtersCotizations.fullName.toLowerCase())) {
            esta = true
          }
        }
        element.car_shop.forEach(elementCar => {
          if (elementCar.data_client.name) {
            if (elementCar.data_client.name.toLowerCase().includes(state.filtersCotizations.fullName.toLowerCase())) {
              esta = true
            }
          }
        })

        if (esta) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    //  AFILIADO
    if (state.filtersCotizations.afiliate) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        if (state.filtersCotizations.afiliate === 0) {
          if (state.idUserLevel0.includes(element.user_id)) {
            result.push(element)
          }
        } else {
          let esta = false
          state.idUserAfiliates.forEach(elementUser => {
            if (elementUser === element.user_id && elementUser === state.filtersCotizations.afiliate) {
              esta = true
            }
          })
          if (esta) {
            result.push(element)
          }
        }
      })

      state.cotizationsPaginate = result
    }

    //  SELLER
    if (state.filtersCotizations.seller) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        if (element.user_id === state.filtersCotizations.seller) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    //  CODIGO
    if (state.filtersCotizations.codeReserva) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        if (element.code === state.filtersCotizations.codeReserva) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }

    //  ESTADO
    if (state.filtersCotizations.state) {
      const result = []

      state.cotizationsPaginate.forEach(element => {
        if (element.state_reserva_id === state.filtersCotizations.state) {
          result.push(element)
        }
      })

      state.cotizationsPaginate = result
    }
  },
  clearFiltrosCotizations(state) {
    state.filtersCotizations = {
      dateReserveFrom: null,
      dateReserveTo: null,
      dateServiceFrom: null,
      dateServiceTo: null,
      tipo_product: null,
      fullName: null,
      afiliate: null,
      seller: null,
      codeReserva: null,
      state: null,
    }

    state.cotizationsPaginate = []
    for (let index = 0; index < state.cotizationsAll.length; index++) {
      if (index < 10) {
        state.cotizationsPaginate.push(state.cotizationsAll[index])
      }
    }
  },
  updateProductsAll(state, items) {
    state.productsAll = items
  },
  setProductsPaginate(state, items) {
    state.productsPaginate = items
  },
  updateProductsPaginate(state, items) {
    items.forEach(element => {
      state.productsPaginate.push(element)
    })
  },
  filtrarCars(state) {
    // MARCA
    if (state.filtersCars.marca_id) {
      const result = []

      state.productsAll.forEach(element => {
        if (element.marca_id === state.filtersCars.marca_id) {
          result.push(element)
        }
      })

      state.productsPaginate = result
    } else {
      state.productsPaginate = state.productsAll
    }

    //  MODELOS
    if (state.filtersCars.modelo_id) {
      const result = []

      state.productsPaginate.forEach(element => {
        if (element.modelo_id === state.filtersCars.modelo_id) {
          result.push(element)
        }
      })

      state.productsPaginate = result
    }

    //  TRANSMISION
    if (state.filtersCars.transmision_id) {
      const result = []

      state.productsPaginate.forEach(element => {
        if (element.transmision_id === state.filtersCars.transmision_id) {
          result.push(element)
        }
      })

      state.productsPaginate = result
    }

    //  TIPO AUTO
    if (state.filtersCars.tipo_auto_id) {
      const result = []

      state.productsPaginate.forEach(element => {
        if (element.tipo_auto_id === state.filtersCars.tipo_auto_id) {
          result.push(element)
        }
      })

      state.productsPaginate = result
    }

    //  NOMBRE DEL CLIENTE
    if (state.filtersCars.name) {
      const result = []

      state.productsPaginate.forEach(element => {
        if (element.name.toLowerCase().includes(state.filtersCars.name.toLowerCase())) {
          result.push(element)
        }
      })

      state.productsPaginate = result
    }

    //  SIN PUBLICAR
    if (state.filtersCars.not_public === false) {
      const result = []

      state.productsPaginate.forEach(element => {
        if (element.publico == state.filtersCars.not_public) {
          result.push(element)
        }
      })

      state.productsPaginate = result
    }

    // TAGS
    let conNotP = 0
    state.productsPaginate.forEach(element => {
      if (!element.publico) {
        conNotP += 1
      }
    })
    state.contNotPublic = conNotP
  },
  initContCars: state => {
    let conInfoPend = 0
    let conNotP = 0
    state.productsPaginate.forEach(element => {
      if (!element.publico) {
        conNotP += 1
        conInfoPend += 1
      }
    })
    state.contNotPublic = conNotP
    state.contInfoPendient = conInfoPend
  },
  clearFiltrosCars(state) {
    state.filtersCars = {
      marca_id: null,
      modelo_id: null,
      transmision_id: null,
      tipo_auto_id: null,
      name: null,
      not_public: null,
      sin_marca: null,
      sin_modelo: null,
      sin_transmision: null,
      sin_tipo_auto: null,
      sin_combustible: null,
      sin_cant_puertas: null,
      sin_cant_paxs: null,
      sin_cant_bag_big: null,
      sin_cant_bag_small: null,
      sin_um: null,
      sin_capacidad_comb: null,
      sin_consumo_comb: null,
      sin_motor: null,
      sin_caracteristicas: null,
      sin_prioridad: null,
      sin_fotos: null,
      sin_tag_fotos: null,
      sin_tag_principal: null,
    }

    state.productsPaginate = []
    for (let index = 0; index < state.productsAll.length; index++) {
      if (index < 10) {
        state.productsPaginate.push(state.productsAll[index])
      }
    }

    // TAGS
    this.commit('initContCars')
  },

  // CLIENTS ID
  updateClientApi(state, arr) {
    state.clientsApis = arr
  },
  addClientApi(state) {
    state.clientsApis.push({
      client_id: null,
      client_secret: null,
      active: true,
    })
  },
  deleteClientApi(state, pos) {
    state.clientsApis.splice(pos, 1)
  },
  setLoadingContent(state, status) {
    state.loadingContent = status
  },

  updatePaginationResult(state, update) {
    state.paginationResult = update
  },
  updateItemsPerPageResultSearch(state, update) {
    state.itemsPerPageResultSearch = update
  },
  updateShowMap(state, update) {
    state.showMapHotels = update
  },
  updateAllHotels(state, value) {
    state.cotizadorHotelsAll = value
  },
  updateIsLoadingSearch(state, update) {
    state.isLoadingSearch = update
  },
  setIsLoadingPagination(state, update) {
    state.isLoadingPagination = update
  },
  filtersCotizadorHotelsResult(state) {
    state.isLoadingSearch = true

    // NOMBRE ALOJAMIENTO
    if (state.cotizadorHotelsResultFilters.name) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        if (element.hotel.name.toLowerCase().includes(state.cotizadorHotelsResultFilters.name.toLowerCase())) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    } else {
      state.cotizadorHotelsResult = state.cotizadorHotelsResultOrigin
    }

    //  CATEGORIAS
    if (state.cotizadorHotelsResultFilters.categories.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        if (state.cotizadorHotelsResultFilters.categories.includes(element.hotel.categoria.id)) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    // PRICE
    if (state.cotizadorHotelsResultFilters.price.length === 2) {
      const result = []
      state.cotizadorHotelsResult.forEach(element => {
        if (state.cotizadorHotelsResultFilters.price[0] <= element.minPriceMark && state.cotizadorHotelsResultFilters.price[1] >= element.minPriceMark) {
          result.push(element)
        }
      })
      state.cotizadorHotelsResult = result
    }

    //  TIPO DESTINO
    if (state.cotizadorHotelsResultFilters.tipoDestinos.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        if (state.cotizadorHotelsResultFilters.tipoDestinos.includes(element.hotel.tipo_destino.id)) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    //  CADENA HOTELERA
    if (state.cotizadorHotelsResultFilters.cadenaHotelera.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        if (state.cotizadorHotelsResultFilters.cadenaHotelera.includes(element.hotel.operador.id)) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    //  MARCA HOTELERA
    if (state.cotizadorHotelsResultFilters.marcaHotelera.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        if (element.hotel.marca_asociada) {
          if (state.cotizadorHotelsResultFilters.marcaHotelera.includes(element.hotel.marca_asociada.id)) {
            result.push(element)
          }
        }
      })

      state.cotizadorHotelsResult = result
    }

    //  TIPO RESERVA
    if (state.cotizadorHotelsResultFilters.tipoReserva.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        if (state.cotizadorHotelsResultFilters.tipoReserva.includes(element.tipo_reserva)) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    //  CONTRATO
    if (state.cotizadorHotelsResultFilters.contratos.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        let esta = false
        state.cotizadorHotelsResultFilters.contratos.forEach(elementCon => {
          if (element.sources.includes(elementCon)) {
            esta = true
          }
        })
        if (esta) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    //  SERVICIOS
    if (state.cotizadorHotelsResultFilters.services.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        let estaS = false
        state.cotizadorHotelsResultFilters.services.forEach(elementSer => {
          if (element.hotel.servicios_id.includes(elementSer)) {
            estaS = true
          }
        })
        if (estaS) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    //  INTERESES
    if (state.cotizadorHotelsResultFilters.intereses.length > 0) {
      const result = []

      state.cotizadorHotelsResult.forEach(element => {
        let estaI = false
        state.cotizadorHotelsResultFilters.intereses.forEach(elementInt => {
          if (element.hotel.intereses_id.includes(elementInt)) {
            estaI = true
          }
        })
        if (estaI) {
          result.push(element)
        }
      })

      state.cotizadorHotelsResult = result
    }

    // ORDERS
    // BY PRICE
    if (state.cotizadorHotelsResultFilters.orderByPrice) {
      if (state.cotizadorHotelsResultFilters.orderByPrice === 'asc') {
        state.cotizadorHotelsResult.sort((a, b) => {
          const x = a.minPriceMark
          const y = b.minPriceMark

          if (x > y) {
            return 1
          }
          if (x < y) {
            return -1
          }

          return 0
        })
      } else if (state.cotizadorHotelsResultFilters.orderByPrice === 'desc') {
        state.cotizadorHotelsResult.sort((a, b) => {
          const x = a.minPriceMark
          const y = b.minPriceMark

          if (x < y) {
            return 1
          }
          if (x > y) {
            return -1
          }

          return 0
        })
      }
    }

    // BY CATEGORY
    if (state.cotizadorHotelsResultFilters.orderByCategory) {
      if (state.cotizadorHotelsResultFilters.orderByCategory === 'asc') {
        state.cotizadorHotelsResult.sort((a, b) => {
          const x = a.hotel.categoria.cant_estrellas
          const y = b.hotel.categoria.cant_estrellas

          if (x > y) {
            return 1
          }
          if (x < y) {
            return -1
          }

          return 0
        })
      } else if (state.cotizadorHotelsResultFilters.orderByCategory === 'desc') {
        state.cotizadorHotelsResult.sort((a, b) => {
          const x = a.hotel.categoria.cant_estrellas
          const y = b.hotel.categoria.cant_estrellas

          if (x < y) {
            return 1
          }
          if (x > y) {
            return -1
          }

          return 0
        })
      }
    }

    // PAGINATION
    const arr = []
    const first = (state.pagination.current - 1) * state.itemsPerPage
    let last = state.pagination.current * state.itemsPerPage
    if (state.pagination.current === state.pagination.total) {
      last = state.cotizadorHotelsResult.length
    }
    // eslint-disable-next-line no-plusplus
    for (let index = first; index < last; index++) {
      arr.push(state.cotizadorHotelsResult[index])
    }
    state.cotizadorHotelsResultPaginate = arr
    state.pagination = {
      current: 1,
      total: Math.ceil(parseInt(state.cotizadorHotelsResult.length, parseInt(state.itemsPerPage, 10)) / 10),
    }

    setTimeout(() => {
      state.isLoadingSearch = false
    }, 100)
  },
  clearCotizadorHotelsResultFilters(state) {
    let minP = 0
    let maxP = 1000
    if (state.cotizadorHotelsResultOrigin.length > 0) {
      minP = state.cotizadorHotelsResultOrigin[0].minPriceMark
      maxP = state.cotizadorHotelsResultOrigin[state.cotizadorHotelsResultOrigin.length - 1].minPriceMark + 50
    }
    state.cotizadorHotelsResultFilters = {
      name: null,
      categories: [],
      price: [minP, maxP],
      tipoDestinos: [],
      cadenaHotelera: [],
      marcaHotelera: [],
      tipoReserva: [],
      contratos: [],
      services: [],
      intereses: [],
      orderByRecomendado: null,
      orderByCategory: null,
      orderByPrice: 'asc',
    }

    /* const arr = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < state.cotizadorHotelsResult.length; index++) {
      if (index === state.itemsPerPageResultSearch) {
        break
      }
      arr.push(state.cotizadorHotelsResult[index])
    }
    state.cotizadorHotelsResultPaginate = arr */
    state.cotizadorHotelsResult = state.cotizadorHotelsResultOrigin
  },

  initHotelsResult(state) {
    state.isLoadingSearch = true
    let last = state.itemsPerPage
    if (state.itemsPerPage > state.cotizadorHotelsResultOrigin.length) {
      last = state.cotizadorHotelsResultOrigin.length
    }
    const arr = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < last; index++) {
      arr.push(state.cotizadorHotelsResultOrigin[index])
    }
    state.pagination = {
      current: 1,
      total: Math.ceil(parseInt(state.cotizadorHotelsResultOrigin.length, parseInt(state.itemsPerPage, 10)) / 10),
    }
    state.cotizadorHotelsResultPaginate = arr
    setTimeout(() => {
      state.isLoadingSearch = false
    }, 100)
  },

  setCotizadorHotels(state, update) {
    state.cotizadorHotels = update
  },
  emptyCotizadorHotels(state) {
    state.cotizadorHotels = {
      destino: null,
      dateIn: null,
      dateOut: null,
      proveedor_id: null,
      categoria_id: [],
      tipo_destino_id: null,
      ocupation: [
        {
          adults: 2,
          childs: 0,
          edades: [],
          room_id: null,
          paxs: [{
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
          }],
          showAllPaxs: false,
          priceTotal: null,
          priceWithMarkups: null,
          priceWithDescuento: null,
          cantAdulEdad: 2,
          cantTeenEdad: 0,
          cantMnrEdad: 0,
          cantInfEdad: 0,
        },
      ],
    }
  },

  // COTIZADOR
  resetPagination(state) {
    state.pagination = {
      current: 1,
      total: 0,
    }
  },
  setPagination(state, current) {
    state.pagination.current = current
  },
  updatePagination(state, { current, total }) {
    state.pagination = {
      current,
      total,
    }
  },

  setShowMenuNavBarCurrency(state, value) {
    state.showMenuNavBarCurrency = value
  },
  setShowMenuNavBarMoreCurrency(state, value) {
    state.showMenuNavBarMoreCurrency = value
  },
}
