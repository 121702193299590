<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <fragment>
    <v-row>
      <v-col cols="12">
        <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('btn.edit')}`">
          <v-card-text v-if="isLoading">
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="button"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-card-text>

          <template v-if="!isLoading">
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    color="error"
                    class="mb-4 me-3"
                    @click="$router.push({ name: 'hotels-contrate-list' })"
                  >
                    <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                    <span>{{ $t('btn.back') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    :loading="loading"
                    :disabled="verifyContrate"
                    @click="save()"
                  >
                    <span>{{ btnTitle }}</span>
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  v-if="model.source.includes('hotetec')"
                  cols="12"
                  md="2"
                  class="mr-10"
                >
                  <v-btn
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="uploadLote"
                  >
                    <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
                    <span class="pl-2">{{ $t('btn.importFiles') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="model.source.includes('dingus')"
                  cols="12"
                  md="3"
                >
                  <v-btn
                    color="primary"
                    outlined
                    @click="uploadLoteDingus"
                  >
                    <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
                    <span class="pl-2">{{ $t('btn.importFiles') }}</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-col
                  v-if="
                    (
                      model.source.includes('hotetec') ||
                      model.source.includes('dingus') &&
                        permisos.includes('contrate_hotels:edit')
                    )"
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    :disabled="isLoadingInfo"
                    @click="addTarifa"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.tarifa') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="
                    (
                      !model.source.includes('hotetec') &&
                      !model.source.includes('dingus') &&
                      permisos.includes('contrate_hotels:edit')
                    )"
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    :disabled="isLoadingInfo"
                    @click="addTarifaDirect"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.tarifa') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="model.source.includes('hotetec') &&
                    permisos.includes('contrate_hotels:edit')
                  "
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="addOcupation"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.ocupations') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="model.source.includes('dingus') &&
                    permisos.includes('contrate_hotels:edit')
                  "
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="addOcupationDingus"
                  >
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    <span class="pl-2">{{ $t('lbl.ocupations') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="permisos.includes('contrate_hotels:edit')"
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="error"
                    class="mb-4 me-3"
                    outlined
                    @click="publicarHotels"
                  >
                    <v-icon>mdi-check-outline</v-icon>
                    <span class="pl-2">{{ $t('lbl.publicar') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="permisos.includes('contrate_hotels:edit')"
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    outlined
                    @click="isDialogContacts = true"
                  >
                    <v-icon>mdi-contacts</v-icon>
                    <span class="pl-2">{{ $t('lbl.contacts') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="permisos.includes('contrate_hotels:edit')"
                  cols="12"
                  md="3"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="error"
                    outlined
                    :disabled="cantTarifarios === 0"
                    @click="deleteTarifarios"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    <span class="pl-2">{{ $t('menu.deleteTarifarios') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col
                  v-if="model.proveedor_id"
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="model.identificador"
                    :label="$t('lbl.identificador')"
                    class="pt-2"
                    outlined
                    dense
                    hide-details
                    :disabled="$store.state.app.onlyShow"
                  ></v-text-field>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="2"
                >
                  <v-switch
                    v-model="model.stopSale"
                    :label="$t('lbl.stopSale')"
                    hide-details
                    class="mt-2"
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                </v-col>-->
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-if="!isLoadingCurrencies"
                    v-model="model.currency_id"
                    :items="currencies"
                    :label="$t('register.moneda')"
                    outlined
                    dense
                    item-text="code"
                    item-value="id"
                    class="pt-2"
                  ></v-select>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="card-heading"
                  ></v-skeleton-loader>
                </v-col>
                <!--v-if="sourcesAvalibled.length > 0"-->
                <v-col
                  v-if="model.source.length > 0"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    label="Fuente"
                    :value="model.source.includes('dingus') ? 'Dingus' : (model.source.includes('hotetec') ? 'Hotetec' : (model.source.includes('solways') ? 'Solways' : 'Directo'))"
                    class="pt-2"
                    outlined
                    dense
                    hide-details
                    disabled
                  ></v-text-field>
                  <!--<v-select
                    v-model="model.source"
                    :items="sourcesAvalibled"
                    label="Fuente"
                    outlined
                    dense
                    item-text="name"
                    item-value="slug"
                    multiple
                    class="pt-2"
                    hide-details="auto"
                    :hint="
                      model.source.includes('hotetec')
                        ? `Para la integración correcta con ${nameHotetec} tiene que llenar los 2 primeros acordeones de cada hotel.`
                        : model.source.includes('dingus')
                          ? `Para la integración correcta con ${nameDingus} tiene que llenar los 2 primeros acordeones de cada hotel.`
                          : ''
                    "
                    :persistent-hint="model.source.includes('hotetec') || model.source.includes('dingus')"
                  ></v-select>-->
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <template v-if="model.source.includes('dingus') || model.source.includes('hotetec')">
                    <span v-if="model.price_neto">
                      <b>Neto</b>
                    </span>
                    <span v-else>
                      <b>Comisión:</b> {{ model.pc_price_gross | number('0.00') }} %
                    </span>
                  </template>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
                    v-model="model.notificar_acceso_data"
                    label="Notificar acceso a los hoteles"
                    hide-details
                    class="mt-0"
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
                    v-model="model.notificar_acceso_disp"
                    label="Notificar envío de disponibilidad"
                    hide-details
                    class="mt-0 mb-2"
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  offset-md="2"
                  class="text-right mt-0 pt-0"
                >
                  <p class="pt-2">
                    <b>Ventas para individuales:</b>
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="mt-0 pt-0"
                >
                  <v-text-field
                    v-model="model.rooms_max"
                    label="Room máx"
                    outlined
                    dense
                    hide-details
                    @keypress="checkPax($event, model.rooms_max)"
                    @input="val => (model.rooms_max = model.rooms_max.toUpperCase())"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="mt-0 pt-0"
                >
                  <v-text-field
                    v-model="model.paxs_max"
                    label="Pax máx"
                    outlined
                    dense
                    hide-details
                    @keypress="checkPax($event, model.paxs_max)"
                    @input="val => (model.paxs_max = model.paxs_max.toUpperCase())"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="2"
                >
                  <v-switch
                    v-model="model.cancelado"
                    :label="$t('lbl.canceled')"
                    hide-details
                    class="mt-0"
                    :disabled="$store.state.app.onlyShow"
                    @click="setCanceled"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="editObservations"
                    :label="$t('lbl.observations')"
                    hide-details
                    class="mt-2 mb-2"
                    :disabled="$store.state.app.onlyShow"
                  ></v-switch>
                </v-col>
                <v-col
                  v-if="editObservations"
                  cols="12"
                  md="4"
                >
                  <v-tooltip
                    top
                    class="text-right"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="addObservationContrateHotel()"
                      >
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  v-if="editObservations"
                  cols="12"
                  md="12"
                >
                  <Observation
                    v-for="(observation, indO) in observationsHotel"
                    :key="indO"
                    :observation="observation"
                    :index="indO"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </app-card-code>
      </v-col>
    </v-row>

    <FiltrosHotels
      v-if="!isLoadingHotels"
      :user="user"
      :countries="countries"
      :operadores="operadores"
      :all-marcas-list="allMarcas"
      :hoteles-all="hotelesAll"
      :all-codes="allCodes"
      @emitSetOperadores="emitSetOperadores"
      @emitSetMarca="emitSetMarca"
      @filtersHotels="filtersHotels"
      @refresh="refresh"
    />

    <!--<v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingCountries"
            v-model="search.country"
            :items="countries"
            :label="$t('lbl.country')"
            outlined
            dense
            hide-details
            @change="changeCountry"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.country') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingStates"
            v-model="search.state"
            :items="states"
            :label="$t('lbl.provinceEstado')"
            outlined
            dense
            hide-details
            @change="changeState"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.provinceEstado') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingCities"
            v-model="search.city"
            :items="cities"
            :label="$t('lbl.locality')"
            outlined
            dense
            hide-details
            @change="changeCity"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.locality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingOperadors"
            v-model="search.operador_id"
            :items="itemsOperador"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setOperadores"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingMarcas"
            v-model="search.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setMarca"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingHotels"
            v-model="search.hotel_id"
            :items="itemsHotel"
            :search-input.sync="searchHotel"
            hide-details
            hide-selected
            :label="$t('menu.hotels')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.hotels') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-if="!isLoadingSources"
            v-model="search.code"
            :items="codesHotel"
            :search-input.sync="searchCodesHotel"
            hide-details
            hide-selected
            label="Code"
            outlined
            dense
            item-text="code"
            @change="setCodesHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Code
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.code"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.code }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-form>-->

    <v-divider class="mb-4"></v-divider>
    <template v-if="!isLoadingHotels">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              search.country ||
                search.state ||
                search.city ||
                search.operador_id ||
                search.marca_asociada_id ||
                search.code ||
                search.hotel_id ||
                search.sin_rooms ||
                search.sin_publicar ||
                search.sin_ocupation ||
                search.sin_plan ||
                search.sin_contact ||
                search.sin_price ||
                search.vence_3 ||
                search.vence_6
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-left"
        >
          <v-tooltip
            v-if="!$store.state.app.onlyShow"
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addHotels()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('lbl.addHotels') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-left"
        >
          <v-btn
            v-if="!$store.state.app.onlyShow && (model.source.includes('hotetec') || model.source.includes('dingus'))"
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="sendCodes"
          >
            <v-icon>mdi-email-send-outline</v-icon>
            <span class="pl-2">{{ $t('lbl.gestionCodes', { n: model.source.includes('hotetec') ? 'Hotetec' : (model.source.includes('dingus') ? 'Dingus' : '') }) }}</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          v-if="!isLoadingInfo"
          cols="12"
          md="2"
        >
          {{ hotels.length }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.hotels').toLowerCase() }}
        </v-col>
        <v-col
          v-if="isLoadingInfo"
          cols="12"
          md="4"
        >
          <v-progress-circular
            :size="20"
            indeterminate
            color="primary"
            class="mr-2"
          ></v-progress-circular>
          {{ $t('lbl.loadingContrate') }}
        </v-col>
      </v-row>
    </template>
    <!--<template v-if="isLoadingInfoPend">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-left text-lowercase"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>-->

    <template v-if="totalInfoPendiente > 0 || totalSinPrice > 0 || totalVence > 0">
      <v-divider class="mb-4 mt-4"></v-divider>
      <v-row>
        <v-col
          v-if="totalInfoPendiente > 0"
          cols="12"
          md="4"
          class="text-center text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="showInfoPendient ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!showInfoPendient ? 'error' : ''"
            @click="infoPendient"
          >
            {{ totalInfoPendiente }} {{ $t('lbl.infoPendiente') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="totalSinPrice > 0"
          cols="12"
          md="2"
          class="text-left text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="search.sin_price ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.sin_price ? 'error' : ''"
            @click="
              search.sin_price = !search.sin_price
              filtersHotels()
            "
          >
            {{ totalSinPrice }} {{ $t('lbl.sinPrice') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="totalVence > 0"
          cols="12"
          md="4"
          class="text-center text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="showVence ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!showVence ? 'error' : ''"
            @click="setVence"
          >
            {{ totalVence }} {{ $t('lbl.vencimiento') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="totalSinContact > 0"
          cols="12"
          md="2"
          class="text-left text-lowercase"
        >
          <v-btn
            class="text-lowercase mt-2 me-3"
            outlined
            rounded
            small
            :style="search.sin_contact ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.sin_contact ? 'error' : ''"
            @click="
              search.sin_contact = !search.sin_contact
              filtersHotels()
            "
          >
            {{ totalSinContact }} {{ $t('lbl.sinContact') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showInfoPendient">
        <v-col
          cols="12"
          md="12"
          class="text-left text-lowercase"
        >
          <v-btn
            v-if="totalSinRooms > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="search.sin_rooms ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.sin_rooms ? 'error' : ''"
            @click="
              search.sin_rooms = !search.sin_rooms
              filtersHotels()
            "
          >
            {{ totalSinRooms }} {{ $t('lbl.sinRooms') }}
          </v-btn>

          <v-btn
            v-if="totalSinPublicar > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="search.sin_publicar ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.sin_publicar ? 'error' : ''"
            @click="
              search.sin_publicar = !search.sin_publicar
              filtersHotels()
            "
          >
            {{ totalSinPublicar }} {{ $t('lbl.sinPublicar') }}
          </v-btn>

          <v-btn
            v-if="totalSinOcupation > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="search.sin_ocupation ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.sin_ocupation ? 'error' : ''"
            @click="
              search.sin_ocupation = !search.sin_ocupation
              filtersHotels()
            "
          >
            {{ totalSinOcupation }} {{ $t('lbl.sinOcupation') }}
          </v-btn>

          <v-btn
            v-if="totalSinPlan > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="search.sin_plan ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.sin_plan ? 'error' : ''"
            @click="
              search.sin_plan = !search.sin_plan
              filtersHotels()
            "
          >
            {{ totalSinPlan }} {{ $t('lbl.sinPlan') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showVence">
        <v-col
          cols="12"
          md="12"
          class="text-left text-lowercase"
        >
          <v-btn
            v-if="totalVence3 > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="search.vence_3 ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.vence_3 ? 'error' : ''"
            @click="
              search.vence_3 = !search.vence_3
              filtersHotels()
            "
          >
            {{ totalVence3 }} {{ $t('lbl.vencimiento3') }}
          </v-btn>
          <v-btn
            v-if="totalVence6 > 0"
            class="mx-2 text-lowercase"
            outlined
            rounded
            small
            :style="search.vence_6 ? 'background-color: red; color: #FFFFFF;' : ''"
            :color="!search.vence_6 ? 'error' : ''"
            @click="
              search.vence_6 = !search.vence_6
              filtersHotels()
            "
          >
            {{ totalVence6 }} {{ $t('lbl.vencimiento6') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-divider class="mb-4 mt-4"></v-divider>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <template v-if="!isLoadingHotels">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-uppercase"
                    width="40%"
                  >
                    {{ $t('lbl.name') }}
                  </th>
                  <!--<th
                    v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
                    class="text-uppercase"
                    width="5%"
                  >
                    Code
                  </th>-->
                  <th
                    class="text-uppercase"
                    width="5%"
                  >
                    Code
                  </th>
                  <th class="text-uppercase">
                    {{ $t('lbl.destino') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('menu.typeDestination') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('menu.operador') }}
                  </th>
                  <th
                    v-if="permisos.includes('hotel:edit') || permisos.includes('hotel:list')"
                    width="150px"
                    class="text-uppercase"
                    style="text-align: center;"
                  >
                    {{ $t('lbl.actions') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <fragment
                  v-for="(iten, indP) in hotelsPagination"
                  :key="indP"
                >
                  <tr v-if="iten.id === iten.id">
                    <td>
                      <NameHotelStar :hotel="iten" />
                    </td>
                    <!--<td v-if="model.source.includes('hotetec') || model.source.includes('dingus')">
                      {{ iten.code_hotel_externo }}
                    </td>-->
                    <td>{{ iten.code_hotel_externo }}</td>
                    <td>
                      {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                      <span v-if="iten.country">{{ iten.country }}</span>
                    </td>
                    <td>
                      <span v-if="iten.tipo_destino_id">
                        {{ iten.tipo_destino.name }}
                      </span>
                    </td>
                    <td>
                      <span v-if="iten.operador_id">
                        {{ iten.operador.name }}
                      </span>
                      <!--<span v-if="iten.marca_asociada_id">
                        <br />
                        {{ iten.marca_asociada.name }}
                      </span>-->
                    </td>
                    <td
                      v-if="
                        permisos.includes('contrate_hotels:edit') ||
                          permisos.includes('contrate_hotels:list') ||
                          permisos.includes('contrate_hotels:destroy')
                      "
                      style="text-align: center;"
                    >
                      <v-menu
                        bottom
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            v-if="permisos.includes('contrate_hotels:edit') && !$store.state.app.onlyShow"
                            link
                            @click="editItem(iten)"
                          >
                            <v-list-item-title>
                              <v-btn icon>
                                <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                              </v-btn>
                              <span>{{ $t('btn.edit') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="permisos.includes('contrate_hotels:list') || permisos.includes('*')"
                            link
                            @click="showItem(iten)"
                          >
                            <v-list-item-title>
                              <v-btn icon>
                                <v-icon>{{ icons.mdiEye }}</v-icon>
                              </v-btn>
                              <span>{{ $t('btn.see') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="permisos.includes('contrate_hotels:destroy') && !$store.state.app.onlyShow"
                            link
                            @click="deleteItem(iten)"
                          >
                            <v-list-item-title>
                              <v-btn icon>
                                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                              </v-btn>
                              <span>{{ $t('btn.delete') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </fragment>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text class="pt-2">
            <v-row>
              <v-col
                lg="12"
                cols="12"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="pagination.current"
                  :length="pagination.total"
                  total-visible="5"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          type="table-tbody"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- MOSTRAR DELETE CONTRATE-->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { n: hotelDelete.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ASOCIAR CONTACTOS-->
    <v-dialog
      v-model="isDialogContacts"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('lbl.contacts')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('lbl.contacts1') }}
          <br />
          <br />
          {{ $t('lbl.contacts2') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            :disabled="loading"
            @click="isDialogContacts = !isDialogContacts"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmAsociarContacts()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--SEND CODES-->
    <SidebarCodesSend
      v-if="isSidebarSendActive"
      v-model="isSidebarSendActive"
      :name-source="model.source.includes('hotetec') ? 'Hotetec' : (model.source.includes('dingus') ? 'Dingus' : '')"
      :source="model.source.includes('hotetec') ? 'hotetec' : (model.source.includes('dingus') ? 'dingus' : '')"
      :proveedor="proveedor"
      :user="user"
    />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiCheckUnderline,
  mdiMinus,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiCloudUpload,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import Observation from '@/views/contratos/hotels/utils/Observation.vue'
import NameHotelStar from '../utils/NameHotelStar.vue'
// eslint-disable-next-line import/no-unresolved
import SidebarCodesSend from '../utils/SidebarCodesSend.vue'
import FiltrosHotels from './FiltrosHotels.vue'

export default {
  components: {
    AppCardCode,
    // eslint-disable-next-line vue/no-unused-components
    NameHotelStar,
    Observation,
    SidebarCodesSend,
    FiltrosHotels,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: false,
      isLoadingInfo: true,
      isLoadingHotels: false,
      isLoadingCurrencies: true,
      isLoadingCountries: true,
      isLoadingStates: true,
      isLoadingCities: true,
      isLoadingOperadors: true,
      isLoadingMarcas: true,
      isLoadingSources: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isDialogContacts: false,
      isLoadingPrices: true,
      isLoadingInfoPend: true,
      showInfoPendient: false,
      showVence: false,
      totalInfoPendienteOrigin: 0,
      totalSinRoomsOrigin: 0,
      totalSinPublicarOrigin: 0,
      totalSinOcupationOrigin: 0,
      totalSinPlanOrigin: 0,
      totalSinPriceOrigin: 0,
      totalSinContactOrigin: 0,
      totalVenceOrigin: 0,
      totalVence3Origin: 0,
      totalVence6Origin: 0,
      totalInfoPendiente: 0,
      idsSinRooms: [],
      totalSinRooms: 0,
      totalSinPublicar: 0,
      idsSinPublicar: [],
      idsSinOcupation: [],
      totalSinOcupation: 0,
      idsSinPlan: [],
      totalSinPlan: 0,
      idsSinPrice: [],
      totalSinPrice: 0,
      idsSinContact: [],
      totalSinContact: 0,
      totalVence: 0,
      idsVence3: [],
      totalVence3: 0,
      idsVence6: [],
      totalVence6: 0,
      filters: {
        sin_rooms: false,
        sin_ocupation: false,
        sin_plan: false,
        sin_price: false,
        vence_3: false,
        vence_6: false,
        sin_contact: false,
      },
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiCheckUnderline,
        mdiMinus,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiCloudUpload,
      },
      model: {
        hotels_pactados: [],
        rooms_max: 5,
        paxs_max: 15,
        notificar_acceso_data: false,
        notificar_acceso_disp: false,
        source: [],
      },
      sources: [],
      sourcesAvalibled: [],
      operadoresApiExternHotetec: [],
      operadoresApiExternDingus: [],
      hotelDelete: {
        id: null,
        name: null,
      },
      loading: false,
      loadingSeason: true,
      isUpdatePrices: false,
      pagination: {
        current: 1,
        total: 0,
      },

      hotels: [],
      search: {}, /* {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
        sin_rooms: false,
        sin_ocupation: false,
        sin_plan: false,
        sin_price: false,
        vence_3: false,
        vence_6: false,
        sin_contact: false,
      } */
      countries: [],
      states: [],
      cities: [],
      operadores: [],
      itemsOperador: [],
      searchOperador: null,
      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,
      searchHotel: null,
      itemsHotel: [],
      codesHotel: [],
      allCodes: [],
      allHotel: [],
      searchCodesHotel: null,
      totalItems: 0,
      totalF: 0,

      itemsPerPage: 10,
      hotelsNomAll: [],
      roomsNomAll: [],
      regimenNomAll: [],
      itemsRooms: [],
      searchRooms: null,
      seasonsContrate: [],
      hotel_pactado: null,
      room_hotel: null,
      season_hotel: [],
      rooms_hotel: [],
      regimen_hotel: 0,
      cantPaxs: 3,
      currencies: [],
      isDialogVisibleDelete: false,
      permisos: localStorage.getItem('permisos_auth'),
      editObservations: false,
      rooms_max: [],
      paxs_max: [],
      isSidebarSendActive: false,
      nameSource: '',
      proveedor: null,
      user: null,
      nameHotetec: null,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      cantTarifarios: 0,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      temporadasContrateHotel: state => state.app.temporadasContrateHotel,
      hotelsAsociadosTemporadas: state => state.app.hotelsAsociadosTemporadas,
      idsHotelsAsociadosTemporadas: state => state.app.idsHotelsAsociadosTemporadas,
      pricesHotelContrate: state => state.app.pricesHotelContrate,
      onlyShow: state => state.app.onlyShow,
      observationsHotel: state => state.app.observationsHotel,
      emailsCodes: state => state.app.emailsCodes,
      hotelsContrateShowCache: state => state.app.hotelsContrateShowCache,
      hotelsProductListCache: state => state.app.hotelsProductListCache,
    }),
    hotelesAll() {
      const hPactados = []
      this.hotelsNomAll.forEach(hote => {
        // this.idsHotelsAsociadosTemporadas.forEach(hAsoc => {
        this.model.hotels_pactados.forEach(hAsoc => {
          if (hote.id === hAsoc) {
            // eslint-disable-next-line camelcase
            let code_hotel_externo = null
            // eslint-disable-next-line camelcase
            let season_id = null
            // eslint-disable-next-line camelcase
            let stop_sale = false
            let publicSeason = false
            this.model.seasons.forEach(seasonModel => {
              if (seasonModel.hotels.includes(hAsoc)) {
                // eslint-disable-next-line camelcase
                code_hotel_externo = seasonModel.code_hotel_externo
                // eslint-disable-next-line camelcase
                season_id = seasonModel.id
                // eslint-disable-next-line camelcase
                stop_sale = seasonModel.stopSale
                publicSeason = seasonModel.publico
              }
            })
            hPactados.push({
              ...hote,
              season_id,
              code_hotel_externo,
              stop_sale,
              publicSeason,
            })
          }
        })
      })

      return hPactados
    },
    hotelsPagination() {
      const arr = []
      let index = 0
      if (this.pagination.current > 1) {
        index = parseInt(this.itemsPerPage * (this.pagination.current - 1), 10)
      }
      const cant = parseInt(index + this.itemsPerPage, 10)

      const hPactados = []
      this.hotels.forEach(hote => {
        // this.idsHotelsAsociadosTemporadas.forEach(hAsoc => {
        this.model.hotels_pactados.forEach(hAsoc => {
          if (hote.id === hAsoc) {
            // eslint-disable-next-line camelcase
            let code_hotel_externo = null
            // eslint-disable-next-line camelcase
            let season_id = null
            // eslint-disable-next-line camelcase
            let stop_sale = false
            let publicSeason = false
            this.model.seasons.forEach(seasonModel => {
              if (seasonModel.hotels.includes(hAsoc)) {
                // eslint-disable-next-line camelcase
                code_hotel_externo = seasonModel.code_hotel_externo
                // eslint-disable-next-line camelcase
                season_id = seasonModel.id
                // eslint-disable-next-line camelcase
                stop_sale = seasonModel.stopSale
                publicSeason = seasonModel.publico
              }
            })
            hPactados.push({
              ...hote,
              season_id,
              code_hotel_externo,
              stop_sale,
              publicSeason,
            })
          }
        })
      })

      // eslint-disable-next-line no-plusplus
      for (index; index < cant && index < hPactados.length; index++) {
        arr.push(hPactados[index])
      }

      return arr
    },
    verifyContrate() {
      let disabled = true
      const jsonVerify = localStorage.getItem('verify-contrate')
      const jsonContrate = JSON.stringify({
        identificador: this.model.identificador,
        cancelado: !!this.model.cancelado,
        currency_id: this.model.currency_id,
        observations: this.observationsHotel,
      })

      if (jsonVerify !== jsonContrate) {
        disabled = false
      }

      return disabled
    },
  },

  /* watch: {
    searchOperador(val) {
      if (val !== null && val.length > 1) {
        this.filterOperador(val.toLowerCase())
      }
    },
    searchMarcas(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      }
    },
    searchHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      }
    },
    searchCodesHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterCodesHotel(val.toLowerCase())
      }
    },
  }, */
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    // eslint-disable-next-line no-empty
    if (localStorage.getItem('hotels-contrate-id') !== null) { } else {
      this.$router.push({ name: 'hotels-contrate-list' })
    }

    if (this.hotelsContrateShowCache.id) {
      this.model = this.hotelsContrateShowCache
      this.hotels = this.hotelsProductListCache
    }

    this.profile()
    this.fetchDataFromEndpoints()

    if (localStorage.getItem('contrate-hotels-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    let i = 1
    while (i <= 30) {
      this.rooms_max.push(i)
      // eslint-disable-next-line no-plusplus
      i++
    }

    let j = 1
    while (i <= 30) {
      this.paxs_max.push(j)
      // eslint-disable-next-line no-plusplus
      j++
    }
  },

  methods: {
    ...mapMutations([
      'setOnlyShow',
      'updateHotelesPactadosContrateHotel',
      'addTemporadaContrateHotel',
      'updateIdsHotelsContrateHotel',
      'updateTemporadaContrateHotel',
      'updateCantPaxsContrate',
      'updatePricesHotelContrate',
      'addPricesHotelContrate',
      'updateSeasonContrateHotel',
      'updateRoomSeasonContrateHotel',
      'updateSuplementSeasonContrateHotel',
      'updateRangeAgeContrateHotel',
      'updateObservationContrateHotel',
      'addObservationContrateHotel',
      'addEmailCode',
      'emptyEmailCode',
    ]),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))

      /* this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        }) */
    },
    async fetchDataFromEndpoints() {
      const jsonHotels = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'marca_asociada_id',
          'categoria_id',
          'tipo_destino_id',
          'sugerido',
          'number_sugerido',
          'checkIn',
          'checkOut',
          'direccion',
          'publicado',
          'cant_departament',
        ],
      }
      const jsonCantPend = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        search: this.search,
      }
      this.sources.push({
        name: 'Directo',
        slug: 'directo',
      })
      const id = localStorage.getItem('hotels-contrate-id')

      try {
        const [
          resHotels,
          resItem,
          resOperadores,
          resCurrencies,
          resCantPend,
          resHotetec,
          resDingus,
        ] = await Promise.all([
          this.axios
            .post('hotels/list', jsonHotels, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`contrate_hotels/${id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios.get('currency_list?per_page=10000'),
          this.axios
            .post('contrate_hotels/cant-pending-season', jsonCantPend, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post(
              'api_extern/show',
              { slug: 'hotetecapi' },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
          this.axios
            .post(
              'api_extern/show',
              { slug: 'dingusapi' },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
        ])

        // eslint-disable-next-line no-empty
        if (resHotels.data.success === false) { } else {
          this.hotelsNomAll = resHotels.data.data
          this.getCountries()
          this.model = resItem.data.data.data
          this.cantTarifarios = resItem.data.data.cantTarifarios
          this.changeProveedor()
          if (this.model.rooms_max === 0) {
            this.model.rooms_max = 5
          }
          this.emptyEmailCode()
          if (this.model.proveedor_id) {
            this.proveedor = this.model.proveedor
            if (this.proveedor.contacts) {
              if (this.proveedor.contacts.length > 0) {
                this.proveedor.contacts.forEach(ccc => {
                  ccc.emails.forEach(cccEma => {
                    this.addEmailCode(cccEma.email)
                  })
                })
              }
            }
            if (this.emailsCodes.length === 0) {
              this.addEmailCode(null)
            }
          }

          this.hotels = this.hotelesAll
          this.allHotel = this.hotels
          this.allHotel.forEach(elementH => {
            this.allCodes.push({
              code: elementH.code_hotel_externo,
            })
          })
          this.totalItems = this.model.hotels_pactados.length

          // PARA LA PAGINACION
          this.recalculeTotalPagination()

          const jsonVerifyUpdate = {
            identificador: this.model.identificador,
            cancelado: !!this.model.cancelado,
            observations: [],
            currency_id: this.model.currency_id,
          }

          if (this.model.observations) {
            this.updateObservationContrateHotel(this.model.observations)
            jsonVerifyUpdate.observations = this.model.observations
          } else {
            this.updateObservationContrateHotel([])
          }
          this.isLoading = false
          localStorage.setItem('verify-contrate', JSON.stringify(jsonVerifyUpdate))

          // this.getItem()
        }

        this.operadores = resOperadores.data.data

        this.currencies = resCurrencies.data.data

        if (resCantPend.data.success === true) {
          this.totalInfoPendiente = resCantPend.data.data.totalInfoPend
          this.totalInfoPendienteOrigin = resCantPend.data.data.totalInfoPend
          this.totalSinRooms = resCantPend.data.data.totalSinRooms
          this.totalSinRoomsOrigin = resCantPend.data.data.totalSinRooms
          this.idsSinRooms = resCantPend.data.data.idsSinRooms
          this.totalSinPublicar = resCantPend.data.data.totalSinPublicar
          this.totalSinPublicarOrigin = resCantPend.data.data.totalSinPublicar
          this.idsSinPublicar = resCantPend.data.data.idsSinPublicar
          this.totalSinOcupation = resCantPend.data.data.totalSinOcupation
          this.totalSinOcupationOrigin = resCantPend.data.data.totalSinOcupation
          this.idsSinOcupation = resCantPend.data.data.idsSinOcupation
          this.totalSinPlan = resCantPend.data.data.totalSinPlan
          this.totalSinPlanOrigin = resCantPend.data.data.totalSinPlan
          this.idsSinPlan = resCantPend.data.data.idsSinPlan
          this.totalSinPrice = resCantPend.data.data.totalSinPrice
          this.totalSinPriceOrigin = resCantPend.data.data.totalSinPrice
          this.idsSinPrice = resCantPend.data.data.idsSinPrice
          this.totalSinContact = resCantPend.data.data.totalSinContact
          this.totalSinContactOrigin = resCantPend.data.data.totalSinContact
          this.idsSinContact = resCantPend.data.data.idsSinContact
          this.totalVence = resCantPend.data.data.totalVence
          this.totalVenceOrigin = resCantPend.data.data.totalVence
          this.totalVence3 = resCantPend.data.data.totalVence3
          this.totalVence3Origin = resCantPend.data.data.totalVence3
          this.idsVence3 = resCantPend.data.data.idsVence3
          this.totalVence6 = resCantPend.data.data.totalVence6
          this.totalVence6Origin = resCantPend.data.data.totalVence6
          this.idsVence6 = resCantPend.data.data.idsVence6
        }

        if (resHotetec.data.status) {
          if (resHotetec.data.data.proveedores) {
            this.operadoresApiExternHotetec = resHotetec.data.data.proveedores
          }
        }

        if (resDingus.data.success) {
          if (resDingus.data.data.proveedores) {
            this.operadoresApiExternDingus = resDingus.data.data.proveedores
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        this.isLoadingHotels = false
        this.isLoadingOperadors = false
        this.isLoadingMarcas = false
        this.isLoadingCurrencies = false
        this.isLoadingInfoPend = false
        this.isLoadingSources = false
        this.isLoadingInfo = false
      }
    },
    getCountries() {
      this.hotelsNomAll.forEach(hotelsNom => {
        if (!this.countries.includes(hotelsNom.country)) {
          if (hotelsNom.country) {
            this.countries.push(hotelsNom.country)
          }
        }
      })
      setTimeout(() => {
        this.isLoadingCountries = false
        this.isLoadingStates = false
        this.isLoadingCities = false
      }, 100)
    },
    async getItem() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const id = localStorage.getItem('hotels-contrate-id')
        await this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.cantTarifarios = res.data.data.cantTarifarios
            this.changeProveedor()
            if (this.model.rooms_max === 0) {
              this.model.rooms_max = 5
            }
            this.emptyEmailCode()
            if (this.model.proveedor_id) {
              this.proveedor = this.model.proveedor
              if (this.proveedor.contacts) {
                if (this.proveedor.contacts.length > 0) {
                  this.proveedor.contacts.forEach(ccc => {
                    ccc.emails.forEach(cccEma => {
                      this.addEmailCode(cccEma.email)
                    })
                  })
                }
              }
              if (this.emailsCodes.length === 0) {
                this.addEmailCode(null)
              }
            }

            this.hotels = this.hotelesAll
            this.allHotel = this.hotels
            this.allHotel.forEach(elementH => {
              this.allCodes.push({
                code: elementH.code_hotel_externo,
              })
            })
            this.totalItems = this.model.hotels_pactados.length

            // PARA LA PAGINACION
            this.recalculeTotalPagination()

            const jsonVerifyUpdate = {
              identificador: this.model.identificador,
              cancelado: !!this.model.cancelado,
              observations: [],
              currency_id: this.model.currency_id,
            }

            if (this.model.observations) {
              this.updateObservationContrateHotel(this.model.observations)
              jsonVerifyUpdate.observations = this.model.observations
            } else {
              this.updateObservationContrateHotel([])
            }

            localStorage.setItem('verify-contrate', JSON.stringify(jsonVerifyUpdate))

            // this.updateHotelesPactadosContrateHotel(this.model.hotels_pactados)
          })
          .finally(() => {
            this.isLoading = false

            // this.getSources()
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },

    deleteTarifarios() {
      this.$router.push({ name: 'hotels-contrate-hotels-delete-tarifarios' })
    },
    publicarHotels() {
      this.$router.push({ name: 'hotels-contrate-hotels-public' })
    },
    addTarifa() {
      this.$router.push({ name: 'hotels-contrate-hotels-add-tarifa' })
    },
    addOcupation() {
      this.$router.push({ name: 'hotels-contrate-hotels-add-ocupation' })
    },
    addOcupationDingus() {
      this.$router.push({ name: 'hotels-contrate-hotels-add-ocupation-dingus' })
    },
    addTarifaDirect() {
      this.$router.push({ name: 'hotels-contrate-hotels-add-tarifa-direct' })
    },
    addHotels() {
      this.$router.push({ name: 'hotels-contrate-hotels-asociar' })
    },
    showItem(iten) {
      localStorage.setItem('hotel-contrato-show', true)
      localStorage.setItem('hotels-id', iten.id)

      /* this.updateSeasonContrateHotel([])
      this.updateRoomSeasonContrateHotel([])
      this.updateSuplementSeasonContrateHotel([])
      this.updateRangeAgeContrateHotel({
        adults_min_years: null,
        childs_max_years: null,
        infant_max_years: null,
      }) */

      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    editItem(iten) {
      localStorage.removeItem('hotel-contrato-show')
      localStorage.setItem('hotels-id', iten.id)
      localStorage.setItem('hotels-contrate-temporada_id', iten.id)
      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.hotelDelete.id = iten.id
      this.hotelDelete.name = iten.name
    },
    sendCodes() {
      this.isSidebarSendActive = true
    },
    uploadLote() {
      localStorage.setItem('contrate-hotels-id', localStorage.getItem('hotels-contrate-id'))
      this.$router.push({ name: 'contrate-hotels-import-files' })
    },
    uploadLoteDingus() {
      localStorage.setItem('contrate-hotels-id', localStorage.getItem('hotels-contrate-id'))
      this.$router.push({ name: 'contrate-hotels-import-files-dingus' })
    },
    infoPendient() {
      this.showInfoPendient = !this.showInfoPendient
    },
    setVence() {
      this.showVence = !this.showVence
    },
    changeTags() {
      this.hotels = this.hotelesAll

      // FILTRAR
      // SIN ROOMS
      const hotelsSinRooms = []
      this.hotels.forEach(element => {
        if (this.filters.sin_rooms) {
          if (this.idsSinRooms.includes(element.id)) {
            hotelsSinRooms.push(element)
          }
        } else {
          hotelsSinRooms.push(element)
        }
      })
      this.hotels = hotelsSinRooms

      // SIN OCUPATIONS
      const hotelsSinOcupation = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_ocupation) {
          if (this.idsSinOcupation.includes(element.id)) {
            hotelsSinOcupation.push(element)
          }
        } else {
          hotelsSinOcupation.push(element)
        }
      })
      this.hotels = hotelsSinOcupation

      // SIN PLAN
      const hotelsSinPlan = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_plan) {
          if (this.idsSinPlan.includes(element.id)) {
            hotelsSinPlan.push(element)
          }
        } else {
          hotelsSinPlan.push(element)
        }
      })
      this.hotels = hotelsSinPlan

      // SIN PRICE
      const hotelsSinPrice = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_price) {
          if (this.idsSinPrice.includes(element.id)) {
            hotelsSinPrice.push(element)
          }
        } else {
          hotelsSinPrice.push(element)
        }
      })
      this.hotels = hotelsSinPrice

      // SIN CONTACT
      const hotelsSinContact = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.sin_contact) {
          if (this.idsSinContact.includes(element.id)) {
            hotelsSinContact.push(element)
          }
        } else {
          hotelsSinContact.push(element)
        }
      })
      this.hotels = hotelsSinContact

      // VENCE 3
      const hotelsVence3 = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.vence_3) {
          if (this.idsVence3.includes(element.id)) {
            hotelsVence3.push(element)
          }
        } else {
          hotelsVence3.push(element)
        }
      })
      this.hotels = hotelsVence3

      // VENCE 6
      const hotelsVence6 = []
      this.hotels.forEach(element => {
        // FILTROS
        if (this.filters.vence_6) {
          if (this.idsVence6.includes(element.id)) {
            hotelsVence6.push(element)
          }
        } else {
          hotelsVence6.push(element)
        }
      })
      this.hotels = hotelsVence6

      this.recalculeTotalPagination()
    },
    refresh(json) {
      this.search = json
      this.pagination = {
        current: 1,
        total: 0,
      }
      this.filtersHotels()
    },
    changeProveedor() {
      this.sourcesAvalibled = []
      if (this.model.proveedor_id) {
        if (this.operadoresApiExternHotetec.filter(e => e === this.model.proveedor_id).length > 0) {
          this.sources.forEach(element => {
            if (element.slug === 'hotetec') {
              this.sourcesAvalibled.push(element)
            }
          })
        }
        if (this.operadoresApiExternDingus.filter(e => e === this.model.proveedor_id).length > 0) {
          this.sources.forEach(element => {
            if (element.slug === 'dingus') {
              this.sourcesAvalibled.push(element)
            }
          })
        }
      } else {
        this.sourcesAvalibled = []
      }
    },
    checkPax(elEvento, model) {
      const numeros = '0123456789'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // NO CUMPLE CON LOS NUMEROS
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      else if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }
    },
    emitSetOperadores(json) {
      this.search = json
      this.filtersHotels()
      this.recalculeTotalPagination()
    },
    emitSetMarca(json) {
      this.search = json
      this.hotels = []
      this.filtersHotels()
    },
    filtersHotels() {
      this.pagination.current = 1
      this.hotels = []

      this.hotelesAll.forEach(hotel => {
        let introducir = true
        if (this.search.operador_id) {
          if (this.search.operador_id !== hotel.operador_id) {
            introducir = false
          }
        }
        if (this.search.marca_asociada_id) {
          if (this.search.marca_asociada_id !== hotel.marca_asociada_id) {
            introducir = false
          }
        }
        if (this.search.hotel_id) {
          if (this.search.hotel_id !== hotel.id) {
            introducir = false
          }
        }
        if (this.search.country) {
          if (this.search.country !== hotel.country) {
            introducir = false
          }
        }
        if (this.search.state) {
          if (this.search.state !== hotel.state) {
            introducir = false
          }
        }
        if (this.search.city) {
          if (this.search.city !== hotel.city) {
            introducir = false
          }
        }
        if (this.search.code) {
          if (this.search.code !== hotel.code_hotel_externo) {
            introducir = false
          }
        }

        // TAGS
        // SIN ROOMS
        if (this.search.sin_rooms) {
          if (!this.idsSinRooms.includes(hotel.id)) {
            introducir = false
          }
        }

        // SIN PUBLICAR
        if (this.search.sin_publicar) {
          if (!this.idsSinPublicar.includes(hotel.id)) {
            introducir = false
          }
        }

        // SIN OCUPACION
        if (this.search.sin_ocupation) {
          if (!this.idsSinOcupation.includes(hotel.id)) {
            introducir = false
          }
        }

        // SIN PLAN
        if (this.search.sin_plan) {
          if (!this.idsSinPlan.includes(hotel.id)) {
            introducir = false
          }
        }

        // SIN PRICE
        if (this.search.sin_price) {
          if (!this.idsSinPrice.includes(hotel.id)) {
            introducir = false
          }
        }

        // VENCE 3 MESES
        if (this.search.vence_3) {
          if (!this.idsVence3.includes(hotel.id)) {
            introducir = false
          }
        }

        // VENCE 6 MESES
        if (this.search.vence_6) {
          if (!this.idsVence6.includes(hotel.id)) {
            introducir = false
          }
        }

        // SIN CONTACT
        if (this.search.sin_contact) {
          if (!this.idsSinContact.includes(hotel.id)) {
            introducir = false
          }
        }

        if (introducir) {
          this.hotels.push(
            hotel,
          )
        }
      })

      // RECALCULAR LOS NUMERO DE LOS TAGS
      const idsInfoPend = []
      const idsInfoVence = []
      this.totalInfoPendiente = 0
      this.totalSinRooms = 0
      this.totalSinPublicar = 0
      this.totalSinOcupation = 0
      this.totalSinPlan = 0
      this.totalSinPrice = 0
      this.totalSinContact = 0
      this.totalVence = 0
      this.totalVence3 = 0
      this.totalVence6 = 0

      this.hotels.forEach(hotel => {
        // SIN ROOMS
        if (this.idsSinRooms.includes(hotel.id)) {
          this.totalSinRooms += 1
          if (!idsInfoPend.includes(hotel.id)) {
            idsInfoPend.push(hotel.id)
            this.totalInfoPendiente += 1
          }
        }

        // SIN PUBLICAR
        if (this.idsSinPublicar.includes(hotel.id)) {
          this.totalSinPublicar += 1
          if (!idsInfoPend.includes(hotel.id)) {
            idsInfoPend.push(hotel.id)
            this.totalInfoPendiente += 1
          }
        }

        // SIN OCUPATIONS
        if (this.idsSinOcupation.includes(hotel.id)) {
          this.totalSinOcupation += 1
          if (!idsInfoPend.includes(hotel.id)) {
            idsInfoPend.push(hotel.id)
            this.totalInfoPendiente += 1
          }
        }

        // SIN PLAN ALIM
        if (this.idsSinPlan.includes(hotel.id)) {
          this.totalSinPlan += 1
          if (!idsInfoPend.includes(hotel.id)) {
            idsInfoPend.push(hotel.id)
            this.totalInfoPendiente += 1
          }
        }

        // SIN CONTACT
        if (this.idsSinContact.includes(hotel.id)) {
          this.totalSinContact += 1
        }

        // SIN PRICE
        if (this.idsSinPrice.includes(hotel.id)) {
          this.totalSinPrice += 1
        }

        // VENCE 3
        if (this.idsVence3.includes(hotel.id)) {
          this.totalVence3 += 1
          if (!idsInfoVence.includes(hotel.id)) {
            idsInfoVence.push(hotel.id)
            this.totalVence += 1
          }
        }

        // VENCE 6
        if (this.idsVence6.includes(hotel.id)) {
          this.totalVence6 += 1
          if (!idsInfoVence.includes(hotel.id)) {
            idsInfoVence.push(hotel.id)
            this.totalVence += 1
          }
        }
      })

      this.recalculeTotalPagination()
    },
    recalculeTotalPagination() {
      // PARA LA PAGINACION
      this.pagination.total = parseInt(Math.ceil(this.hotels.length / this.itemsPerPage), 10)
    },
    clearSearch() {
      this.pagination.current = 1
      this.hotels = this.hotelesAll
      this.allHotel = this.hotels
      this.states = []
      this.cities = []
      this.search = {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      }

      this.totalInfoPendiente = this.totalInfoPendienteOrigin
      this.totalSinRooms = this.totalSinRoomsOrigin
      this.totalSinPublicar = this.totalSinPublicarOrigin
      this.totalSinOcupation = this.totalSinOcupationOrigin
      this.totalSinPlan = this.totalSinPlanOrigin
      this.totalSinPrice = this.totalSinPriceOrigin
      this.totalSinContact = this.totalSinContactOrigin
      this.totalVence = this.totalVenceOrigin
      this.totalVence3 = this.totalVence3Origin
      this.totalVence6 = this.totalVence6Origin

      this.recalculeTotalPagination()
    },
    async confirmDelete() {
      this.loading = true
      await this.axios
        .post(
          `contrate_hotels/delete-hotel/${localStorage.getItem('hotels-contrate-id')}`,
          { hotel_id: this.hotelDelete.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.getItem()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.hotelDelete = {
        id: null,
        name: null,
      }
      this.loading = false
    },

    async save() {
      const json = {
        identificador: this.model.identificador,
        publicado: !this.model.stopSale,
        cancelado: this.model.cancelado,
        rooms_max: this.model.rooms_max,
        paxs_max: this.model.paxs_max,
        currency_id: this.model.currency_id,
        source: this.model.source,
        notificar_acceso_data: this.model.notificar_acceso_data,
        notificar_acceso_disp: this.model.notificar_acceso_disp,
        observations: this.observationsHotel,
      }
      this.loading = true

      await this.axios
        .put(`contrate_hotels/${this.model.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            // localStorage.removeItem('hotels-contrate-id')
            // this.$router.push({ name: 'hotels-contrate-list' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getItem()
          this.loading = false
          this.editObservations = false
        })
    },
    async setCanceled() {
      if (this.model.cancelado) {
        this.model.publicado = false
        this.model.stopSale = true
      } else {
        this.model.publicado = true
        this.model.stopSale = false
      }
      const json = {
        contrate_id: this.model.id,
        publicado: !this.model.stopSale,
        cancelado: this.model.cancelado,
      }
      this.loading = true

      await this.axios
        .post('contrate_hotels/change-status', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            // localStorage.removeItem('hotels-contrate-id')
            // this.$router.push({ name: 'hotels-contrate-list' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getItem()
          this.loading = false
          this.editObservations = false
        })
    },

    async confirmAsociarContacts() {
      this.loading = true
      const json = {
        contrate_id: this.model.id,
      }
      await this.axios
        .post('contrate_hotels/asociate-contacts', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.getCantPend()

            // localStorage.removeItem('hotels-contrate-id')
            // this.$router.push({ name: 'hotels-contrate-list' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.isDialogContacts = false
        })
    },

    /*
    getSources() {
      this.sources.push({
        name: 'Directo',
        slug: 'directo',
      })
      this.axios
        .post(
          'api_keys/verify-status',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.status) {
            this.nameSource = res.data.data.api_name
            this.nameHotetec = res.data.data.api_name
            this.sources.push({
              name: res.data.data.api_name,
              slug: 'hotetec',
            })
          }
        })
        .finally(() => {
          this.isLoadingSources = false
          this.getApiExtern()
        })
    },
    getApiExtern() {
      this.axios
        .post(
          'api_extern/show',
          { slug: 'hotetecapi' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            if (res.data.data.proveedores) {
              this.operadoresApiExternHotetec = res.data.data.proveedores
            }
          }
        })
        .finally(() => {
          this.axios
            .post(
              'api_extern/show',
              { slug: 'dingusapi' },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            )
            .then(res => {
              if (res.data.success) {
                if (res.data.data.proveedores) {
                  this.operadoresApiExternDingus = res.data.data.proveedores
                }
              }
            })
        })
    },
    getCantPend() {
      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        search: this.search,
      }
      this.axios
        .post('contrate_hotels/cant-pending-season', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === true) {
            this.totalInfoPendiente = response.data.data.totalInfoPend
            this.totalInfoPendienteOrigin = response.data.data.totalInfoPend
            this.totalSinRooms = response.data.data.totalSinRooms
            this.totalSinRoomsOrigin = response.data.data.totalSinRooms
            this.idsSinRooms = response.data.data.idsSinRooms
            this.totalSinPublicar = response.data.data.totalSinPublicar
            this.totalSinPublicarOrigin = response.data.data.totalSinPublicar
            this.idsSinPublicar = response.data.data.idsSinPublicar
            this.totalSinOcupation = response.data.data.totalSinOcupation
            this.totalSinOcupationOrigin = response.data.data.totalSinOcupation
            this.idsSinOcupation = response.data.data.idsSinOcupation
            this.totalSinPlan = response.data.data.totalSinPlan
            this.totalSinPlanOrigin = response.data.data.totalSinPlan
            this.idsSinPlan = response.data.data.idsSinPlan
            this.totalSinPrice = response.data.data.totalSinPrice
            this.totalSinPriceOrigin = response.data.data.totalSinPrice
            this.idsSinPrice = response.data.data.idsSinPrice
            this.totalSinContact = response.data.data.totalSinContact
            this.totalSinContactOrigin = response.data.data.totalSinContact
            this.idsSinContact = response.data.data.idsSinContact
            this.totalVence = response.data.data.totalVence
            this.totalVenceOrigin = response.data.data.totalVence
            this.totalVence3 = response.data.data.totalVence3
            this.totalVence3Origin = response.data.data.totalVence3
            this.idsVence3 = response.data.data.idsVence3
            this.totalVence6 = response.data.data.totalVence6
            this.totalVence6Origin = response.data.data.totalVence6
            this.idsVence6 = response.data.data.idsVence6
          }
        })
        .finally(() => {
          this.isLoadingInfoPend = false
        })
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.currencies = res.data.data
      })
        .finally(() => {
          this.isLoadingCurrencies = false
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data
        })
        .finally(() => {
          this.isLoadingOperadors = false
          this.isLoadingMarcas = false
        })
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'marca_asociada_id',
          'categoria_id',
          'tipo_destino_id',
          'sugerido',
          'number_sugerido',
          'checkIn',
          'checkOut',
          'direccion',
          'publicado',
          'cant_departament',
        ],
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
          }
        })
        .finally(() => {
          this.isLoadingHotels = false
          this.getCountries()
          this.getItem()
        })
    },
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    filterHotel() {
      this.itemsHotel = this.allHotel.filter(e => e.name.toLowerCase())
    },
    filterCodesHotel() {
      this.codesHotel = this.allCodes.filter(e => e.code.toLowerCase())
    },
    setOperadores() {
      this.hotels = []
      this.allMarcas = []
      this.allHotel = []
      this.itemsHotel = []
      this.itemsMarcas = []
      if (this.operadores.filter(e => e.id === this.search.operador_id).length > 0) {
        if (this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas
          this.allHotel = this.hotelesAll.filter(e => e.operador_id === this.search.operador_id)
          this.itemsMarcas = this.allMarcas
          this.filtersHotels()
        }
      }

      this.recalculeTotalPagination()
    },
    setMarca() {
      this.hotels = []
      this.allHotel = []
      this.itemsHotel = []
      if (this.allMarcas.filter(e => e.id === this.search.marca_asociada_id).length > 0) {
        this.allHotel = this.hotelesAll.filter(e => e.marca_asociada_id === this.search.marca_asociada_id)
        this.filtersHotels()
      }
    },
    setHotel() {
      this.hotels = []
      this.filtersHotels()
    },
    setCodesHotel() {
      this.hotels = []
      this.filtersHotels()
    },
    changeCountry() {
      this.states = []
      this.search.state = null
      this.search.city = null
      if (this.search.country) {
        const states = this.hotelesAll.filter(e => e.country === this.search.country)
        states.forEach(element => {
          if (!this.states.includes(element.state) && element.state) {
            this.states.push(element.state)
          }
        })
      }
      setTimeout(() => {
        this.isLoadingStates = false
      }, 100)
      this.filtersHotels()
    },
    changeState() {
      this.cities = []
      this.search.city = null
      if (this.search.state) {
        const states = this.hotelesAll.filter(e => e.state === this.search.state)
        states.forEach(element => {
          if (!this.cities.includes(element.city)) {
            this.cities.push(element.city)
          }
        })
      }
      this.filtersHotels()
    },
    changeCity() {
      setTimeout(() => {
        this.isLoadingCities = false
      }, 100)
      this.filtersHotels()
    }, */
  },
}
</script>
<style scoped>
::v-deep .v-messages__message {
  color: red !important;
  margin-top: 10px !important;
}
</style>
