<template>
  <div v-if="!isLoading">
    <!-- div fijo -->
    <div class="fixHeightMine">
      <v-container
        grid-list-xs
        class=""
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="client.email"
              :label="$t('lbl.email')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <!-- vista desktop para el div -->
            <div class="d-none d-md-block">
              <div class="d-flex justify-start align-center">
                <v-btn
                  color="primary"
                  dense
                  hide-details
                  outlined
                  :loading="loadingEmail"
                  @click="sendEmail()"
                >
                  {{ $t('lbl.sendEmail') }}
                </v-btn>
                <p class="mb-0 px-4">
                  {{ $t('lbl.or') }}
                </p>
                <v-btn
                  color="primary"
                  dense
                  hide-details
                  outlined
                  :loading="loadingPdf"
                  @click="downloadPdf()"
                >
                  {{ $t('lbl.downloadPdf') }}
                </v-btn>
              </div>
            </div>
            <!-- vista movil para el div -->
            <div class="d-md-none">
              <div class="d-flex justify-center align-center">
                <v-btn
                  color="primary"
                  width="100%"
                  dense
                  hide-details
                  outlined
                  @click="sendEmail()"
                >
                  {{ $t('lbl.sendEmail') }}
                </v-btn>
              </div>
              <div class="d-flex justify-center align-center">
                <p class="mb-0 px-4">
                  {{ $t('lbl.or') }}
                </p>
              </div>
              <div class="d-flex justify-center align-center">
                <v-btn
                  color="primary"
                  width="100%"
                  dense
                  hide-details
                  outlined
                  @click="downloadPdf()"
                >
                  {{ $t('lbl.downloadPdf') }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div>
      <!-- Regresar -->
      <v-container grid-list-xs>
        <v-row>
          <v-col
            cols="12"
            class="px-0"
          >
            <a
              style="text-decoration: none; cursor: pointer;"
              @click="goBack()"
            >
              <p
                class="mb-0"
                style="color:gray"
              >
                <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
                {{ $t('lbl.backSearch') }}
              </p>
            </a>
          </v-col>
        </v-row>
      </v-container>

      <!--CALCULADORA-->
      <v-card>
        <v-container
          grid-list-xs
          class="px-4"
        >
          <v-row>
            <v-col
              v-for="(destino, index) in destinos"
              :key="index"
              cols="12"
              md="12"
              class="pb-0"
            >
              <div class="d-block justify-start align-center">
                <div class="d-flex justify-start align-center">
                  <h2>{{ destino.destino.origen.country }}</h2>
                  <v-icon
                    class="px-2"
                    color="primary"
                  >
                    {{ !destino.onlyIda && !destino.destinosMultiples ? icons.mdiSwapHorizontal : icons.mdiArrowRight }}
                  </v-icon>
                  <h2>{{ destino.destino.destino.country }}</h2>
                </div>
                <div class="d-flex justify-start align-center">
                  <h5>{{ destino.destino.from | moment('DD MMMM, dddd') }}</h5>
                  <fragment v-if="!destino.onlyIda && !destino.destinosMultiples">
                    <h5 class="px-2">
                      -
                    </h5>
                    <h5>{{ destino.destino.to | moment('DD MMMM, dddd') }}</h5>
                  </fragment>
                </div>
                <div class="d-flex justify-start align-center">
                  <h5>
                    {{ destino.cant_adults > 0 ? `${$t('lbl.adultos')} ${destino.cant_adults}` : '' }}
                    <fragment v-if="destino.cant_ninnos > 0">
                      ,
                    </fragment>
                    {{ destino.cant_ninnos > 0 ? `${$t('lbl.childs')} ${destino.cant_ninnos}` : '' }}
                    <fragment v-if="destino.cant_infant > 0">
                      ,
                    </fragment>
                    {{ destino.cant_infant > 0 ? `${$t('lbl.infants')} ${destino.cant_infant}` : '' }}
                  </h5>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="client.name"
                dense
                outlined
                hide-details
                label="Nombre del cliente"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="markup"
                dense
                outlined
                hide-details
                label="Mark-up"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex align-center"
            >
              <v-radio-group
                v-model="valueMarkup"
                row
                hide-details
                class="mt-0"
              >
                <v-radio
                  dense
                  label="%"
                  value="%"
                ></v-radio>
                <v-radio
                  dense
                  label="USD"
                  value="$"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex align-center"
            >
              <v-checkbox
                v-model="agregarDescuento"
                :label="$t('lbl.addDescuento')"
                dense
                hide-details
                outlined
                class="descuento my-0 py-0"
                value="1"
                @change="changeAgregarDescuento"
              ></v-checkbox>
            </v-col>
            <!-- Vista movil del agregar descuento
            <div class="d-md-none">
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  label="Mark-up"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-center"
              >
                <v-radio-group
                  v-model="value"
                  row
                  hide-details
                  class="mt-0"
                >
                  <v-radio
                    dense
                    label="%"
                    value="value1"
                  ></v-radio>
                  <v-radio
                    dense
                    label="USD"
                    value="value2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </div>-->
            <!-- Fin vista movil del agregar descuento-->
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                @click="applyMarkups"
              >
                {{ $t('btn.apply') }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- Vista desktop del agregar descuento -->
          <div
            v-if="agregarDescuento"
            class="d-none d-md-block"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="descuento"
                  dense
                  outlined
                  hide-details
                  :label="$t('lbl.descuento')"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="agregarDescuento"
                cols="12"
                md="2"
                class="d-flex align-center"
              >
                <v-radio-group
                  v-model="valueDescuento"
                  row
                  hide-details
                  class="mt-0"
                >
                  <v-radio
                    dense
                    label="%"
                    value="%"
                  ></v-radio>
                  <v-radio
                    dense
                    label="USD"
                    value="$"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="d-flex align-center"
              >
              </v-col>
            </v-row>
          </div>
          <!-- Fin de vista desktop del agregar descuento -->
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="mb-0">
                {{ $t('lbl.calculo') }}:
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-start align-center">
                <div class="d-block justify-center mr-4">
                  <p class="d-flex justify-center align-center mb-0">
                    {{ $t('lbl.precioInitial') }}:
                  </p>
                  <v-divider
                    style="border: 1px solid #bebbc3;"
                    class="my-1"
                  ></v-divider>
                  <p class="d-flex justify-center align-center mb-0">
                    {{ precioInitial | currency }}
                  </p>
                </div>
                <div class="d-block justify-center mr-4">
                  <p class="d-flex justify-center align-center mb-0">
                    {{ $t('lbl.conMarkups') }}
                  </p>
                  <v-divider
                    style="border: 1px solid #bebbc3;"
                    class="my-1"
                  ></v-divider>
                  <p class="d-flex justify-center align-center mb-0">
                    {{ precioWithMarkup | currency }}
                  </p>
                </div>
                <div class="d-block justify-center">
                  <p class="d-flex justify-center align-center mb-0">
                    {{ $t('lbl.conDescuento') }}
                  </p>
                  <v-divider
                    style="border: 1px solid #bebbc3;"
                    class="my-1"
                  ></v-divider>
                  <p class="d-flex justify-center align-center mb-0">
                    {{ precioWithDescuento | currency }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!--TABLA-->
      <v-card class="mt-2">
        <v-card-title primary-title>
          {{ $t('menu.flights') }}
        </v-card-title>
        <v-container grid-list-xs>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-container grid-list-xs>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-0 pl-0"
                    >
                      <template>
                        <v-simple-table
                          id="bookingTableMine"
                          dense
                          fixed-header
                          height="400px"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.departure') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.durationVuelo') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.escalas') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.operador') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.numeroVuelo') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.clase') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.tarif') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.equipaje_cabina.title') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.equipaje_bodega.title') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.precioInitial') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.conMarkups') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('lbl.conDescuento') }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, i) in listItems"
                                :key="i"
                              >
                                <td>
                                  <v-checkbox
                                    v-model="item.selected"
                                    @change="onChangeCheckbox(item)"
                                  ></v-checkbox>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.hour_departure }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ formatDuration(item.durationM) }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.escalas > 0 ? 'Si': 'No' }}
                                  </p>
                                </td>
                                <td>
                                  <v-tooltip
                                    left
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-avatar
                                        v-if="item.operadoPor.isotipo === null"
                                        color="primary"
                                        size="48"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <span
                                          class="white--text text-h5"
                                        >{{ item.operadoPor.slug }}</span>
                                      </v-avatar>
                                      <v-img
                                        v-else
                                        :src="rutaPublic + item.operadoPor.isotipo"
                                        :alt="item.operadoPor.slug"
                                        max-height="50"
                                        max-width="50"
                                        contain
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-img>
                                    </template>
                                    <span>{{ item.operadoPor.name }}</span>
                                  </v-tooltip>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.numeroVuelo }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.clase }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.tarifa }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    <v-icon :color="item.cant_maletas_cabina > 0 ? 'primary' : ''">
                                      {{ item.cant_maletas_cabina > 0 ? icons.mdiBagCarryOn : icons.mdiBagCarryOnOff }}
                                    </v-icon>
                                    {{ item.equipajeMano.peso }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    <v-icon :color="item.cant_maletas_bodega > 0 ? 'primary' : ''">
                                      {{ item.cant_maletas_bodega !== null ? icons.mdiBagPersonal : icons.mdiBagPersonalOff }}
                                    </v-icon>
                                    {{ item.equipajeBodega.peso }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.precio | currency }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.precio_markups | currency }}
                                  </p>
                                </td>
                                <td>
                                  <p class="mb-0">
                                    {{ item.precio_descuento | currency }}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiChevronLeft,
  mdiBagCarryOn,
  mdiBagCarryOnOff,
  mdiBagPersonal,
  mdiBagPersonalOff,
  mdiArrowRight,
  mdiSwapHorizontal,
} from '@mdi/js'

// import Cotizador from './form/Cotizador.vue'

export default {
  // components: {
  //   Cotizador,
  // },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      loadingPdf: false,
      loadingEmail: false,
      client: {},
      listItems: [],
      destinos: [],
      dimensiones: [],
      pesos: [],
      icons: {
        mdiChevronLeft,
        mdiBagCarryOn,
        mdiBagCarryOnOff,
        mdiBagPersonal,
        mdiBagPersonalOff,
        mdiArrowRight,
        mdiSwapHorizontal,
      },
      user: {},
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
    }
  },
  computed: {
    ...mapState({
      cotizadorFlightFavorite: state => state.app.cotizadorFlightFavorite,
    }),
    precioInitial() {
      let priceTotal = 0

      this.listItems.forEach(element => {
        if (element.selected) {
          priceTotal += element.precio_inicial
        }
      })

      return priceTotal
    },
    precioWithMarkup() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
        } // USD
        if (this.valueMarkup === '$') {
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + ((parseFloat(priceTotal) * parseFloat(this.markup)) / 100)
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) - ((parseFloat(priceTotal) * parseFloat(this.descuento)) / 100)
        } // USD
        if (this.valueDescuento === '$') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          priceTotal = parseFloat(priceTotal) - parseFloat(this.descuento)
        }
      }

      return priceTotal
    },
    cantItemsSelect() {
      let cant = 0

      this.listItems.forEach(element => {
        if (element.selected) {
          cant += 1
        }
      })

      return cant
    },
  },
  mounted() {
    if (this.cotizadorFlightFavorite.length === 0) {
      this.$router.push({ name: 'cotizador' })
    }
  },
  created() {
    this.profile()
    this.getDimensiones()
    this.getPesos()
    setTimeout(() => {
      this.getItems()
    }, 500)
    this.isLoading = false
  },
  methods: {
    ...mapMutations([
      'clearCotizadorFlightFavorite',
      'setSearchingFlightCotizador',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          this.user = response.data.data
        })
        .catch(error => console.log(error))
    },
    getDimensiones() {
      this.axios.get('dimensiones-bag-flights?per_page=10000', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        // eslint-disable-next-line no-empty
        if (res.data.success === false) { } else {
          this.dimensiones = res.data.data
        }
      })
    },
    getPesos() {
      this.axios.get('pesos-bag-flights?per_page=10000', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        // eslint-disable-next-line no-empty
        if (res.data.success === false) { } else {
          this.pesos = res.data.data
        }
      })
    },
    formatDuration(minutes) {
      let textHours
      let days = 0
      let hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      if (hours > 24) {
        days = Math.floor(hours / 24)
        hours %= 24
        textHours = `${days}d`
      }

      if (days > 0) {
        textHours = `${textHours} ${hours}h ${mins}min.`
      } else {
        textHours = `${hours}h ${mins}min.`
      }

      return textHours
    },
    getItems() {
      // console.log(this.cotizadorFlightFavorite)
      this.cotizadorFlightFavorite.forEach(element => {
        if (element.onlyIda) {
          // eslint-disable-next-line camelcase
          let cant_escalas = 0
          let escalas = []
          if (element.item_ida.flight.escalas !== null) {
            // eslint-disable-next-line camelcase
            cant_escalas = element.item_ida.flight.escalas.length
            escalas = element.item_ida.flight.escalas
          }

          let frecuencia = []
          if (element.item_ida.flight.frecuencia !== null) {
            frecuencia = element.item_ida.flight.frecuencia
          }

          let equipajeMano = { }
          if (element.item_ida.tarifas_contrate[element.indTarifa].equipaje_cabina.peso > 0) {
            let peso = ''
            this.pesos.forEach(peseta => {
              if (peseta.id === element.item_ida.tarifas_contrate[element.indTarifa].equipaje_cabina.peso) {
                peso = peseta.name
              }
            })
            let dimension = ''
            this.dimensiones.forEach(dime => {
              if (dime.id === element.item_ida.tarifas_contrate[element.indTarifa].equipaje_cabina.dimensiones) {
                dimension = dime.nameUm
              }
            })
            equipajeMano = {
              peso,
              dimension,
            }
          } else {
            equipajeMano = {
              peso: '',
              dimension: '',
            }
          }

          let equipajeBodega = { }
          if (element.item_ida.tarifas_contrate[element.indTarifa].equipaje_bodega.peso > 0) {
            let peso = ''
            this.pesos.forEach(peseta => {
              if (peseta.id === element.item_ida.tarifas_contrate[element.indTarifa].equipaje_bodega.peso) {
                peso = peseta.name
              }
            })
            let dimension = ''
            this.dimensiones.forEach(dime => {
              if (dime.id === element.item_ida.tarifas_contrate[element.indTarifa].equipaje_bodega.dimensiones) {
                dimension = dime.nameUm
              }
            })
            equipajeBodega = {
              peso,
              dimension,
            }
          } else {
            equipajeBodega = {
              peso: '',
              dimension: '',
            }
          }

          this.listItems.push({
            selected: true,
            hour_departure: element.item_ida.flight.origen.salida,
            hour_arrival: element.item_ida.flight.destino.llegada,
            durationM: element.item_ida.flight.destino.durationM,
            operadoPor: element.item_ida.flight.aerolinea,
            numeroVuelo: element.item_ida.flight.numero_de_vuelo,
            clase: element.item_ida.tarifas_contrate[element.indTarifa].clase,
            tarifa: element.item_ida.tarifas_contrate[element.indTarifa].tarifa,
            equipajePersonal: element.item_ida.tarifas_contrate[element.indTarifa].equipaje_personal,
            equipajeMano,
            cant_maletas_cabina: element.item_ida.tarifas_contrate[element.indTarifa].cant_maletas_cabina,
            equipajeBodega,
            cant_maletas_bodega: element.item_ida.tarifas_contrate[element.indTarifa].cant_maletas_bodega,
            precio: element.item_ida.tarifas_contrate[element.indTarifa].price_total,
            precio_inicial: element.item_ida.tarifas_contrate[element.indTarifa].price_inicial,
            precio_markups: element.item_ida.tarifas_contrate[element.indTarifa].price_total,
            precio_descuento: element.item_ida.tarifas_contrate[element.indTarifa].price_total,

            escalas,
            cantEscalas: cant_escalas,
            frecuencia,
            airport_origen: element.item_ida.flight.airport_origen,
            airport_destino: element.item_ida.flight.airport_destino,
            terminal: element.item_ida.flight.origen.terminal,
          })

          // console.log(this.listItems)
          element.destinos.forEach(destino => {
            this.destinos.push({
              destino,
              onlyIda: element.onlyIda,
              destinosMultiples: element.destinosMultiples,
              cant_adults: element.cant_adults,
              cant_ninnos: element.cant_ninnos,
              cant_infant: element.cant_infant,
            })
          })
        } else if (!element.onlyIda && !element.destinosMultiples) {
          // eslint-disable-next-line camelcase
          let cant_escalas = 0
          let escalas = []
          if (element.item_ida.flight.escalas !== null) {
            // eslint-disable-next-line camelcase
            cant_escalas = element.item_ida.flight.escalas.length
            escalas = element.item_ida.flight.escalas
          }

          let frecuencia = []
          if (element.item_ida.flight.frecuencia !== null) {
            frecuencia = element.item_ida.flight.frecuencia
          }

          let equipajeMano = { }
          if (element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_cabina.peso > 0) {
            let peso = ''
            this.pesos.forEach(peseta => {
              if (peseta.id === element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_cabina.peso) {
                peso = peseta.name
              }
            })
            let dimension = ''
            this.dimensiones.forEach(dime => {
              if (dime.id === element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_cabina.dimensiones) {
                dimension = dime.nameUm
              }
            })
            equipajeMano = {
              peso,
              dimension,
            }
          } else {
            equipajeMano = {
              peso: '',
              dimension: '',
            }
          }

          let equipajeBodega = { }
          if (element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_bodega.peso > 0) {
            let peso = ''
            this.pesos.forEach(peseta => {
              if (peseta.id === element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_bodega.peso) {
                peso = peseta.name
              }
            })
            let dimension = ''
            this.dimensiones.forEach(dime => {
              if (dime.id === element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_bodega.dimensiones) {
                dimension = dime.nameUm
              }
            })
            equipajeBodega = {
              peso,
              dimension,
            }
          } else {
            equipajeBodega = {
              peso: '',
              dimension: '',
            }
          }

          this.listItems.push({
            selected: true,
            hour_departure: element.item_ida.flight.origen.salida,
            hour_arrival: element.item_ida.flight.destino.llegada,
            durationM: element.item_ida.flight.destino.durationM,
            operadoPor: element.item_ida.flight.aerolinea,
            numeroVuelo: element.item_ida.flight.numero_de_vuelo,
            clase: element.item_ida.tarifas_contrate[element.indTarifaIda].clase,
            tarifa: element.item_ida.tarifas_contrate[element.indTarifaIda].tarifa,
            equipajePersonal: element.item_ida.tarifas_contrate[element.indTarifaIda].equipaje_personal,
            equipajeMano,
            cant_maletas_cabina: element.item_ida.tarifas_contrate[element.indTarifaIda].cant_maletas_cabina,
            equipajeBodega,
            cant_maletas_bodega: element.item_ida.tarifas_contrate[element.indTarifaIda].cant_maletas_bodega,
            precio: element.item_ida.tarifas_contrate[element.indTarifaIda].price_total,
            precio_inicial: element.item_ida.tarifas_contrate[element.indTarifaIda].price_inicial,
            precio_markups: element.item_ida.tarifas_contrate[element.indTarifaIda].price_total,
            precio_descuento: element.item_ida.tarifas_contrate[element.indTarifaIda].price_total,

            escalas,
            cantEscalas: cant_escalas,
            frecuencia,
            airport_origen: element.item_ida.flight.airport_origen,
            airport_destino: element.item_ida.flight.airport_destino,
            terminal: element.item_ida.flight.origen.terminal,
          })

          // REGRESO
          // eslint-disable-next-line camelcase
          cant_escalas = 0
          escalas = []
          if (element.item_regreso.flight.escalas !== null) {
            // eslint-disable-next-line camelcase
            cant_escalas = element.item_regreso.flight.escalas.length
            escalas = element.item_regreso.flight.escalas
          }

          frecuencia = []
          if (element.item_regreso.flight.frecuencia !== null) {
            frecuencia = element.item_regreso.flight.frecuencia
          }

          equipajeMano = { }
          if (element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_cabina.peso > 0) {
            let peso = ''
            this.pesos.forEach(peseta => {
              if (peseta.id === element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_cabina.peso) {
                peso = peseta.name
              }
            })
            let dimension = ''
            this.dimensiones.forEach(dime => {
              if (dime.id === element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_cabina.dimensiones) {
                dimension = dime.nameUm
              }
            })
            equipajeMano = {
              peso,
              dimension,
            }
          } else {
            equipajeMano = {
              peso: '',
              dimension: '',
            }
          }

          equipajeBodega = { }
          if (element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_bodega.peso > 0) {
            let peso = ''
            this.pesos.forEach(peseta => {
              if (peseta.id === element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_bodega.peso) {
                peso = peseta.name
              }
            })
            let dimension = ''
            this.dimensiones.forEach(dime => {
              if (dime.id === element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_bodega.dimensiones) {
                dimension = dime.nameUm
              }
            })
            equipajeBodega = {
              peso,
              dimension,
            }
          } else {
            equipajeBodega = {
              peso: '',
              dimension: '',
            }
          }

          this.listItems.push({
            selected: true,
            hour_departure: element.item_regreso.flight.origen.salida,
            hour_arrival: element.item_regreso.flight.destino.llegada,
            durationM: element.item_regreso.flight.destino.durationM,
            operadoPor: element.item_regreso.flight.aerolinea,
            numeroVuelo: element.item_regreso.flight.numero_de_vuelo,
            clase: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].clase,
            tarifa: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].tarifa,
            equipajePersonal: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].equipaje_personal,
            equipajeMano,
            cant_maletas_cabina: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].cant_maletas_cabina,
            equipajeBodega,
            cant_maletas_bodega: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].cant_maletas_bodega,
            precio: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].price_total,
            precio_inicial: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].price_inicial,
            precio_markups: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].price_total,
            precio_descuento: element.item_regreso.tarifas_contrate[element.indTarifaRegreso].price_total,

            escalas,
            cantEscalas: cant_escalas,
            frecuencia,
            airport_origen: element.item_ida.flight.airport_origen,
            airport_destino: element.item_ida.flight.airport_destino,
            terminal: element.item_ida.flight.origen.terminal,
          })

          // console.log(this.listItems)
          element.destinos.forEach(destino => {
            this.destinos.push({
              destino,
              onlyIda: element.onlyIda,
              destinosMultiples: element.destinosMultiples,
              cant_adults: element.cant_adults,
              cant_ninnos: element.cant_ninnos,
              cant_infant: element.cant_infant,
            })
          })
        } else if (element.destinosMultiples) {
          element.item_ida.destinos.forEach(dest => {
            // eslint-disable-next-line camelcase
            let cant_escalas = 0
            let escalas = []
            if (dest.flights[dest.indexTarifa].flight.escalas !== null) {
              // eslint-disable-next-line camelcase
              cant_escalas = dest.flights[dest.indexTarifa].flight.escalas.length
              escalas = dest.flights[dest.indexTarifa].flight.escalas
            }

            let frecuencia = []
            if (dest.flights[dest.indexTarifa].flight.frecuencia !== null) {
              frecuencia = dest.flights[dest.indexTarifa].flight.frecuencia
            }

            let equipajeMano = { }
            if (dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_cabina.peso > 0) {
              let peso = ''
              this.pesos.forEach(peseta => {
                if (peseta.id === dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_cabina.peso) {
                  peso = peseta.name
                }
              })
              let dimension = ''
              this.dimensiones.forEach(dime => {
                if (dime.id === dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_cabina.dimensiones) {
                  dimension = dime.nameUm
                }
              })
              equipajeMano = {
                peso,
                dimension,
              }
            } else {
              equipajeMano = {
                peso: '',
                dimension: '',
              }
            }

            let equipajeBodega = { }
            if (dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_bodega.peso > 0) {
              let peso = ''
              this.pesos.forEach(peseta => {
                if (peseta.id === dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_bodega.peso) {
                  peso = peseta.name
                }
              })
              let dimension = ''
              this.dimensiones.forEach(dime => {
                if (dime.id === dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_bodega.dimensiones) {
                  dimension = dime.nameUm
                }
              })
              equipajeBodega = {
                peso,
                dimension,
              }
            } else {
              equipajeBodega = {
                peso: '',
                dimension: '',
              }
            }

            this.listItems.push({
              selected: true,
              hour_departure: dest.flights[dest.indexTarifa].flight.origen.salida,
              hour_arrival: dest.flights[dest.indexTarifa].flight.destino.llegada,
              durationM: dest.flights[dest.indexTarifa].flight.destino.durationM,
              operadoPor: dest.flights[dest.indexTarifa].flight.aerolinea,
              numeroVuelo: dest.flights[dest.indexTarifa].flight.numero_de_vuelo,
              clase: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].clase,
              tarifa: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].tarifa,
              equipajePersonal: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].equipaje_personal,
              equipajeMano,
              cant_maletas_cabina: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].cant_maletas_cabina,
              equipajeBodega,
              cant_maletas_bodega: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].cant_maletas_bodega,
              precio: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].price_total,
              precio_inicial: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].price_inicial,
              precio_markups: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].price_total,
              precio_descuento: dest.flights[dest.indexTarifa].tarifas_contrate[dest.indTarifa].price_total,

              escalas,
              cantEscalas: cant_escalas,
              frecuencia,
              airport_origen: dest.flights[dest.indexTarifa].flight.airport_origen,
              airport_destino: dest.flights[dest.indexTarifa].flight.airport_destino,
              terminal: dest.flights[dest.indexTarifa].flight.terminal,
            })
          })

          element.destinos.forEach(destino => {
            this.destinos.push({
              destino,
              onlyIda: element.onlyIda,
              destinosMultiples: element.destinosMultiples,
              cant_adults: element.cant_adults,
              cant_ninnos: element.cant_ninnos,
              cant_infant: element.cant_infant,
            })
          })
        }
      })
    },
    goBack() {
      this.clearCotizadorFlightFavorite()
      this.setSearchingFlightCotizador(false)
      this.$router.push({ name: 'cotizador' })
    },
    sendEmail() {
      if (this.client.email && this.client.name) {
        const json = {
          destinos: this.destinos,
          items: this.listItems,
          email: this.client.email,
          cliente: this.client.name,
          lang: this.$i18n.locale,
          user_id: this.user.id,
        }
        this.loadingEmail = true
        this.axios
          .post('send-pdf/favorities-flights', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.$toast.info(this.$t('msg.sendEmail', { n: this.client.email }))
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loadingEmail = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    downloadPdf() {
      // console.log(this.destinos)
      // console.log(this.listItems)
      if (this.client.name) {
        this.loadingPdf = true
        const json = {
          destinos: this.destinos,
          items: this.listItems,
          cliente: this.client.name,
          lang: this.$i18n.locale,
          user_id: this.user.id,
        }
        this.axios
          .post('export-pdf/favorities-flights', json,
            { responseType: 'arraybuffer' }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
          .then(res => {
            // console.log(res)

            let filename = 'cotization'
            filename = `${this.client.name}_Vuelos_${this.$moment().format('MM-D-Y')}`
            filename = filename.replace(/ /g, '-')
            this.downloadFile(res, filename)
          })
        // eslint-disable-next-line no-return-assign
          .finally(() => (this.loadingPdf = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
    applyMarkups() {
      /* const json = {
        markup: this.markup,
        valueMarkup: this.valueMarkup,
        descuento: this.descuento,
        valueDescuento: this.valueDescuento,
      } */
      let incrementMark = 0
      let incrementDesc = 0

      if (this.markup) {
        incrementMark = this.precioWithMarkup - this.precioInitial
        incrementMark = parseFloat(incrementMark / this.cantItemsSelect).toFixed(2)
      }
      if (this.descuento) {
        incrementDesc = this.precioWithDescuento - this.precioInitial
        incrementDesc = parseFloat(incrementDesc / this.cantItemsSelect).toFixed(2)
      } else {
        incrementDesc = incrementMark
      }

      this.listItems.forEach(element => {
        if (element.selected) {
          // eslint-disable-next-line no-param-reassign
          element.precio_markups = parseFloat(element.precio_inicial) + parseFloat(incrementMark)
          // eslint-disable-next-line no-param-reassign
          element.precio_descuento = parseFloat(element.precio_inicial) + parseFloat(incrementDesc)
        }
      })
    },
    changeAgregarDescuento(e) {
      if (e === null) {
        this.descuento = null
        this.valueDescuento = null
      }
    },
    onChangeCheckbox() {
      this.listItems.forEach(element => {
        if (!element.selected) {
          // eslint-disable-next-line no-param-reassign
          element.precio_markups = parseFloat(element.precio_inicial)
          // eslint-disable-next-line no-param-reassign
          element.precio_descuento = parseFloat(element.precio_inicial)
        }
      })

      // RECALCULAR
      this.applyMarkups()
    },
  },
}
</script>

<style scoped>
.fixHeightMine {
  height: auto;
  width: 100% !important;
  background-color: #daccf4;
  position: fixed;
  z-index: 10;
  bottom: 0px;
}

@media screen and (max-width: 1024px) and (min-width: 200px) {
  .fixHeightMine {
    height: auto;
    width: 100% !important;
    background-color: #daccf4;
    position: fixed;
    z-index: 10;
    bottom: 0px;
    left: 0;
  }
}

.imgBookingMine {
  /* max-width: 120px !important; */
  height: 120px !important;
}

#bookingTableMine th {
  min-width: 120px;
}

#bookingTableMine th:nth-child(1) {
  min-width: auto;
}
</style>
