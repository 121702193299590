<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.flights')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'flights-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="4"
              >
                <!--<v-select
                  v-model="model.aerolinea_id"
                  :items="aerolineas"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.operador')"
                  outlined
                  dense
                  hide-details
                  @change="changeAerolinea"
                ></v-select>-->
                <v-autocomplete
                  v-model="model.aerolinea_id"
                  :items="itemsAerolinea"
                  :search-input.sync="searchAerolinea"
                  hide-details
                  hide-selected
                  :label="$t('lbl.operador')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  @change="changeAerolinea"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.operador') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  ref="numero_de_vuelo"
                  v-model="model.numero_de_vuelo"
                  :prefix="iataAeroline"
                  :label="$t('lbl.numeroVuelo')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.aeronave_id"
                  :items="aeronaves"
                  item-text="modelo"
                  item-value="id"
                  :label="$t('lbl.aeronave')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>

            <!--FRECUENCIAS-->
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="2"
                md="2"
                class="pb-2 pt-3"
              >
                <b>{{ $t('lbl.frecuencias') }}</b>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="adicionarFrecuencia()"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </v-col>

              <v-col
                v-if="!$store.state.app.loadFrecuencia"
                cols="12"
                sm="12"
                md="12"
              >
                <v-divider v-if="frecuencias.length > 0"></v-divider>
                <Frecuencias
                  v-for="(frecuencia, i) in frecuencias"
                  :key="i"
                  :pos="i"
                  :frecuencia="frecuencia"
                  class="pt-2"
                />
              </v-col>
            </v-row>

            <!--ORIGEN--
            <v-row class="pb-4">
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.origen.destino"
                  :items="itemsOrigen"
                  :search-input.sync="searchOrigen"
                  hide-details
                  hide-selected
                  :label="$t('lbl.origen')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.origenSearch') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name !== undefined ? `${item.name.slice(0, 30)} ...` : '' }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon left>
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <vc-date-picker
                  v-model="model.origen.salida"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  @input="calculateTime"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.departure')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <v-text-field
                      v-model="model.origen.terminal"
                      :label="$t('lbl.terminal')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    offset-md="1"
                  >
                    <v-text-field
                      v-model="model.origen.puerta"
                      :label="$t('lbl.puerta')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  v-model="model.origen.duration"
                  :label="$t('lbl.duration')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>-->

            <!--ESCALAS
            <v-row
              no-gutters
            >
              <v-col
                v-if="$store.state.app.escalas.length === 0"
                cols="12"
                sm="2"
                md="2"
                class="pb-2 pt-3"
              >
                <b>{{ $t('lbl.escalas') }}</b>
              </v-col>
              <v-col
                v-if="$store.state.app.escalas.length === 0"
                cols="12"
                sm="2"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="adicionarEscala()"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </v-col>

              <v-col
                v-if="!$store.state.app.loadEscalas"
                cols="12"
                sm="12"
                md="12"
                class="pb-5"
              >
                <Escalas
                  v-for="(escala, i) in $store.state.app.escalas"
                  :key="i"
                  :escala="escala"
                  :pos="i"
                />
              </v-col>
            </v-row>-->

            <!--DESTINO
            <v-row
              class="pt-0 mt-0"
            >
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.destino.destino"
                  :items="itemsDestino"
                  :search-input.sync="searchDestino"
                  hide-details
                  hide-selected
                  :label="$t('lbl.destino')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.destinoSearch') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name !== undefined ? `${item.name.slice(0, 30)} ...` : '' }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon left>
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <vc-date-picker
                  v-model="model.destino.salida"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.departure')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <v-text-field
                      v-model="model.destino.terminal"
                      :label="$t('lbl.terminal')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    offset-md="1"
                  >
                    <v-text-field
                      v-model="model.destino.puerta"
                      :label="$t('lbl.puerta')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  v-model="model.destino.duration"
                  :label="$t('lbl.duration')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                md="2"
                offset-md="6"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="1"
                    offset-md="7"
                    class="pt-2 pl-1"
                  >
                    +
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.days"
                      :suffix="$t('lbl.dia')"
                      outlined
                      dense
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  v-model="model.duration"
                  :label="$t('lbl.duration')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>-->

            <!--<v-col
                cols="12"
                md="8"
              >
                <strong>{{ $t('lbl.frecuency') }}</strong>
                <v-sheet class="py-2">
                  <v-slide-group
                    v-model="model.frecuencia"
                    multiple
                  >
                    <v-slide-item
                      v-for="(item, index) in semanas"
                      v-slot="{ active, toggle }"
                      :key="index"
                      class="px-1"
                    >
                      <v-btn
                        class=""
                        :input-value="active"
                        active-class="primary white--text"
                        depressed
                        rounded
                        small
                        @click="toggle"
                      >
                        {{ item }}
                      </v-btn>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <strong style="color: #FFFFFF">.</strong>
                <vc-date-picker
                  v-model="model.fromValidez"
                  outlined
                  dense
                  :min-date="new Date()"
                  mode="date"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.fromValidez')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <strong style="color: #FFFFFF">.</strong>
                <vc-date-picker
                  v-model="model.toValidez"
                  outlined
                  dense
                  :min-date="$moment(model.fromValidez).add(1, 'days').calendar()"
                  mode="date"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.toValidez')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.origen.terminal"
                  :label="$t('lbl.terminal')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.origen.puerta"
                  :label="$t('lbl.puerta')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.destino.terminal"
                  :label="$t('lbl.terminal')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.destino.puerta"
                  :label="$t('lbl.puerta')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>-->

            <!--<v-row>
              <v-col
                cols="12"
                md="3"
              >
                <vc-date-picker
                  v-model="model.check_in"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  is24hr
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.checkIn')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <vc-date-picker
                  v-model="model.hour_departure"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  is24hr
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.hour_departure')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <vc-date-picker
                  v-model="model.hour_arrival"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  is24hr
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.hour_arrival')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.time_vuelo_h"
                  :label="$t('lbl.duration')"
                  outlined
                  dense
                  hide-details
                  suffix="hour"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.time_vuelo_m"
                  :label="$t('lbl.duration')"
                  outlined
                  dense
                  hide-details
                  suffix="min"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-row>-->
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

// import Escalas from '../utils/Escalas.vue'
import Frecuencias from '../utils/Frecuencias.vue'

export default {
  components: {
    AppCardCode,

    // Escalas,
    Frecuencias,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      timezone: '',
      aeronaves: [],
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      semanas: [],
      modelSemanas: [],
      iataAeroline: null,
      model: {
        origen: {
          destino: '',
          salida: '',
          terminal: '',
          puerta: '',
          duration: '',
        },
        destino: {
          destino: '',
          salida: '',
          terminal: '',
          puerta: '',
          duration: '',
        },
      },
      loading: false,

      items: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('flight-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      frecuencias: state => state.app.frecuencias,
    }),
  },
  watch: {
    searchAerolinea(val) {
      this.filterAerolinea(val.toLowerCase())

      /* if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAerolinea(val.toLowerCase())
      } else {
        this.itemsAerolinea = []
      } */
    },
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.itemsOrigen = []
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getAirPorts()
    this.getAerolineas()
    this.getAeronaves()
    this.getItem()

    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')
  },
  methods: {
    ...mapMutations([
      'adicionarEscala',
      'deleteEscala',
      'updateEscala',
      'initFrecuencia',
      'adicionarFrecuencia',
      'updateFrecuencia',
      'deleteFrecuencia',
    ]),
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterOrigen(v) {
      this.itemsOrigen = []
      if (v === '') {
        this.itemsOrigen = []
      } else {
        // this.itemsOrigen = this.items.filter(e => e.iataComodin.toLowerCase())
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              // this.items.push({ id: element.id, name: `${element.nominatim.address.city !== undefined ? element.nominatim.address.city : (element.nominatim.address.state !== undefined ? element.nominatim.address.state : element.nominatim.address.island)} (${element.iata} - ${element.name}) ${element.nominatim.address.country}` })
              this.itemsOrigen.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        // this.itemsDestino = this.items.filter(e => e.iataComodin.toLowerCase())
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              // this.items.push({ id: element.id, name: `${element.nominatim.address.city !== undefined ? element.nominatim.address.city : (element.nominatim.address.state !== undefined ? element.nominatim.address.state : element.nominatim.address.island)} (${element.iata} - ${element.name}) ${element.nominatim.address.country}` })
              this.itemsDestino.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },

    getItem() {
      this.$store.state.app.loadEscalas = true
      if (localStorage.getItem('flight-id') !== null) {
        const id = localStorage.getItem('flight-id')
        this.axios
          .get(`flight/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            this.itemsAerolinea.push(this.model.aerolinea)

            this.iataAeroline = this.model.aerolinea.slug
            let numero = 0
            // eslint-disable-next-line no-plusplus
            for (let index = this.iataAeroline.length; index < this.model.numero_de_vuelo.length; index++) {
              const element = this.model.numero_de_vuelo[index]

              // eslint-disable-next-line eqeqeq
              if (numero == 0) {
                numero = element
              } else {
                numero = `${numero}${element}`
              }
            }
            if (localStorage.getItem('flight-clone') !== null) {
              this.model.numero_de_vuelo = ''
            } else {
              this.model.numero_de_vuelo = numero
            }

            if (this.model.origen !== null) {
              this.itemsOrigen.push(this.model.origen.destino)
            }
            if (this.model.destino !== null) {
              this.itemsDestino.push(this.model.destino.destino)
            }

            if (this.model.escalas) {
              this.updateEscala(this.model.escalas)
            } else {
              this.updateEscala([])
            }

            if (this.model.frecuencia) {
              this.updateFrecuencia(this.model.frecuencia)
            } else {
              this.updateFrecuencia([])
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.$store.state.app.loadEscalas = false))
      } else {
        this.updateEscala([])
        this.$store.state.app.loadEscalas = false

        // this.updateFrecuencia([])
        this.initFrecuencia()
      }
    },

    getAirPorts() {
      this.axios
        .post('airports-names', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data.data

          // console.log(result)
          result.forEach(element => {
            // eslint-disable-next-line no-nested-ternary
            // this.items.push({ id: element.id, name: `${element.nominatim.address.city !== undefined ? element.nominatim.address.city : (element.nominatim.address.state !== undefined ? element.nominatim.address.state : element.nominatim.address.island)} (${element.iata} - ${element.name}) ${element.nominatim.address.country}` })
            this.items.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
          })
        })
    },

    getAeronaves() {
      this.axios
        .get(`airplane?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aeronaves = res.data.data
        })
    },

    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data
        })
    },

    save() {
      if (
        this.model.aerolinea_id
        && this.model.numero_de_vuelo
        && this.frecuencias.length > 0

      // && this.model.aeronave_id
      // && this.model.time_vuelo_h
      // && this.model.time_vuelo_m
      ) {
        let proceder = true

        this.frecuencias.forEach(element => {
          if (
            !element.fromValidez
            || !element.toValidez
            || !element.origen.destino
            || !element.origen.salida
            || !element.destino.destino
            || !element.destino.llegada
          ) {
            proceder = false
          }
        })

        if (this.frecuencias[0].escalas.length > 0) {
          this.frecuencias[0].escalas.forEach(element => {
            if (!element.city || !element.llegada || !element.salida) {
              proceder = false
            }
          })
        }

        if (proceder) {
          this.loading = true
          const json = {
            numero_de_vuelo: `${this.iataAeroline} ${this.model.numero_de_vuelo}`,

            // fromValidez: this.model.fromValidez,
            // toValidez: this.model.toValidez,
            // frecuencia: this.model.frecuencia,
            origen: this.frecuencias[0].origen,
            destino: this.frecuencias[0].destino,

            // check_in: this.model.check_in,
            // hour_departure: this.model.hour_departure,
            // hour_arrival: this.model.hour_arrival,
            // time_vuelo_h: this.model.time_vuelo_h,
            // time_vuelo_m: this.model.time_vuelo_m,
            perfil_slug: localStorage.getItem('perfil'),

            // escalas: this.$store.state.app.escalas,
            escalas: this.$store.state.app.frecuencias[0].escalas,
            frecuencia: this.frecuencias,
            aeronave_id: this.model.aeronave_id,
            aerolinea_id: this.model.aerolinea_id,
          }

          // console.log(json)

          if (localStorage.getItem('flight-id') === null || localStorage.getItem('flight-clone') !== null) {
            this.axios
              .post('flight', json, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                // console.log(response)
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  } else if (response.data.data.status === 402) {
                    this.$toast.error(this.$t('msg.existFlight', { n: this.model.numero_de_vuelo }))
                  }
                } else {
                  localStorage.removeItem('flight-id')
                  this.$router.push({ name: 'flights-list' })
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          } else {
            this.axios
              .put(`flight/${this.model.id}`, json, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else {
                  localStorage.removeItem('flight-id')
                  this.$router.push({ name: 'flights-list' })
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    changeAerolinea() {
      const air = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]
      this.iataAeroline = air.slug

      // this.model.numero_de_vuelo = `${air.slug} `
      this.model.numero_de_vuelo = ''
      this.$refs.numero_de_vuelo.focus()
    },

    addFrecuency() {
      let newFrec = {}
      if (this.frecuencias.length > 0) {
        const frec = this.frecuencias[0]
        const origen = {
          destino: frec.origen.destino,
          salida: frec.origen.salida,
          terminal: frec.origen.terminal,
          puerta: frec.origen.puerta,
          duration: frec.origen.duration,
          durationM: frec.origen.durationM,
        }
        const destino = {
          destino: frec.destino.destino,
          llegada: frec.destino.llegada,
          terminal: frec.destino.terminal,
          puerta: frec.destino.puerta,
          days: frec.destino.days,
          duration: frec.destino.duration,
          durationM: frec.destino.durationM,
        }
        newFrec = {
          frecuencia: [],
          fromValidez: frec.fromValidez,
          toValidez: frec.toValidez,
          origen,
          destino,
          days: '',
          duration: '',
          durationM: 0,
          escalas: frec.escalas,
          tiene_escalas: frec.tiene_escalas,
        }
      } else {
        newFrec = {
          frecuencia: [],
          fromValidez: '',
          toValidez: '',
          origen: {
            destino: null,
            salida: null,
            terminal: null,
            puerta: null,
            duration: null,
            durationM: null,
          },
          destino: {
            destino: null,
            llegada: null,
            terminal: null,
            puerta: null,
            days: null,
            duration: null,
            durationM: null,
          },
          days: 0,
          duration: '',
          escalas: [],
          tiene_escalas: false,
        }
      }

      console.log(newFrec)

      // this.adicionarFrecuencia(newFrec)
    },
  },
}
</script>
